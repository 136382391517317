import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchSubPoetry';

import Grid from '@mui/material/Grid';
import { CssTextField, BWButton } from '../components/CustomFormComponents';

export default class SubPoetry extends Component {
    state = {
        hash: this.generateRandomHash(),
        year: "2023",
        month: "01",
        day: "01",
        hours: "00",
        minutes: "00",
        seconds: "00",
    }

    generateRandomHash() {
        let result           = '';
        let characters       = 'abcdefghi0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < 64; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    buttonClickRandomHash() {
        this.setState({
            hash: this.generateRandomHash(),
        })
        console.log(window.tokenHash)
    }
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Poetry for the subconscious
                            </p>
                            <br/><br/>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <script>{window.tokenHash=this.state.hash}</script>
                                <script>{window.timeSeed=this.state.year + this.state.month + this.state.day + this.state.hours + this.state.minutes + this.state.seconds}</script>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <div style={{padding: "40px 0 20px 0"}}>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="left" style={{marginBottom: 20}}>  
                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center"> 
                                        <Grid item xs={9}>
                                            <CssTextField size="small" fullWidth id="tokenHash" label="Hash" variant="outlined" 
                                                value={this.state.hash} 
                                                onChange={(event) => this.setState({hash: event.target.value})}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <BWButton variant="outlined" onClick={() => this.buttonClickRandomHash()}>
                                                Generate random hash
                                            </BWButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="year" label="Year" variant="outlined" 
                                                    value={this.state.year} 
                                                    onChange={(event) => this.setState({year: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="month" label="Month" variant="outlined" 
                                                    value={this.state.month} 
                                                    onChange={(event) => this.setState({month: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="day" label="Day" variant="outlined" 
                                                    value={this.state.day} 
                                                    onChange={(event) => this.setState({day: event.target.value})}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="hours" label="Hours" variant="outlined" 
                                                    value={this.state.hours} 
                                                    onChange={(event) => this.setState({hours: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="minutes" label="Minutes" variant="outlined" 
                                                    value={this.state.minutes} 
                                                    onChange={(event) => this.setState({minutes: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="seconds" label="Seconds" variant="outlined" 
                                                    value={this.state.seconds} 
                                                    onChange={(event) => this.setState({seconds: event.target.value})}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <p>
                                Poetry is a complex system, where each word and its position within the composition influence the whole text, 
                                at times in ways that are easily identifiable, others more obscure and that require multiple readings.<br/><br/>

                                Layout also has an impact on the whole system to various degrees: from the choice of meter, newline, 
                                and typography, to concrete and visual poetry, where it often becomes more important than the content itself.<br/><br/>

                                To further complicate the system, if we take into account that the same letters can be written in different ways, 
                                as it goes for handwriting, we arrive at a system that is almost impossible to fully analyze. 
                                The same text written by different hands and in different layouts will convey different messages.<br/><br/>

                                Concrete and asemic writing both aim to trascend the language as it is, 
                                to convey and or communicate concepts either raw or not logically formed. 
                                Essentially, to talk not to the analytical mind, but to the instinctive or subconscious one.<br/><br/>

                                They do not lack meaning, they lack meaning in the formal definition of meaning, 
                                but there is no such thing as truly meaningless.<br/>
                                The focus is different: is to be perceived from a more internal point of view rather than a 
                                logical and culturally influenced one. It's trying to communicate to the parts of ourselves that not 
                                even we fully understand.<br/>
                                By forming unexpected outputs, by putting together pieces that we wouldn't normally do, 
                                a barrier is broken, and we enter into a different mind space.<br/>
                                Within this mind space, lies imagination.<br/><br/>

                                The aim of this series is that. Characters formed by an algorithm are written 
                                in a visual representation derived from the algorithm of the characters themselves, 
                                and analyzed in a system of relevance, accents, and connections.<br/><br/>

                                The key to understand each piece within the series is unique to all of us, 
                                and also to the specific moment we are living. 
                                Imagination within every individual evolves as they accumulate more perceptions, 
                                more experiences, fueling new ways of putting all these parts together. <br/><br/>

                                Often, when reading a book again, we focus on different parts that we possibly didn't even notice 
                                in the previous readings. Visual art is no different.<br/>
                                This is an invitation to stop and wonder, to reflect on where you are right now, 
                                and re-explore it at later points or your life.<br/>
                                I would also recommend writing down the thoughts each time, along with the date. 
                                The track of memories, experiences, imagination, and where your mind was in each moment 
                                will help you remember your path and know yourself better.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}