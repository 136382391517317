import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Roads extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="day 1" 
                                    link="https://objkt.com/asset/versum_items/6719"
                                    imgLink="/images/roads/day1.gif"
                                />
                                <CollectionPiece 
                                    title="day 29" 
                                    link="https://objkt.com/asset/versum_items/6801"
                                    imgLink="/images/roads/day29.gif"
                                />
                                <CollectionPiece 
                                    title="day 99" 
                                    link="https://objkt.com/asset/versum_items/17529"
                                    imgLink="/images/roads/day99.gif"
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Roads to nowhere
                                </p>
                                <a href="https://objkt.com/profile/encapsuled/created?faContracts=KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" target="_blank" rel="noopener noreferrer">
                                    Available on objkt
                                </a>
                                <p>
                                    I've always liked to draw, but never thought I was good at it.<br/>
                                    I still don't, but I love the process of imagining what I can do.<br/>
                                    So I decided to start with a theme that I've sketched some times before, roads.<br/>
                                    Except my roads don't go anywhere.<br/>
                                    All pieces are hand drawn gifs frame by frame. There are in total 100 + 1 roads in this collection.
                                </p>
                                <p>
                                    You can see them all <a href="https://objkt.com/profile/encapsuled/created?faContracts=KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" target="_blank" rel="noopener noreferrer">here</a>.
                                </p>
                                <CollectionPiece 
                                    title="day 23" 
                                    link="https://objkt.com/asset/versum_items/6772"
                                    imgLink="/images/roads/day23.gif"
                                />
                                <CollectionPiece 
                                    title="day 41" 
                                    link="https://objkt.com/asset/versum_items/6823"
                                    imgLink="/images/roads/day41.gif"
                                />
                                <CollectionPiece 
                                    title="day ?" 
                                    link="https://objkt.com/asset/versum_items/17829"
                                    imgLink="/images/roads/daylast.gif"
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Roads to nowhere
                            </p>
                            <a href="https://objkt.com/profile/encapsuled/created?faContracts=KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" target="_blank" rel="noopener noreferrer">
                                Available on objkt
                            </a>
                            <p>
                                I've always liked to draw, but never thought I was good at it.<br/>
                                I still don't, but I love the process of imagining what I can do.<br/>
                                So I decided to start with a theme that I've sketched some times before, roads.<br/>
                                Except my roads don't go anywhere.<br/>
                                All pieces are hand drawn gifs frame by frame. There are in total 100 + 1 roads in this collection.
                            </p>
                            <p>
                                You can see them all <a href="https://objkt.com/profile/encapsuled/created?faContracts=KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" target="_blank" rel="noopener noreferrer">here</a>.
                            </p>
                            <CollectionPiece 
                                title="day 1" 
                                link="https://objkt.com/asset/versum_items/6719"
                                imgLink="/images/roads/day1.gif"
                            />
                            <CollectionPiece 
                                title="day 23" 
                                link="https://objkt.com/asset/versum_items/6772"
                                imgLink="/images/roads/day23.gif"
                            />
                            <CollectionPiece 
                                title="day 29" 
                                link="https://objkt.com/asset/versum_items/6801"
                                imgLink="/images/roads/day29.gif"
                            />
                            <CollectionPiece 
                                title="day 41" 
                                link="https://objkt.com/asset/versum_items/6823"
                                imgLink="/images/roads/day41.gif"
                            />
                            <CollectionPiece 
                                title="day 99" 
                                link="https://objkt.com/asset/versum_items/17529"
                                imgLink="/images/roads/day99.gif"
                            />
                            <CollectionPiece 
                                title="day ?" 
                                link="https://objkt.com/asset/versum_items/17829"
                                imgLink="/images/roads/daylast.gif"
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Roads;