import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

class OnWriting extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <img alt='' src='/images/scelte/1.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/3.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/5.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/7.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/9.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/11.png' className="pieceImgGen" />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Scelte
                                </p>
                                <p>
                                    Scelte (IT) = Choices (EN)
                                </p>
                                <p>
                                    I spend lot of time wondering about the many possibilities of every little action. "What if" are two very common words in my daily life.<br/>
                                    Unfortunately, none of us will ever be able to know what would've happened if we did something different.
                                </p>
                                <p>
                                    With this collection I explore different outcomes with some fixed premises.<br/>
                                    So you don't have to make the choice. You can see the different possibilities next to each other.<br/>
                                    You'll realize that at times you like one more than the other, but your perception might change in a different moment.
                                </p>
                                <p>
                                    There is no right choice. Every little thing you did brought you to where you are today, and the only thing you can do now is move forward with all your experiences.<br/>
                                </p>
                                <p>
                                    We are all unique outcomes of countless choices made throughout our lives.
                                </p>
                                <img alt='' src='/images/scelte/0.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/2.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/4.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/6.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/8.png' className="pieceImgGen" />
                                <img alt='' src='/images/scelte/10.png' className="pieceImgGen" />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Scelte
                            </p>
                            <p>
                                Scelte (IT) = Choices (EN)
                            </p>
                            <p>
                                I spend lot of time wondering about the many possibilities of every little action. "What if" are two very common words in my daily life.<br/>
                                Unfortunately, none of us will ever be able to know what would've happened if we did something different.
                            </p>
                            <p>
                                With this collection I explore different outcomes with some fixed premises.<br/>
                                So you don't have to make the choice. You can see the different possibilities next to each other.<br/>
                                You'll realize that at times you like one more than the other, but your perception might change in a different moment.
                            </p>
                            <p>
                                There is no right choice. Every little thing you did brought you to where you are today, and the only thing you can do now is move forward with all your experiences.<br/>
                            </p>
                            <p>
                                We are all unique outcomes of countless choices made throughout our lives.
                            </p>
                            <img alt='' src='/images/scelte/1.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/3.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/5.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/7.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/9.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/11.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/0.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/2.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/4.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/6.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/8.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/10.png' className="pieceImgGen" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OnWriting;