
export default function sketch(p5) {
  function makeid(length) {
    var result           = '';
    var characters       = 'abcdefghi0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  let tokenData = {hash: makeid(64), tokenID: "0"}
  //tokenData.hash = "3956a2hfaece44hi6a608i8bgcc950h62ccci3gihdi8ig9ea061c8chff80bib1"
  console.log(tokenData.hash)
  class R {
    constructor(seed) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(seed.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(seed.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }
  }
  
  function pointInCircle(a, b, x, y, r) {
    const dx = a - x
    const dy = b - y
    return dx * dx + dy * dy < r * r
  }

  const rng = new R(tokenData.hash)
  let initialPoints = []
  let ps = []
  let tempPs = []
  let w, h, a, x, y, aInc, found
  let angleBoundaries = [0.01, 0.1, 0.2, 0.5, 1, 2, 3]
  let thicknesses = [0.001, 0.002, 0.004, 0.01]
  
  let angleBoundary = rng.rC(angleBoundaries)
  let th = rng.rC(thicknesses)
  let collisionDistanceMultiplier = rng.rC([1, 2])
  let strokeW = 0.001
  let inverted = rng.rC([-1, 1])
  let cont = rng.rC([50, 100])
  
  let horMargin = rng.rC([0, 0.05, 0.1, 0.2, 0.3])
  let verMargin = rng.rC([0, 0.05, 0.1, 0.2, 0.3])
  let horStep = rng.rC([0.01, 0.02, 0.05, 0.1, 0.2, 1/3, 0.5, 1])
  let verStep = rng.rC([0.01, 0.02, 0.05, 0.1, 0.2, 1/3, 0.5, 1])
  while ((horStep >= 1/3 && verStep >= 0.2) || (horStep >= 0.2 && verStep >= 1/3) || (horStep < 0.06 && verStep < 0.06)) {
    horStep = rng.rC([0.01, 0.02, 0.05, 0.1, 0.2, 1/3, 0.5, 1])
    verStep = rng.rC([0.01, 0.02, 0.05, 0.1, 0.2, 1/3, 0.5, 1])
  }
  let density = horStep * verStep
  let numPointsChoices = [500, 1000, 2000, 5000, 10000]
  if (density <= 0.001) {
    numPointsChoices = [50, 100, 200, 500]
  } else if (density <= 0.002) {
    numPointsChoices = [50, 100, 200, 500, 1000, 2000]
  } else if (density <= 0.01) {
    numPointsChoices = [200, 500, 1000, 2000, 5000]
  } 
  horStep = horStep*(1-2*horMargin)
  verStep = verStep*(1-2*verMargin)
  let numPointsParam = rng.rC(numPointsChoices)
  let coherentNumPoints = rng.rB(0, 1) > 0.5
  let vertical = rng.rB(0, 1) > 0.5
  let coherentBoundaries = rng.rB(0, 1) > 0.5
  let coherentTh = rng.rB(0, 1) > 0.5
  let drawLines = rng.rB(0, 1) > 0.5
  let noSkip = horStep > 0.2 || verStep > 0.2 || rng.rB(0, 1) > 0.5
  let skipThreshold = rng.rC([0.8, 0.9, 0.95, 0.99])
  let invert = rng.rB(0, 1) > 0.5
  let invertThreshold = rng.rC([0.5, 0.8, 0.9, 0.95, 0.99])
  let invertStyle = rng.rC([1, 2, 3])
  let backgroundColor = 1 * inverted
  
  console.log('angleBoundary ' + angleBoundary)
  console.log('collisionDistanceMultiplier ' + collisionDistanceMultiplier)
  console.log('strokeW ' + strokeW)
  console.log('inverted ' + inverted)
  console.log('cont ' + cont)
  console.log('horMargin ' + horMargin)
  console.log('verMargin ' + verMargin)
  console.log('vertical ' + vertical)
  console.log('th ' + th)
  console.log('numPointsParam ' + numPointsParam)
  console.log('coherentNumPoints ' + coherentNumPoints)
  console.log('coherentBoundaries ' + coherentBoundaries)
  console.log('coherentTh ' + coherentTh)
  console.log('drawLines ' + drawLines)
  console.log('noSkip ' + noSkip)
  console.log('skipThreshold ' + skipThreshold)
  console.log('invert ' + invert)
  console.log('invertThreshold ' + invertThreshold)
  console.log('invertStyle ' + invertStyle)
  
  p5.setup = () => {
    if (p5.windowHeight <= p5.windowWidth/p5.sqrt(2)) {
      w=p5.windowHeight*p5.sqrt(2)*0.75
      h=p5.windowHeight*0.75
    } else {
      w=p5.windowWidth*0.75
      h=p5.windowWidth/p5.sqrt(2)*0.75
    }
    p5.createCanvas(w, h)
    p5.noStroke()
    p5.pixelDensity(5)
  
    if (vertical) {
      for (let i=horMargin; i<(1-horMargin-0.001); i+=horStep) {
        for (let j=verMargin; j<(1-verMargin-0.001); j+=verStep) {
          if (noSkip || rng.rB(0, 1) < skipThreshold) {
            let inv = rng.rB(0, 1) > invertThreshold
            initialPoints.push({x: i+(horStep/2), y: j+(verStep/2), minX: i, minY: j, maxX: i+horStep, maxY: j+verStep,
              numPoints: coherentNumPoints ? numPointsParam : rng.rC(numPointsChoices),
              angleBoundary: coherentBoundaries ? angleBoundary : rng.rC(angleBoundaries),
              th: coherentTh ? th : rng.rC(thicknesses),
              invert: inv,
              c: backgroundColor * (inv ? (invertStyle === 1 ? -1 : (invertStyle === 3 ? rng.rC([-1, 1]) : 1)) : -1)
            })
          }
        }
      }
    } else {
      for (let j=verMargin; j<(1-verMargin-0.001); j+=verStep) {
        for (let i=horMargin; i<(1-horMargin-0.001); i+=horStep) {
          if (noSkip || rng.rB(0, 1) < skipThreshold) {
            let inv = rng.rB(0, 1) > invertThreshold
            initialPoints.push({x: i+(horStep/2), y: j+(verStep/2), minX: i, minY: j, maxX: i+horStep, maxY: j+verStep,
              numPoints: coherentNumPoints ? numPointsParam : rng.rC(numPointsChoices),
              angleBoundary: coherentBoundaries ? angleBoundary : rng.rC(angleBoundaries),
              th: coherentTh ? th : rng.rC(thicknesses),
              invert: inv,
              c: backgroundColor * (inv ? (invertStyle === 1 ? -1 : (invertStyle === 3 ? rng.rC([-1, 1]) : 1)) : -1)
            })
          }
        }
      }
    }
    
    for (const p of initialPoints) {
      tempPs = []
      a = rng.rB(0, p5.TWO_PI)
      aInc = rng.rB(-p.angleBoundary, p.angleBoundary)
      x = p.x
      y = p.y
      
      for (let i = 0; i<p.numPoints; i++) {
        if (i%150 === 0) {
          a = rng.rB(0, p5.TWO_PI)
        }
        if (i%cont === 0) {
          aInc = rng.rB(-p.angleBoundary, p.angleBoundary)
        }
        a += aInc
        x += Math.cos(a)*(p.th/3)/Math.sqrt(2)
        y += Math.sin(a)*(p.th/3)
  
        if (y<(p.minY) || x<(p.minX)) {
          a+=p5.PI
          aInc = rng.rB(-p.angleBoundary, p.angleBoundary)
        } else if (y>(p.maxY) || x>(p.maxX)) {
          a-=p5.PI
          aInc = rng.rB(-p.angleBoundary, p.angleBoundary)
        }
  
        if (x < p.minX) {
          x = p.minX
        }
        if (y < p.minY) {
          y = p.minY
        }
        if (x > p.maxX) {
          x = p.maxX
        }
        if (y > p.maxY) {
          y = p.maxY
        }
  
        found = false
        for (let j=tempPs.length-(0.02/p.th); j>0 && !found; j--) {
          if (pointInCircle(tempPs[j].x, tempPs[j].y, x, y, p.th/3)) {
            found = true
            break
          }
        }
        if (!found) {
          tempPs.push({
            x: x,
            y: y,
            th: p.th,
            c: p.c,
          })
        }
      }
      ps = [...ps, ...tempPs]
    }
  }
  
  p5.draw = () => {
    p5.background(backgroundColor === 1 ? "#fafafa" : "#0f0f0f")
    if (invert) {
      p5.fill(backgroundColor === 1 ? "#0f0f0f" : "#fafafa")
      p5.noStroke()
      for (const p of initialPoints) {
        if (p.invert) {
          p5.rect((p.x - horStep/2)*w, (p.y-verStep/2)*h, (horStep+0.0002)*w, (verStep+0.0002)*h)
        }
      }
    }
    if (drawLines) {
      for (let i=0; i<ps.length-1; i++) {
        p5.strokeWeight(ps[i].th*h)
        p5.stroke(ps[i].c === 1 ? '#fafafa' : '#0f0f0f')
        p5.line((ps[i].x)*w, (ps[i].y)*h, (ps[i+1].x)*w, (ps[i+1].y)*h)
      }
    } else {
      p5.noStroke()
      for (let i=0; i<ps.length; i++) {
        p5.fill(ps[i].c === 1 ? '#fafafa' : '#0f0f0f')
        p5.circle((ps[i].x)*w, (ps[i].y)*h, ps[i].th*h)
      }
    }
    p5.noLoop()
  }
}
