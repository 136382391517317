import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class OnWriting extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="Ink and paper" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/0"
                                    imgLink='/images/onwriting/inkPaper.gif'
                                />
                                <CollectionPiece 
                                    title="Wave after wave" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/2"
                                    imgLink='/images/onwriting/waveafterwavetw.gif'
                                />
                                <CollectionPiece 
                                    title="Want, need" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/4"
                                    imgLink='/images/onwriting/onwriting5tw.gif'
                                />
                                <CollectionPiece 
                                    title="Swarm of words" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/6"
                                    imgLink='/images/onwriting/onwriting7tw.gif'
                                />
                                <CollectionPiece 
                                    title="A white page" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/8"
                                    imgLink='/images/onwriting/onwriting9tw.gif'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    On writing
                                </p>
                                <a href="https://objkt.com/collection/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms" target="_blank" rel="noopener noreferrer">
                                    Available on objkt
                                </a>
                                <p>
                                    One of the things I like to write about most is writing.<br/>
                                    I think it's normal, given the amount of time I spend doing that activity, so I decided to embrace it.<br/>
                                    All the pieces of this collection are very readable, with minimal animation.
                                </p>
                                <CollectionPiece 
                                    title="Gatherer of words" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/1"
                                    imgLink='/images/onwriting/gatherer.gif'
                                />
                                <CollectionPiece 
                                    title="Turn the page" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/3"
                                    imgLink='/images/onwriting/onwriting4tw.gif'
                                />
                                <CollectionPiece 
                                    title="Frame" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/5"
                                    imgLink='/images/onwriting/onwriting6tw.gif'
                                />
                                <CollectionPiece 
                                    title="Let it breathe" 
                                    link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/7"
                                    imgLink='/images/onwriting/onwriting8tw.gif'
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                On writing
                            </p>
                            <a href="https://objkt.com/collection/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms" target="_blank" rel="noopener noreferrer">
                                Available on objkt
                            </a>
                            <p>
                                One of the things I like to write about most is writing.<br/>
                                I think it's normal, given the amount of time I spend doing that activity, so I decided to embrace it.<br/>
                                All the pieces of this collection are very readable, with minimal animation.
                            </p>
                            <CollectionPiece 
                                title="Ink and paper" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/0"
                                imgLink='/images/onwriting/inkPaper.gif'
                            />
                            <CollectionPiece 
                                title="Gatherer of words" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/1"
                                imgLink='/images/onwriting/gatherer.gif'
                            />
                            <CollectionPiece 
                                title="Wave after wave" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/2"
                                imgLink='/images/onwriting/waveafterwavetw.gif'
                            />
                            <CollectionPiece 
                                title="Turn the page" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/3"
                                imgLink='/images/onwriting/onwriting4tw.gif'
                            />
                            <CollectionPiece 
                                title="Want, need" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/4"
                                imgLink='/images/onwriting/onwriting5tw.gif'
                            />
                            <CollectionPiece 
                                title="Frame" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/5"
                                imgLink='/images/onwriting/onwriting6tw.gif'
                            />
                            <CollectionPiece 
                                title="Swarm of words" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/6"
                                imgLink='/images/onwriting/onwriting7tw.gif'
                            />
                            <CollectionPiece 
                                title="Let it breathe" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/7"
                                imgLink='/images/onwriting/onwriting8tw.gif'
                            />
                            <CollectionPiece 
                                title="A white page" 
                                link="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/8"
                                imgLink='/images/onwriting/onwriting9tw.gif'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OnWriting;