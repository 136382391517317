import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

class OnWriting extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <img alt='' src='/images/sentieri/1.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/3.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/5.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/7.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/9.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/11.png' className="pieceImgGen" />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Sentieri
                                </p>
                                <p>
                                    Sentieri (IT) = Paths (EN)
                                </p>
                                <p>
                                    Sentieri is an exploration of human paths.<br/><br/>
                                    some are more intuitive<br/>
                                    some are chaotic<br/>
                                    we don't know what's next<br/>
                                    but we keep going<br/><br/>
                                    there may be unexpected turns<br/>
                                    sometimes we split<br/>
                                    sometimes we get together<br/>
                                    but we keep going<br/><br/>
                                    to each their own<br/>
                                    at times we learn<br/>
                                    at times we deviate<br/>
                                    but we keep going
                                </p>
                                <p>
                                    Sentieri is a snapshot of a part of life. This snapshot may catch some connections we make, or a moment where we separate from someone or something else.<br/>
                                    No path is complete, no path is correct. They simply are, and all we can do is walking along and see where we end up.
                                </p>
                                <img alt='' src='/images/sentieri/0.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/2.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/4.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/6.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/8.png' className="pieceImgGen" />
                                <img alt='' src='/images/sentieri/10.png' className="pieceImgGen" />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Sentieri
                            </p>
                            <p>
                                Scelte (IT) = Choices (EN)
                            </p>
                            <p>
                                Sentieri (IT) = Paths (EN)
                            </p>
                            <p>
                                Sentieri is an exploration of human paths.<br/><br/>
                                some are more intuitive<br/>
                                some are chaotic<br/>
                                we don't know what's next<br/>
                                but we keep going<br/><br/>
                                there may be unexpected turns<br/>
                                sometimes we split<br/>
                                sometimes we get together<br/>
                                but we keep going<br/><br/>
                                to each their own<br/>
                                at times we learn<br/>
                                at times we deviate<br/>
                                but we keep going
                            </p>
                            <p>
                                Sentieri is a snapshot of a part of life. This snapshot may catch some connections we make, or a moment where we separate from someone or something else.<br/>
                                No path is complete, no path is correct. They simply are, and all we can do is walking along and see where we end up.
                            </p>
                            <img alt='' src='/images/scelte/1.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/3.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/5.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/7.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/9.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/11.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/0.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/2.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/4.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/6.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/8.png' className="pieceImgGen" />
                            <img alt='' src='/images/scelte/10.png' className="pieceImgGen" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OnWriting;