import React, { Component } from 'react'
import { Link } from "react-router-dom";

class LeftNav extends Component {
    render() {
        return(
            <div className="leftNav">
                <Link to="/">
                    <img
                        src="/images/encapsuled_logo_v2.png"
                        width="50" height="50" alt="logo" style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10, display: "block" }}
                    />
                </Link>
                <br/>
                <p>
                    <b>michelangelo (encapsuled)</b>
                </p>
                <Link to="/">Home</Link><br/>
                <p>
                    Works:
                </p>
                <ul>
                    <li><Link to="/carattere">Carattere</Link></li>
                    <li><Link to="/taccuini">Taccuini</Link></li>
                    <li><Link to="/evolutionv0">Evolution v0</Link></li>
                    <li><Link to="/endlessdialogue">Endless dialogue</Link></li>
                    <li><Link to="/loss">Loss</Link></li>
                    <li><Link to="/linebreak">Linebreak</Link></li>
                    <li><Link to="/indaco">Indaco</Link></li>
                    <li><Link to="/chronicles">Chronicles</Link></li>
                    <li><Link to="/yourstory">Your Story</Link></li>
                    <li><Link to="/manifesto">Manifesto</Link></li>
                    <li><Link to="/people">People</Link></li>
                    <li><Link to="/disegni">Disegni di poesia</Link></li>
                    <li><Link to="/e28">28</Link></li>
                    <li><Link to="/journey">Journey</Link></li>
                    <li><Link to="/capsules">Capsules</Link></li>
                    <li><Link to="/raw">Raw</Link></li>
                    <li><Link to="/roads">Roads to nowhere</Link></li>
                    <li><Link to="/onwriting">On writing</Link></li>
                    <li><Link to="/ouroboros">Ouroboros</Link></li>
                    <li><Link to="/engoesback">engoesback</Link></li>
                </ul>
                <p>
                    Collaborations:
                </p>
                <ul>
                    <li><Link to="/divergence">Divergence</Link></li>
                    <li><a href="https://teia.art/objkt/717662" target="_blank" rel="noopener noreferrer">
                        encapsuled x coppola
                    </a></li>
                </ul>
            </div>
        )
    }
}

export default LeftNav;
