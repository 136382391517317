export default function sketch(p) {
    
      
  function makeid(length) {
    var result           = '';
    var characters       = 'abcdef0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return '0x'+result;
  }

  const tokenData = {
    hash: makeid(64)
  }


  class R {
    constructor(seed) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(seed.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(seed.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }
  }


  function gsft(d) {
    return '' + d.getUTCFullYear() + d.getUTCMonth() + d.getUTCDay() + d.getUTCHours() + d.getUTCMinutes() + seed
  }

  function gpsft(d) {
    let pd = new Date(d - 60000)
    return '' + pd.getUTCFullYear() + pd.getUTCMonth() + pd.getUTCDay() + pd.getUTCHours() + pd.getUTCMinutes() + seed
  }

  function gnsft(d) {
    let pd = new Date(d.getTime() + 60000)
    return '' + pd.getUTCFullYear() + pd.getUTCMonth() + pd.getUTCDay() + pd.getUTCHours() + pd.getUTCMinutes() + seed
  }

  function iar(v, inc, min, max) {
    v += inc
    if (v<min) {
      v = min
    }
    if (v>max) {
      v = max
    }
    return v
  }

  function gp(rng, extra, d) {
    let ps = []
    let speaker = rng.rI(0, numSpeakers-1)
    let emotion = rng.rI(0, 360)
    let emotionMaxIntensityPerc = rng.rB(0, 1)
    let emotionMaxIntensity = emotionMaxIntensityPerc < 0.5 ? 50 : emotionMaxIntensityPerc < 0.7 ? 70 : emotionMaxIntensityPerc < 0.9 ? 90 : 100
    let maxIntensity = rng.rI(0, emotionMaxIntensity)
    let silence = rng.rB(0, 1) > 0.5
    let silenceLength = rng.rC([5, 10, 20, 50])
    let thickness = rng.rC([0.001, 0.002, 0.003])
    let al = rng.rC([0.1, 0.3, 0.5, 1])
    let justTalkedOver = false
    let speakerStyle, newSpeaker, saturation
    for (let i=0; i<=200; i++) {
      if (!(silence && i<silenceLength)) {
        if (rng.rB(0, 1) < 0.9) {
          if (rng.rB(0, 1) > dialogueStyle) {   
            newSpeaker = rng.rI(0, numSpeakers-1)
            if (newSpeaker !== speaker) {   
              emotion = iar(emotion, rng.rB(-5, 5), 0, 360)
              thickness = rng.rC([0.001, 0.002, 0.003])
              al = rng.rC([0.1, 0.3, 0.5, 1])
            }
            speaker = newSpeaker
          }
          thickness = iar(thickness, rng.rB(-0.0005, 0.0005), 0.001, 0.005)
          al = iar(al, rng.rB(-0.05, 0.05), 0.1, 1)
          saturation = i <= 100 ? i : 200-i
          saturation = p.map(saturation, 0, 100, 0, maxIntensity)

          let y = possibleHeights[speaker]
          speakerStyle = mixedStyle ? speakerStyles[speaker] : speakerStyles[0]
          steps = speakerStyle >= 1 ? 60 : 30
          ps = ps.concat(gcp({
            x: i/200 + extra,
            y: y*9/16,
            a: rng.rI(0, p.TWO_PI),
            c: p.color(emotion, saturation, 98),
            t: thickness,
            al: al,
          }, speakerStyle, steps, rng, y))
          
          if (rng.rB(0, 1) < talkOverChance && !justTalkedOver) {
            let rs = rng.rI(0, numSpeakers-1)
            speakerStyle = mixedStyle ? speakerStyles[rs] : speakerStyles[0]
            steps = 20
            for (const talkOverI of [i, i+1]) {
              ps = ps.concat(gcp({
                x: talkOverI/200 + extra,
                y: possibleHeights[rs]*9/16,
                a: rng.rI(0, p.TWO_PI),
                c: p.color(emotion, saturation, 98),
                t: thickness,
                al: al,
              }, speakerStyle, steps, rng, possibleHeights[rs]))
            }
            justTalkedOver = true
          } else {
            if (justTalkedOver) {
              justTalkedOver = false
            }
          }
        }
      }
    }
    return [...ps]
  }

  function gcp(point, angleBoundary, steps, rng, startY) {
    let stepSize = angleBoundary >= 1 ? 0.001 : 0.0005
    let ps = []
    let x = point.x
    let y = point.y
    let a = point.a
    let na = rng.rB(-angleBoundary, angleBoundary)
    for (let i=0; i<steps; i++) {
      ps.push({
        x: x,
        y: y,
        d: point.d,
        c: point.c,
        t: point.t,
        al: point.al,
      })
      a += na
      x += p.cos(a)*stepSize
      y += p.sin(a)*stepSize
      if (y < (startY-0.1)*9/16 || y > (startY+0.1)*9/16) {
        break
      }
      if (i%50 === 0) {
        na = rng.rB(-angleBoundary, angleBoundary)
      }
    }
    return ps
  }

  function dp(point, currStep) {
    let c = point.c
    c.setAlpha(point.al)
    p.fill(c)
    p.circle((point.x - currStep)*cW, point.y*cW, point.t*cH)
  }

  let rrng
  let previousRng
  let nextRng
  let seed = tokenData.hash
  let seedRng = new R(seed)
  let cW, cH 
  let points = []
  let pPoints = []
  let nPoints = []
  let allPoints = []
  let alp = seedRng.rC([50, 100, 150])
  let angleBoundaries = [5, 2, 1, 0.3, 0.2, 0.1, 0.05, 0.01]
  let numSpeakers = seedRng.rB(0,1) > 0.99 ? 1 : seedRng.rI(2, 5)
  let speakersOrdered = seedRng.rB(0, 1) > 0.5
  let talkOverChance = seedRng.rC([0.01, 0.1, 0.2, 0.5])
  let possibleHeightsOrdered = [
    [],
    [0.5],
    [0.4, 0.6],
    [0.3, 0.5, 0.7],
    [0.2, 0.4, 0.6, 0.8],
    [0.1, 0.3, 0.5, 0.7, 0.9],
  ]
  let possibleHeights = speakersOrdered ? possibleHeightsOrdered[numSpeakers] : [
    seedRng.rB(0.1, 0.9), 
    seedRng.rB(0.1, 0.9), 
    seedRng.rB(0.1, 0.9), 
    seedRng.rB(0.1, 0.9), 
    seedRng.rB(0.1, 0.9),
  ]

  let mixedStyle = seedRng.rB(0, 1) > 0.9

  let speakerStyles = [
    seedRng.rC(angleBoundaries),
    seedRng.rC(angleBoundaries),
    seedRng.rC(angleBoundaries),
    seedRng.rC(angleBoundaries),
    seedRng.rC(angleBoundaries),
  ]

  let ew = "#fafafa"
  let eb = "#0f0f0f"
  let w = "#fff"
  let b = "#000"
  let ow = "#f5f5f5"
  let ob = "#131313"

  let dialogueStyle = seedRng.rC([0.95, 0.9, 0.99, 0.8])
  let museumColors = [
    [ow,w],
    [ow,b],
    [ow,ob],
    [ob,b],
    [ob,ow],
    [ob,w],
    [w,ow],
    [b,ow],
    [b,ob], 
    [w,ob],
    [w,b],
    [b,w],
  ]
  let museumColor = seedRng.rC(museumColors)
  let wallOuterWidth = seedRng.rC([0.01, 0.05, 0.1])
  let wallColor = museumColor[0]
  let frameColor = museumColor[1]

  let irrFrames = seedRng.rB(0, 1) > 0.5

  let frameRows = seedRng.rI(1, 3)
  let frameColumns = seedRng.rI(1, 4)

  let irrFramesXs = []
  let hCuts = seedRng.rI(0, 9)
  let found, newX
  for (let i=0; i<hCuts; i++) {
    found = false
    newX = seedRng.rB(0.2, 0.8)
    for (const cx of irrFramesXs) {
      if (Math.abs(cx-newX) < 0.1) {
        found = true
      }
    }
    if (!found) {
      irrFramesXs.push(newX)
    }
  }
  let irrFramesYs = []
  let vCuts = seedRng.rI(0, 9)
  for (let i=0; i<vCuts; i++) {
    found = false
    newX = seedRng.rB(0.2, 0.8)
    for (const cx of irrFramesYs) {
      if (Math.abs(cx-newX) < 0.1) {
        found = true
      }
    }
    if (!found) {
      irrFramesYs.push(newX)
    }
  }

  let wallWidth = irrFrames ? seedRng.rC([0.01, 0.02, 0.03]) : seedRng.rC([0.01, 0.05, 0.1])
  let frameWidth = irrFrames ? seedRng.rC([0, 0.01, 0.02, 0.03]) : frameRows === 3 ? seedRng.rC([0, 0.01, 0.05]) : seedRng.rC([0, 0.01, 0.05, 0.1])

  let settingAlpha = seedRng.rB(0.1, 1)
  let settingStroke = seedRng.rB(0, 1) > 0.5
  let settingWidth = frameColumns === 1 && frameRows === 1 ? seedRng.rC(['n', 'm', 'w']) : seedRng.rC(['m', 'w'])
  let bgc = [0, 0, seedRng.rI(10, 100)]

  let bezierSettingLines = alp < 100 ? seedRng.rC([5, 10, 20, 50]) : seedRng.rC([5, 10]) 
  let bzs = []

  let squaresSettingAmount = seedRng.rC([5, 10, 20, 50, 100, 500, 1000, 2000])
  let squaresSettingSize = squaresSettingAmount < 50 ? seedRng.rC([0.2, 0.5, 1]) : seedRng.rC([0.05, 0.1, 0.2])
  let squaresCoherent = seedRng.rB(0, 1) > 0.5
  let sqs = []

  let linesSettingAmount = seedRng.rC([5, 10, 20, 50, 100, 500, 1000, 2000])
  let linesSettingPercHoriz = seedRng.rC([0, 0.2, 0.5, 0.8, 1])
  let linesSettingStrokeWeight = linesSettingAmount < 10 ? seedRng.rC([10, 100, 1000]) : linesSettingAmount < 500 ? seedRng.rC([100, 1000, 2000]) : seedRng.rC([1000, 2000])

  let ls = []

  let quadsSettingAmount = seedRng.rC([1, 2, 3, 5, 10, 20])
  let qds = []

  let settingChoice = seedRng.rB(0, 1)

  let primaryColor = seedRng.rC([
    "#0247fe","#fe2712","#fefe33","#66b032","#8601af","#fb9902"
  ])

  let colorChance = seedRng.rB(0, 1) > 0.42
  let steps, bgcc
  let showDialogue = false
  let done = false

  p.setup = () => {
    let d = new Date()
    rrng = new R(gsft(d));
    previousRng = new R(gpsft(d));
    nextRng = new R(gnsft(d));
    if (p.windowWidth <= p.windowHeight/9*16) {
      cW = p.windowWidth*0.75
      cH = p.windowWidth/16*9*0.75
    } else {
      cW = p.windowHeight/9*16*0.75
      cH = p.windowHeight*0.75
    }
    p.createCanvas(cW, cH)
    p.frameRate(30)
    p.noStroke()
    p.colorMode(p.HSB)
    
    bzs = gbb()
    qds = gqb()
    sqs = gsb()
    ls = glb()
    
    points = gp(rrng, 0.5, d)
    pPoints = gp(previousRng, -0.5, d)
    nPoints = gp(nextRng, 1.5, d)
    allPoints = [...pPoints, ...points, ...nPoints]
    primaryColor = p.color(primaryColor)
    primaryColor.setAlpha(settingAlpha)
  }

  p.draw = () => {
    let d = new Date()
    let s = d.getUTCSeconds()
    let ms = d.getUTCMilliseconds()
    if (s < 1 && !done) {
      rrng = new R(gsft(d));
      previousRng = new R(gpsft(d));
      nextRng = new R(gnsft(d));
      points = gp(rrng, 0.5, d)
      pPoints = gp(previousRng, -0.5, d)
      nPoints = gp(nextRng, 1.5, d)
      allPoints = [...pPoints, ...points, ...nPoints]
      done = true
    }
    if (s === 42) {
      done = false
    }
    let currStep = (s + ms/1000)/60
    p.background([bgc[0], bgc[1], bgc[2] > 40 && bgc[2] < 60 ? 20 : 100-bgc[2]])
    bgcc = p.color(bgc)
    bgcc.setAlpha(settingAlpha)
    p.fill(bgcc)
    
    if (settingChoice < 0.1) {
      dsb(sqs)
    } else if (settingChoice < 0.2) {
      dlb(ls)
    } else if (settingChoice < 0.3) {
      dbb(bzs)
    } else if (settingChoice < 0.4) {
      dqb(qds)
    } else if (settingChoice < 0.5) {
      dsb(sqs)
      dlb(ls)
    } else if (settingChoice < 0.6) {
      dlb(ls)
      dbb(bzs)
    } else if (settingChoice < 0.7) {
      dlb(ls)
      dqb(qds)
    } else if (settingChoice < 0.8) {
      dqb(qds)
      dbb(bzs)
    } else if (settingChoice < 0.9) {
      dsb(sqs)
      dqb(qds)
    } else if (settingChoice < 1) {
      dsb(sqs)
      dbb(bzs)
    }
    
    p.noStroke()
    if (irrFrames) {
      dif()
    } else {
      df()
    }

    
    if (showDialogue) {
      let dc = p.color(eb)
      dc.setAlpha(0.3)
      p.fill(dc)
      for (let i=0; i<numSpeakers; i++) {
        p.rect(0, (possibleHeights[i]-0.05)*cH, cW, (0.1)*cH)
        p.rect(0, (possibleHeights[i]-0.1)*cH, cW, (0.2)*cH)
      }
    }
    
    for (let i=0; i<allPoints.length; i++) {
      if (allPoints[i].x - currStep > -0.05 && 
          allPoints[i].x - currStep < 1.05) {
        dp(allPoints[i], currStep)
      }
    }
    
    //fill("red")
    //text(frameRate(), 10, 10)
    //text(s, 10, 30)
  }


  function gbb() {
    let bezierMin
    let bezierMax
    if (settingWidth === 'n') {
      bezierMin = 0.25
      bezierMax = 0.75
    } else if (settingWidth === 'm') {
      bezierMin = 0
      bezierMax = 1
    } else if (settingWidth === 'w') {
      bezierMin = -1
      bezierMax = 2
    }
    let bzs = []
    for (let i=0; i<bezierSettingLines; i++) {
      bzs.push({
        x1: seedRng.rB(bezierMin, bezierMax)*cW,
        y1: seedRng.rB(bezierMin, bezierMax)*cH,
        
        c1x: seedRng.rB(bezierMin, bezierMax)*cW,
        c1y: seedRng.rB(bezierMin, bezierMax)*cH,
        
        c2x: seedRng.rB(bezierMin, bezierMax)*cW,
        c2y: seedRng.rB(bezierMin, bezierMax)*cH,
        
        x2: seedRng.rB(bezierMin, bezierMax)*cW,
        y2: seedRng.rB(bezierMin, bezierMax)*cH,
      })
    }
    return bzs
  }
    

  function dbb(bzs) {
    if (settingStroke) {
      p.stroke(ew)
      p.strokeWeight(cW / 2000)
    } else {
      p.noStroke()
    }
    for (let i=0; i<bzs.length-1; i++) {
      if (i===0 && colorChance) {
        p.fill(primaryColor)
      } else {
        p.fill(bgcc)
      }
      p.bezier(bzs[i].x1, bzs[i].y1, bzs[i].c1x, bzs[i].c1y, bzs[i].c2x, bzs[i].c2y, bzs[i].x2, bzs[i].y2)
      p.line(bzs[i].x1, bzs[i].y1, bzs[i].x2, bzs[i].y2)
      p.bezier(bzs[i].x2, bzs[i].y2, bzs[i].c1x, bzs[i].c1y, bzs[i].c2x, bzs[i].c2y, bzs[i+1].x1, bzs[i+1].y1)
      p.line(bzs[i].x2, bzs[i].y2, bzs[i+1].x1, bzs[i+1].y1)
    }
    p.bezier(bzs[bzs.length-1].x2, bzs[bzs.length-1].y2, bzs[0].c1x, bzs[0].c1y, bzs[0].c2x, bzs[0].c2y, bzs[0].x1, bzs[0].y1)
    p.line(bzs[bzs.length-1].x2, bzs[bzs.length-1].y2, bzs[0].x1, bzs[0].y1)
    p.noStroke()
  }

  function gqb() {
    let qds = []
    for (let i=0; i<quadsSettingAmount; i++) {
      qds.push({
        x1: seedRng.rB(-0.1, 1.1)*cW,
        y1: seedRng.rB(-0.1, 1.1)*cH,
        x2: seedRng.rB(-0.1, 1.1)*cW,
        y2: seedRng.rB(-0.1, 1.1)*cH,
        x3: seedRng.rB(-0.1, 1.1)*cW,
        y3: seedRng.rB(-0.1, 1.1)*cH,
        x4: seedRng.rB(-0.1, 1.1)*cW,
        y4: seedRng.rB(-0.1, 1.1)*cH,
      }) 
    }
    return qds
  }

  function dqb(qds) {
    if (settingStroke) {
      p.stroke(ew)
      p.strokeWeight(cW / 2000)
    } else {
      p.noStroke()
    }
    for (let i=0; i<qds.length; i++) {
      if (i===0 && colorChance) {
        p.fill(primaryColor)
      } else {
        p.fill(bgcc)
      }
      p.quad(
        qds[i].x1,
        qds[i].y1,
        qds[i].x2,
        qds[i].y2,
        qds[i].x3,
        qds[i].y3,
        qds[i].x4,
        qds[i].y4
      )
    }
  }
    

  function gsb() {
    let sqs = []
    let r = seedRng.rC([0, 0.01, 0.05, 0.1, 0.5, 1])*cH
    for (let i=0; i<squaresSettingAmount; i++) {
      sqs.push({
        x: seedRng.rB(-0.5, 1)*cW,
        y: seedRng.rB(-0.5, 1)*cH,
        w: seedRng.rB(0, squaresSettingSize)*cH,
        r: squaresCoherent ? r : seedRng.rC([0, 0.01, 0.05, 0.1, 0.5, 1])*cH
      }) 
    }
    return sqs
  }

  function dsb(sqs) {
    p.noStroke()
    for (let i=0; i<sqs.length; i++) {
      if (i===0 && colorChance) {
        p.fill(primaryColor)
      } else {
        p.fill(bgcc)
      }
      p.rect(sqs[i].x, sqs[i].y, sqs[i].w, sqs[i].w, sqs[i].r)
    }
  }
    

  function glb() {
    let ls = []
    for (let i=0; i<linesSettingAmount; i++) {
      if (seedRng.rB(0,1) > linesSettingPercHoriz) {
        ls.push({
          xs: -0.5*cW,
          ys: seedRng.rB(-0.5, 1.5)*cH,
          xe: 1.5*cW,
          ye: seedRng.rB(-0.5, 1.5)*cH,
        }) 
      } else {
        ls.push({
          xs: seedRng.rB(-0.5, 1.5)*cW,
          ys: -0.5*cH,
          xe: seedRng.rB(-0.5, 1.5)*cW,
          ye: 1.5*cH,
        }) 
      }
    }
    return ls
  }

  function dlb(ls) {
    p.strokeWeight(cW / linesSettingStrokeWeight)
    for (let i=0; i<ls.length; i++) {
      if (i===0 && colorChance) {
        p.stroke(primaryColor)
      } else {
        p.stroke(bgcc)
      }
      p.line(ls[i].xs, ls[i].ys, ls[i].xe, ls[i].ye)
    }
    p.noStroke()
  }
    
  function df() {
    if (frameWidth > 0) {
      p.fill(frameColor)
      p.rect(0, 0, cW, (wallWidth+frameWidth)*cH)
      p.rect(cW-(wallWidth+frameWidth)*cH, 0, (wallWidth+frameWidth)*cH, cH)
      p.rect(0, (1-wallWidth-frameWidth)*cH, cW, (wallWidth+frameWidth)*cH)
      p.rect(0, 0, (wallWidth+frameWidth)*cH, cH)

      if (frameColumns === 2) {
        p.rect(cW*0.5-(cH*(wallWidth+2*frameWidth)/2), 0, cH*(wallWidth+2*frameWidth), cH)
      }
      if (frameColumns === 3) {
        p.rect(cW*1/3-(cH*frameWidth), 0, cH*(wallWidth/2+2*frameWidth), cH)
        p.rect(cW*2/3-(cH*(wallWidth/2+frameWidth)), 0, cH*(wallWidth/2+2*frameWidth), cH)
      }
      if (frameColumns === 4) {
        p.rect(cW*0.25-(cH*frameWidth), 0, cH*(wallWidth*2/3+2*frameWidth), cH)
        p.rect(cW*0.5-(cH*(wallWidth/3+frameWidth)), 0, cH*(wallWidth*2/3+2*frameWidth), cH)
        p.rect(cW*0.75-(cH*(wallWidth*2/3+frameWidth)), 0, cH*(wallWidth*2/3+2*frameWidth), cH)
      }
      if (frameRows === 2) {
        p.rect(0, (0.5-(wallWidth+2*frameWidth)/2)*cH, cW, (wallWidth+2*frameWidth)*cH)
      }
      if (frameRows === 3) {
        p.rect(0, (1/3-frameWidth)*cH, cW, (wallWidth/2+2*frameWidth)*cH)
        p.rect(0, (2/3-wallWidth/2-frameWidth)*cH, cW, (2*frameWidth+wallWidth/2)*cH)
      }
    }
    p.fill(wallColor)
    p.rect(0, 0, cW, wallWidth*cH)
    p.rect(cW-wallWidth*cH, 0, wallWidth*cH, cH)
    p.rect(0, (1-wallWidth)*cH, cW, wallWidth*cH)
    p.rect(0, 0, wallWidth*cH, cH)
    if (frameColumns === 2) {
      p.rect(cW*0.5-(cH*wallWidth/2), 0, cH*wallWidth, cH)
    }
    if (frameColumns === 3) {
      p.rect(cW*1/3, 0, cH*wallWidth/2, cH)
      p.rect(cW*2/3-(cH*wallWidth/2), 0, cH*wallWidth/2, cH)
    }
    if (frameColumns === 4) {
      p.rect(cW*0.25, 0, cH*wallWidth*2/3, cH)
      p.rect(cW*0.5-(cH*wallWidth/3), 0, cH*wallWidth*2/3, cH)
      p.rect(cW*0.75-(cH*wallWidth*2/3), 0, cH*wallWidth*2/3, cH)
    }
    if (frameRows === 2) {
      p.rect(0, cH*(0.5-wallWidth/2), cW, cH*wallWidth)
    }
    if (frameRows === 3) {
      p.rect(0, cH*(1/3), cW, cH*wallWidth/2)
      p.rect(0, cH*(2/3-wallWidth/2), cW, cH*wallWidth/2)
    }
  }
    
  function dif() {
    if (frameWidth > 0) {
      p.fill(frameColor)
      p.rect(0, 0, cW, (frameWidth+wallOuterWidth)*cH)
      p.rect(cW-(frameWidth+wallOuterWidth)*cH, 0, (frameWidth+wallOuterWidth)*cH, cH)
      p.rect(0, (1-(frameWidth+wallOuterWidth))*cH, cW, (frameWidth+wallOuterWidth)*cH)
      p.rect(0, 0, (frameWidth+wallOuterWidth)*cH, cH)
      for(const y of irrFramesYs) {
        p.rect(cW*y-frameWidth*cH-wallWidth*cH/2, 0, (frameWidth*2+wallWidth)*cH, cH)
      }
      for (const x of irrFramesXs) {
        p.rect(0, cH*x-frameWidth*cH-wallWidth*cH/2, cW, (frameWidth*2+wallWidth)*cH)
      }
    }
    p.fill(wallColor)
    p.rect(0, 0, cW, wallOuterWidth*cH)
    p.rect(cW-wallOuterWidth*cH, 0, wallOuterWidth*cH, cH)
    p.rect(0, (1-wallOuterWidth)*cH, cW, wallOuterWidth*cH)
    p.rect(0, 0, wallOuterWidth*cH, cH)
    for(const y of irrFramesYs) {
      p.rect(cW*y-wallWidth/2*cH, 0, wallWidth*cH, cH)
    }
    for (const x of irrFramesXs) {
      p.rect(0, cH*x-wallWidth/2*cH, cW, wallWidth*cH)
    }
  }
    
  p.keyPressed = () => {
    if (p.keyCode === 83) {
      showDialogue = !showDialogue
    }
  }
}

