import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Linebreak extends Component {

    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div>
                            <div style={{padding: "50px 50px 0 50px"}}>
                                <p className="collectionTitle">
                                    Linebreak
                                </p>
                                <img alt='' src={'/images/linebreak/linebreak1.jpg'} className="pieceImgGen" />
                                <img alt='' src={'/images/linebreak/linebreak2.jpg'} className="pieceImgGen" />
                                <img alt='' src={'/images/linebreak/linebreak3.jpg'} className="pieceImgGen" />
                                <p>
                                    Linebreak is the story of a curious line.<br/><br/>
                                    The algorithm for this series narrates this story in two parallel ways: 
                                    every visual output is paired with some verses that can be read by (T)oggling with the keyboard, 
                                    and the whole poetic story can be (D)ownloaded.<br/><br/>
                                    In the visual outputs, the line breaks and evolves,
                                    almost like following the birth of a language with increasing degrees of freedom and complexity.
                                    The verses in their entirety tell a story that has multiple interpretations.
                                    The theme of interpreting and using the person's own creativity is synergic,
                                    since the visual outputs feature an asemic language I crafted over time.
                                    Or better, not asemic, but multisemic, since it has meaning but it's up to each viewer to derive it.<br/><br/>
                                    Imagination is closely tied to perception, the more we experience,
                                    the more power our mind has to create new concepts by assembling existing ones.
                                    It's only by feeding more perceptions to ourselves that we can enhance our creativity,
                                    and learn to interpret our perceptions in different ways. The same can be applied to reading.
                                    The more we're used to think about what we are consuming the more we will be able to get information out of it.
                                </p>
                                <hr/>
                            </div>
                            <div className="coupleCollections">
                                <div className="collectionLeft">
                                    {Array.from({length: 50}, (_, index) => index).map(i => (
                                        <img key={i} alt='' src={'/images/linebreak/' + ('0' + i.toString()).slice(-2) + 'Lines.png'} className="pieceImgGen" />
                                    ))}
                                </div>
                                <div className="collectionRight">
                                    {Array.from({length: 50}, (_, index) => index).map(i => (
                                        <img key={i} alt='' src={'/images/linebreak/' + i.toString() + '.png'} className="pieceImgGen" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Linebreak
                            </p>
                            <img alt='' src={'/images/linebreak/linebreak1.jpg'} className="pieceImgGen" />
                            <img alt='' src={'/images/linebreak/linebreak2.jpg'} className="pieceImgGen" />
                            <img alt='' src={'/images/linebreak/linebreak3.jpg'} className="pieceImgGen" />
                            <p>
                                Linebreak is the story of a curious line.<br/><br/>
                                The algorithm for this series narrates this story in two parallel ways: 
                                every visual output is paired with some verses that can be read by (T)oggling with the keyboard, 
                                and the whole poetic story can be (D)ownloaded.<br/><br/>
                                In the visual outputs, the line breaks and evolves,
                                almost like following the birth of a language with increasing degrees of freedom and complexity.
                                The verses in their entirety tell a story that has multiple interpretations.
                                The theme of interpreting and using the person's own creativity is synergic,
                                since the visual outputs feature an asemic language I crafted over time.
                                Or better, not asemic, but multisemic, since it has meaning but it's up to each viewer to derive it.<br/><br/>
                                Imagination is closely tied to perception, the more we experience,
                                the more power our mind has to create new concepts by assembling existing ones.
                                It's only by feeding more perceptions to ourselves that we can enhance our creativity,
                                and learn to interpret our perceptions in different ways. The same can be applied to reading.
                                The more we're used to think about what we are consuming the more we will be able to get information out of it.
                            </p>
                            <hr/>
                            {Array.from({length: 50}, (_, index) => index).map(i => (<>
                                <img key={i} alt='' src={'/images/linebreak/' + ('0' + i.toString()).slice(-2) + 'Lines.png'} className="pieceImgGen" />
                                <img key={i} alt='' src={'/images/linebreak/' + i.toString() + '.png'} className="pieceImgGen" />
                            </>))}
                        </div>
                    }
                </div>
            </div>
        )
    }
};