import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Corpus extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="Shred"
                                    link="/corpus/0"
                                    imgLink='/images/0.png'
                                />
                                <CollectionPiece 
                                    title="River"
                                    link="/corpus/2"
                                    imgLink='/images/2.png'
                                />
                                <CollectionPiece 
                                    title="Branches that cry"
                                    link="/corpus/4"
                                    imgLink='/images/4.png'
                                />
                                <CollectionPiece 
                                    title="Dam"
                                    link="/corpus/6"
                                    imgLink='/images/6.png'
                                />
                                <CollectionPiece 
                                    title="Soft mist"
                                    link="/corpus/8"
                                    imgLink='/images/8.png'
                                />
                                <CollectionPiece 
                                    title="On the other side"
                                    link="/corpus/10"
                                    imgLink='/images/10.png'
                                />
                                <CollectionPiece 
                                    title="Equilibrium"
                                    link="/corpus/12"
                                    imgLink='/images/12.png'
                                />
                                <CollectionPiece 
                                    title="I just wanted to be happy"
                                    link="/corpus/14"
                                    imgLink='/images/14.png'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Ouroboros - on EncapsuledCorpus
                                </p>
                                <p>
                                    Corpus <i>noun</i><br/>
                                    : all the writings or works of a particular kind or on a particular subject<br/>
                                    especially : the complete works of an author
                                </p>
                                <p>
                                    Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                    What does it mean?
                                    The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                    You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                                </p>
                                <p>
                                    Ouroboros is a collection I wrote between 2011 and 2012 in Italian, and is the first that I was proud of. <br/>
                                    It went through a lot of editing, and has been translated into English.<br/>
                                    It's the first collection I published in my custom smart contract.
                                </p>
                                <CollectionPiece 
                                    title="Beating of wings"
                                    link="/corpus/1"
                                    imgLink='/images/1.png'
                                />
                                <CollectionPiece 
                                    title="Transient"
                                    link="/corpus/3"
                                    imgLink='/images/3.png'
                                />
                                <CollectionPiece 
                                    title="Past, present"
                                    link="/corpus/5"
                                    imgLink='/images/5.png'
                                />
                                <CollectionPiece 
                                    title="Inspiration"
                                    link="/corpus/7"
                                    imgLink='/images/7.png'
                                />
                                <CollectionPiece 
                                    title="Green eyes"
                                    link="/corpus/9"
                                    imgLink='/images/9.png'
                                />
                                <CollectionPiece 
                                    title="Humanity"
                                    link="/corpus/11"
                                    imgLink='/images/11.png'
                                />
                                <CollectionPiece 
                                    title="Growing"
                                    link="/corpus/13"
                                    imgLink='/images/13.png'
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Ouroboros - on EncapsuledCorpus
                            </p>
                            <p>
                                Corpus <i>noun</i><br/>
                                : all the writings or works of a particular kind or on a particular subject<br/>
                                especially : the complete works of an author
                            </p>
                            <p>
                                Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                What does it mean?
                                The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                            </p>
                            <p>
                                Ouroboros is a collection I wrote between 2011 and 2012 in Italian, and is the first that I was proud of. <br/>
                                It went through a lot of editing, and has been translated into English.<br/>
                                It's the first collection I published in my custom smart contract.
                            </p>
                            <CollectionPiece 
                                title="Shred"
                                link="/corpus/0"
                                imgLink='/images/0.png'
                            />
                            <CollectionPiece 
                                title="Beating of wings"
                                link="/corpus/1"
                                imgLink='/images/1.png'
                            />
                            <CollectionPiece 
                                title="River"
                                link="/corpus/2"
                                imgLink='/images/2.png'
                            />
                            <CollectionPiece 
                                title="Transient"
                                link="/corpus/3"
                                imgLink='/images/3.png'
                            />
                            <CollectionPiece 
                                title="Branches that cry"
                                link="/corpus/4"
                                imgLink='/images/4.png'
                            />
                            <CollectionPiece 
                                title="Past, present"
                                link="/corpus/5"
                                imgLink='/images/5.png'
                            />
                            <CollectionPiece 
                                title="Dam"
                                link="/corpus/6"
                                imgLink='/images/6.png'
                            />
                            <CollectionPiece 
                                title="Inspiration"
                                link="/corpus/7"
                                imgLink='/images/7.png'
                            />
                            <CollectionPiece 
                                title="Soft mist"
                                link="/corpus/8"
                                imgLink='/images/8.png'
                            />
                            <CollectionPiece 
                                title="Green eyes"
                                link="/corpus/9"
                                imgLink='/images/9.png'
                            />
                            <CollectionPiece 
                                title="On the other side"
                                link="/corpus/10"
                                imgLink='/images/10.png'
                            />
                            <CollectionPiece 
                                title="Humanity"
                                link="/corpus/11"
                                imgLink='/images/11.png'
                            />
                            <CollectionPiece 
                                title="Equilibrium"
                                link="/corpus/12"
                                imgLink='/images/12.png'
                            />
                            <CollectionPiece 
                                title="Growing"
                                link="/corpus/13"
                                imgLink='/images/13.png'
                            />
                            <CollectionPiece 
                                title="I just wanted to be happy"
                                link="/corpus/14"
                                imgLink='/images/14.png'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Corpus;