export default function sketch(p5) {

  const u = new URLSearchParams(window.location.search)
  p5.disableFriendlyErrors = true;

  class R {
    constructor(seed) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(seed.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(seed.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }
  }

  function gsft(d) {
    return '' + d.getUTCFullYear() + d.getUTCMonth() + d.getUTCDay() + d.getUTCHours() + d.getUTCMinutes()
  }

  function gpsft(d) {
    let pd = new Date(d - 60000)
    return '' + pd.getUTCFullYear() + pd.getUTCMonth() + pd.getUTCDay() + pd.getUTCHours() + pd.getUTCMinutes()
  }

  function gnsft(d) {
    let pd = new Date(d.getTime() + 60000)
    return '' + pd.getUTCFullYear() + pd.getUTCMonth() + pd.getUTCDay() + pd.getUTCHours() + pd.getUTCMinutes()
  }

  function iar(v, inc, min, max) {
    v += inc
    if (v<min) {
      v = min
    }
    if (v>max) {
      v = max
    }
    return v
  }

  function gp(rng, extra) {
    let ps = []
    let speaker = rng.rI(0, ns-1)
    let emotion = rng.rI(0, 360)
    let emotionMaxIntensityPerc = rng.rB(0, 1)
    let emotionMaxIntensity = emotionMaxIntensityPerc < 0.5 ? 50 : emotionMaxIntensityPerc < 0.7 ? 70 : emotionMaxIntensityPerc < 0.9 ? 90 : 100
    let maxIntensity = rng.rI(0, emotionMaxIntensity)
    let silence = rng.rB(0, 1) > 0.5
    let silenceLength = rng.rC([5, 10, 20, 50])
    let thickness = rng.rC([0.001, 0.002, 0.003])
    let al = rng.rC([0.1, 0.3, 0.5, 1])
    let justTalkedOver = false
    let speakerStyle, newSpeaker, saturation
    for (let i=0; i<=200; i++) {
      if (!(silence && i<silenceLength)) {
        if (rng.rB(0, 1) < 0.9) {
          if (rng.rB(0, 1) > ds) {   
            newSpeaker = rng.rI(0, ns-1)
            if (newSpeaker !== speaker) {   
              emotion = iar(emotion, rng.rB(-5, 5), 0, 360)
              thickness = rng.rC([0.001, 0.002, 0.003])
              al = rng.rC([0.1, 0.3, 0.5, 1])
            }
            speaker = newSpeaker
          }
          thickness = iar(thickness, rng.rB(-0.0005, 0.0005), 0.001, 0.005)
          al = iar(al, rng.rB(-0.05, 0.05), 0.1, 1)
          saturation = i <= 100 ? i : 200-i
          saturation = p5.map(saturation, 0, 100, 0, maxIntensity)
          if (r) {
            saturation = 100-saturation
          }
          let y = ph[speaker]
          speakerStyle = ss[speaker]
          steps = speakerStyle >= 1 ? 60 : 30
          ps = ps.concat(gcp({
            x: i/200 + extra,
            y: y,
            a: rng.rI(0, p5.TWO_PI),
            c: p5.color(emotion, saturation, b),
            t: thickness,
            al: al,
          }, speakerStyle, steps, rng, y))
          
          if (rng.rB(0, 1) < o && !justTalkedOver) {
            let rs = rng.rI(0, ns-1)
            speakerStyle = ss[speaker]
            steps = 20
            for (const talkOverI of [i, i+1]) {
              ps = ps.concat(gcp({
                x: talkOverI/200 + extra,
                y: ph[rs],
                a: rng.rI(0, p5.TWO_PI),
                c: p5.color(emotion, saturation, b),
                t: thickness,
                al: al,
              }, speakerStyle, steps, rng, ph[rs]))
            }
            justTalkedOver = true
          } else {
            if (justTalkedOver) {
              justTalkedOver = false
            }
          }
        }
      }
    }
    return [...ps]
  }

  function gcp(p, angleBoundary, steps, rng, startY) {
    let stepSize = angleBoundary >= 1 ? 0.001 : 0.0005
    let ps = []
    let x = p.x
    let y = p.y
    let a = p.a
    let na = rng.rB(-angleBoundary, angleBoundary)
    for (let i=0; i<steps; i++) {
      ps.push({
        x: x,
        y: y,
        d: p.d,
        c: p.c,
        t: p.t,
        al: p.al,
      })
      a += na
      x += Math.cos(a)*stepSize
      y += Math.sin(a)*stepSize*ar
      if (y < (startY-0.1) || y > (startY+0.1)) {
        break
      }
      if (i%cont === 0) {
        na = rng.rB(-angleBoundary, angleBoundary)
      }
    }
    return ps
  }

  function dp(p, currStep) {
    let c = p.c
    c.setAlpha(p.al)
    if (hollow) {
      p5.stroke(c)
    } else {
      p5.fill(c)
    }
    p5.circle((p.x - currStep)*cW, p.y*cH, p.t*cH*th)
  }

  let rr
  let pr
  let nr
  let cW, cH
  let ps = []
  let pp = []
  let np = []
  let ap = []
  let ns = u.get("s") || "2"
  let ph = ns === "1" ? [0.5] : ns === "2" ? [0.35, 0.65] : ns === "3" ? [0.25, 0.5, 0.75] : ns === "4" ? [0.2, 0.4, 0.6, 0.8] : ns === "5" ? [0.2, 0.35, 0.5, 0.65, 0.8] : []
  let ss = [
    parseFloat(u.get("s1")) || 2, 
    parseFloat(u.get("s2")) || 2,
    parseFloat(u.get("s3")) || 2,
    parseFloat(u.get("s4")) || 2,
    parseFloat(u.get("s5")) || 2,
  ]
  let r = u.get("r") === "1"
  let hollow = u.get("h") === "1"
  let th = parseFloat(u.get("t")) || 1
  let o = parseFloat(u.get("o")) || 0.1
  let ds = parseFloat(u.get("d")) || 0.95
  let b = parseInt(u.get("b")) || (r ? 40 : 98)
  let cont = parseInt(u.get("c")) || 50
  let ar

  let steps
  let done = false

  p5.setup = () => {
    let d = new Date()
    rr = new R(gsft(d));
    pr = new R(gpsft(d));
    nr = new R(gnsft(d));
    cW = p5.windowWidth*0.75
    cH = p5.windowHeight*0.75
    ar = parseFloat(u.get("a")) || cW/cH
    p5.createCanvas(cW, cH)
    p5.frameRate(30)
    p5.colorMode(p5.HSB)
    
    ps = gp(rr, 0.5)
    pp = gp(pr, -0.5)
    np = gp(nr, 1.5)
    ap = [...pp, ...ps, ...np]
  }

  p5.draw = () => {
    let d = new Date()
    let s = d.getUTCSeconds()
    let ms = d.getUTCMilliseconds()
    if (s < 1 && !done) {
      rr = new R(gsft(d));
      pr = new R(gpsft(d));
      nr = new R(gnsft(d));
      ps = gp(rr, 0.5)
      pp = gp(pr, -0.5)
      np = gp(nr, 1.5)
      ap = [...pp, ...ps, ...np]
      done = true
    }
    if (s === 42) {
      done = false
    }
    let currStep = (s + ms/1000)/60
    p5.background(r ? "#fafafa" : "#0f0f0f")
    p5.noStroke()
    p5.noFill()
    
    for (let i=0; i<ap.length; i++) {
      if (ap[i].x - currStep > -0.05 && 
          ap[i].x - currStep < 1.05) {
        dp(ap[i], currStep)
      }
    }
  }
}

