import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Disegni extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="Another" 
                                    link="/corpus/46"
                                    imgLink='/images/46.png'
                                />
                                <p className="poemInCollection">
                                    Day after day<br/>
                                    repeating,<br/>
                                    it felt as if<br/>
                                    everything was losing shape<br/>
                                    and content.<br/>
                                    I'd look out:<br/>
                                    the colors gone,<br/>
                                    but why change?<br/>
                                    It will all repeat,<br/>
                                    just a longer<br/>
                                    period<br/>
                                    just another<br/>
                                    grey<br/>
                                    just<br/>
                                    stop.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Flow" 
                                    link="/corpus/50"
                                    imgLink='/images/50.png'
                                />
                                <p className="poemInCollection">
                                    Things change.<br/>
                                    It's inevitable,<br/>
                                    I tried to resist many times<br/>
                                    "this will not last"<br/>
                                    "it's just a period"<br/>
                                    but the world never listened.<br/>
                                    And so I changed too.<br/>
                                    I stopped resisting<br/>
                                    and started embracing.<br/>
                                    The flow<br/>
                                    is not against you.<br/>
                                    The flow just is.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Above the clouds" 
                                    link="/corpus/45"
                                    imgLink='/images/45.png'
                                />
                                <p className="poemInCollection">
                                    They asked him as he grew up<br/>
                                    "what do you want to do?"<br/>
                                    His answer never changed:<br/>
                                    "I want to build<br/>
                                    above the clouds".<br/>
                                    A child's dream,<br/>
                                    impossible,<br/>
                                    until it became<br/>
                                    a child's dream<br/>
                                    in an adult's body,<br/>
                                    that looked at them<br/>
                                    from above the clouds.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Shut" 
                                    link="/corpus/52"
                                    imgLink='/images/52.png'
                                />
                                <p className="poemInCollection">
                                    I've been in that dark place.<br/>
                                    I remember the feeling,<br/>
                                    it's hard<br/>
                                    to get out of it.<br/>
                                    When you think<br/>
                                    you see the light<br/>
                                    a door shuts<br/>
                                    in front of you,<br/>
                                    but remember:<br/>
                                    darkness is not scary,<br/>
                                    you get used to it,<br/>
                                    and it's just a moment,<br/>
                                    a long one,<br/>
                                    but it will pass too.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Today" 
                                    link="/corpus/56"
                                    imgLink='/images/56.png'
                                />
                                <p className="poemInCollection">
                                    Another day,<br/>
                                    they feel<br/>
                                    all the same,<br/>
                                    trapped<br/>
                                    in an endless loop<br/>
                                    waiting for something,<br/>
                                    anything,<br/>
                                    to happen.<br/>
                                    But I know it won't:<br/>
                                    nothing just happens.<br/>
                                    I'd need to do something,<br/>
                                    anything,<br/>
                                    but I know I won't.<br/>
                                    I'll enjoy the sun<br/>
                                    and the blue sky,<br/>
                                    that'll be enough<br/>
                                    for today.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Artwork" 
                                    link="/corpus/48"
                                    imgLink='/images/48.png'
                                />
                                <p className="poemInCollection">
                                    Letter by letter,<br/>
                                    a flow of lines<br/>
                                    coming out of a<br/>
                                    restless mind,<br/>
                                    the hand follows<br/>
                                    a path that is<br/>
                                    yet to be discovered<br/>
                                    with the intent to create<br/>
                                    an artwork<br/>
                                    about the artwork.
                                </p>
                                <hr/>
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Disegni di poesia - on EncapsuledCorpus
                                </p>
                                <p>
                                    Corpus <i>noun</i><br/>
                                    : all the writings or works of a particular kind or on a particular subject<br/>
                                    especially : the complete works of an author
                                </p>
                                <p>
                                    Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                    What does it mean?
                                    The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                    You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                                </p>
                                <p>
                                    Poems taken apart and rebuilt,<br/>
                                    letter by letter,<br/>
                                    to enhance the viewer's experience<br/>
                                    in a synergy of visual and poetry.<br/><br/>
                                    Poems are provided in the description of the artworks and can be read from the image directly.
                                </p>
                                <CollectionPiece 
                                    title="Trees" 
                                    link="/corpus/55"
                                    imgLink='/images/55.png'
                                />
                                <p className="poemInCollection">
                                    It used to be<br/>
                                    endless,<br/>
                                    the trees would go<br/>
                                    as far as your eyes could see,<br/>
                                    until they decided<br/>
                                    to build a road.<br/>
                                    A road to nowhere,<br/>
                                    to this day<br/>
                                    you can count the cars<br/>
                                    crossing it,<br/>
                                    it doesn't add up to ten a day,<br/>
                                    but now you can also count<br/>
                                    the trees,<br/>
                                    they don't add up to ten either.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Stack" 
                                    link="/corpus/53"
                                    imgLink='/images/53.png'
                                />
                                <p className="poemInCollection">
                                    That moment<br/>
                                    between day and night,<br/>
                                    the sun already gone<br/>
                                    but the sky is still bright,<br/>
                                    people rushing to their homes<br/>
                                    "faster,<br/>
                                    there's so little time left<br/>
                                    for today"<br/>
                                    and after opening the door,<br/>
                                    saying a quick "I'm back"<br/>
                                    they get ready for an encore,<br/>
                                    just another day on the stack.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Street lights" 
                                    link="/corpus/54"
                                    imgLink='/images/54.png'
                                />
                                <p className="poemInCollection">
                                    That moment<br/>
                                    when the street lights<br/>
                                    turn on<br/>
                                    but the sun is still up,<br/>
                                    refusing to go to sleep,<br/>
                                    "there's more for today"<br/>
                                    it says,<br/>
                                    but the street lights don't lie<br/>
                                    and so it sets,<br/>
                                    The only thing left<br/>
                                    is a shadow of pink and yellow,<br/>
                                    the night's call.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Foam" 
                                    link="/corpus/51"
                                    imgLink='/images/51.png'
                                />
                                <p className="poemInCollection">
                                    The foam<br/>
                                    born from the violence<br/>
                                    of waves crashing,<br/>
                                    provoked by the wind<br/>
                                    and the currents of the sea,<br/>
                                    so white,<br/>
                                    and so brief,<br/>
                                    it vanishes<br/>
                                    as fast as it formed,<br/>
                                    a brief spark of life<br/>
                                    that endlessly repeats,<br/>
                                    the beauty<br/>
                                    of the ephemeral.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Apparition" 
                                    link="/corpus/47"
                                    imgLink='/images/47.png'
                                />
                                <p className="poemInCollection">
                                    I saw him in my dreams<br/>
                                    whispering,<br/>
                                    it was the first time<br/>
                                    I could not hear the words,<br/>
                                    but there was no need to.<br/>
                                    I knew<br/>
                                    the message<br/>
                                    he brought to me:<br/>
                                    a direction<br/>
                                    a way forward<br/>
                                    a compass to guide<br/>
                                    my steps.<br/>
                                    He was smiling,<br/>
                                    he knew I'd follow him,<br/>
                                    I had<br/>
                                    nowhere else<br/>
                                    to go.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Fine line" 
                                    link="/corpus/49"
                                    imgLink='/images/49.png'
                                />
                                <p className="poemInCollection">
                                    It's a fine line,<br/>
                                    you don't realize you crossed it:<br/>
                                    the colors fade,<br/>
                                    there's silence,<br/>
                                    and then<br/>
                                    nothing.<br/>
                                    Your mind<br/>
                                    stops,<br/>
                                    and it's not cold<br/>
                                    anymore.
                                </p>
                                <hr/>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Disegni di poesia - on EncapsuledCorpus
                            </p>
                            <p>
                                Corpus <i>noun</i><br/>
                                : all the writings or works of a particular kind or on a particular subject<br/>
                                especially : the complete works of an author
                            </p>
                            <p>
                                Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                What does it mean?
                                The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                            </p>
                            <p>
                                Poems taken apart and rebuilt,<br/>
                                letter by letter,<br/>
                                to enhance the viewer's experience<br/>
                                in a synergy of visual and poetry.<br/><br/>
                                Poems are provided in the description of the artworks and can be read from the image directly.
                            </p>
                            <CollectionPiece 
                                title="Trees" 
                                link="/corpus/55"
                                imgLink='/images/55.png'
                            />
                            <p className="poemInCollection">
                                It used to be<br/>
                                endless,<br/>
                                the trees would go<br/>
                                as far as your eyes could see,<br/>
                                until they decided<br/>
                                to build a road.<br/>
                                A road to nowhere,<br/>
                                to this day<br/>
                                you can count the cars<br/>
                                crossing it,<br/>
                                it doesn't add up to ten a day,<br/>
                                but now you can also count<br/>
                                the trees,<br/>
                                they don't add up to ten either.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Another" 
                                link="/corpus/46"
                                imgLink='/images/46.png'
                            />
                            <p className="poemInCollection">
                                Day after day<br/>
                                repeating,<br/>
                                it felt as if<br/>
                                everything was losing shape<br/>
                                and content.<br/>
                                I'd look out:<br/>
                                the colors gone,<br/>
                                but why change?<br/>
                                It will all repeat,<br/>
                                just a longer<br/>
                                period<br/>
                                just another<br/>
                                grey<br/>
                                just<br/>
                                stop.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Stack" 
                                link="/corpus/53"
                                imgLink='/images/53.png'
                            />
                            <p className="poemInCollection">
                                That moment<br/>
                                between day and night,<br/>
                                the sun already gone<br/>
                                but the sky is still bright,<br/>
                                people rushing to their homes<br/>
                                "faster,<br/>
                                there's so little time left<br/>
                                for today"<br/>
                                and after opening the door,<br/>
                                saying a quick "I'm back"<br/>
                                they get ready for an encore,<br/>
                                just another day on the stack.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Flow" 
                                link="/corpus/50"
                                imgLink='/images/50.png'
                            />
                            <p className="poemInCollection">
                                Things change.<br/>
                                It's inevitable,<br/>
                                I tried to resist many times<br/>
                                "this will not last"<br/>
                                "it's just a period"<br/>
                                but the world never listened.<br/>
                                And so I changed too.<br/>
                                I stopped resisting<br/>
                                and started embracing.<br/>
                                The flow<br/>
                                is not against you.<br/>
                                The flow just is.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Above the clouds"
                                link="/corpus/45"
                                imgLink='/images/45.png'
                            />
                            <p className="poemInCollection">
                                They asked him as he grew up<br/>
                                "what do you want to do?"<br/>
                                His answer never changed:<br/>
                                "I want to build<br/>
                                above the clouds".<br/>
                                A child's dream,<br/>
                                impossible,<br/>
                                until it became<br/>
                                a child's dream<br/>
                                in an adult's body,<br/>
                                that looked at them<br/>
                                from above the clouds.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Street lights" 
                                link="/corpus/54"
                                imgLink='/images/54.png'
                            />
                            <p className="poemInCollection">
                                That moment<br/>
                                when the street lights<br/>
                                turn on<br/>
                                but the sun is still up,<br/>
                                refusing to go to sleep,<br/>
                                "there's more for today"<br/>
                                it says,<br/>
                                but the street lights don't lie<br/>
                                and so it sets,<br/>
                                The only thing left<br/>
                                is a shadow of pink and yellow,<br/>
                                the night's call.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Shut" 
                                link="/corpus/52"
                                imgLink='/images/52.png'
                            />
                            <p className="poemInCollection">
                                I've been in that dark place.<br/>
                                I remember the feeling,<br/>
                                it's hard<br/>
                                to get out of it.<br/>
                                When you think<br/>
                                you see the light<br/>
                                a door shuts<br/>
                                in front of you,<br/>
                                but remember:<br/>
                                darkness is not scary,<br/>
                                you get used to it,<br/>
                                and it's just a moment,<br/>
                                a long one,<br/>
                                but it will pass too.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Foam" 
                                link="/corpus/51"
                                imgLink='/images/51.png'
                            />
                            <p className="poemInCollection">
                                The foam<br/>
                                born from the violence<br/>
                                of waves crashing,<br/>
                                provoked by the wind<br/>
                                and the currents of the sea,<br/>
                                so white,<br/>
                                and so brief,<br/>
                                it vanishes<br/>
                                as fast as it formed,<br/>
                                a brief spark of life<br/>
                                that endlessly repeats,<br/>
                                the beauty<br/>
                                of the ephemeral.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Apparition" 
                                link="/corpus/47"
                                imgLink='/images/47.png'
                            />
                            <p className="poemInCollection">
                                I saw him in my dreams<br/>
                                whispering,<br/>
                                it was the first time<br/>
                                I could not hear the words,<br/>
                                but there was no need to.<br/>
                                I knew<br/>
                                the message<br/>
                                he brought to me:<br/>
                                a direction<br/>
                                a way forward<br/>
                                a compass to guide<br/>
                                my steps.<br/>
                                He was smiling,<br/>
                                he knew I'd follow him,<br/>
                                I had<br/>
                                nowhere else<br/>
                                to go.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Today" 
                                link="/corpus/56"
                                imgLink='/images/56.png'
                            />
                            <p className="poemInCollection">
                                Another day,<br/>
                                they feel<br/>
                                all the same,<br/>
                                trapped<br/>
                                in an endless loop<br/>
                                waiting for something,<br/>
                                anything,<br/>
                                to happen.<br/>
                                But I know it won't:<br/>
                                nothing just happens.<br/>
                                I'd need to do something,<br/>
                                anything,<br/>
                                but I know I won't.<br/>
                                I'll enjoy the sun<br/>
                                and the blue sky,<br/>
                                that'll be enough<br/>
                                for today.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Fine line" 
                                link="/corpus/49"
                                imgLink='/images/49.png'
                            />
                            <p className="poemInCollection">
                                It's a fine line,<br/>
                                you don't realize you crossed it:<br/>
                                the colors fade,<br/>
                                there's silence,<br/>
                                and then<br/>
                                nothing.<br/>
                                Your mind<br/>
                                stops,<br/>
                                and it's not cold<br/>
                                anymore.
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Artwork" 
                                link="/corpus/48"
                                imgLink='/images/48.png'
                            />
                            <p className="poemInCollection">
                                Letter by letter,<br/>
                                a flow of lines<br/>
                                coming out of a<br/>
                                restless mind,<br/>
                                the hand follows<br/>
                                a path that is<br/>
                                yet to be discovered<br/>
                                with the intent to create<br/>
                                an artwork<br/>
                                about the artwork.
                            </p>
                            <hr/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Disegni;