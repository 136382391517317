import React, { Component } from 'react'
import { CssTextField, BWButton } from '../components/CustomFormComponents';

import Web3 from "web3";

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

class MarketInterface extends Component {
    state = {
        amount: "",
        address: "",
        privateListing: false,
    }

    render() {
        return(this.props.loading ? <div style={{margin: 50}}>
                    <p>Waiting on confirmation...</p>
                    <CircularProgress style={{'color': '#fafafa'}}/>
                </div> 
                : this.props.tokenData ? <div>
                    <div style={{margin: "20px 0px"}}>
                        owned by&nbsp;
                        <a href={"https://etherscan.io/address/" + this.props.tokenData.owner} target="_blank" rel="noopener noreferrer">
                            {this.props.tokenData.ens ? this.props.tokenData.ens : this.props.tokenData.owner?.substring(0, 8) + '...'}
                        </a>
                        {this.props.account === this.props.tokenData.owner ? ' (you)' : null}
                    </div>

                    {this.props.tokenData.onSale.isForSale
                        ? <div style={{margin: "20px 0px"}}>
                            <p>Price: {Web3.utils.fromWei(this.props.tokenData.onSale.minValue.toString(), 'ether')} Ξ</p>
                            {this.props.tokenData.onSale.onlySellTo === '0x0000000000000000000000000000000000000000' ? null : <p>Listing reserved for {this.props.tokenData.onSale.onlySellTo}</p>}
                        </div>
                        : <div style={{marginBottom: 20}}>
                            <p>This poem is not listed for sale.</p>
                        </div>
                    }

                    {this.props.tokenData.bids.hasBid
                        ? <div style={{margin: "20px 0px"}}>
                            <p>Highest offer:</p>
                            <p>From&nbsp;
                                <a href={"https://etherscan.io/address/" + this.props.tokenData.bids.bidder} target="_blank" rel="noopener noreferrer">
                                    {this.props.tokenData.bids.bidder?.substring(0, 8)}...
                                </a>
                                &nbsp;{Web3.utils.fromWei(this.props.tokenData.bids.value.toString(), 'ether')}&nbsp;Ξ
                            </p>
                        </div>
                        : <div style={{marginBottom: 20}}>
                            <p>No offers on this poem.</p>
                        </div>
                    }
                    


                    {this.props.contract 
                        ? <div>
                            {this.props.account === this.props.tokenData.owner
                            ? <div style={{margin: "20px 0px"}}>
                                <p>Approved for trading: {this.props.isApprovedForAll ? 'Yes' : 'No'}</p>
                                {this.props.isApprovedForAll 
                                    ? <div>
                                        {this.props.tokenData.onSale.isForSale
                                            ? <div>
                                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="left" style={{marginBottom: 20}}>  
                                                    <Grid item>
                                                        <Checkbox onChange={(e) => this.setState({privateListing: e.target.checked})} sx={{ color: '#fafafa', '&.Mui-checked': { color: '#fafafa'} }}/> Private listing
                                                    </Grid>
                                                    {this.state.privateListing ? <Grid item>
                                                        <CssTextField size="small" fullWidth id="address" label="Address" variant="outlined" 
                                                            value={this.state.address} 
                                                            onChange={(event) => this.setState({address: event.target.value})}
                                                        />
                                                    </Grid> : null}
                                                    <Grid item>
                                                        <CssTextField size="small" id="amount" label="Price" variant="outlined" 
                                                            value={this.state.amount} 
                                                            onChange={(event) => this.setState({amount: event.target.value})}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">Ξ</InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <BWButton variant="outlined" onClick={() => this.props.listToken(this.state.amount, this.state.address)}>
                                                            Edit listing
                                                        </BWButton>
                                                    </Grid>
                                                </Grid>
                                                <BWButton variant="outlined" onClick={() => this.props.delistToken()}>Cancel listing</BWButton>
                                            </div>
                                            : <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="left">
                                                <Grid item>
                                                    <Checkbox onChange={(e) => this.setState({privateListing: e.target.checked})} sx={{ color: '#fafafa', '&.Mui-checked': { color: '#fafafa'} }}/> Private listing
                                                </Grid>
                                                {this.state.privateListing ? <Grid item>
                                                    <CssTextField size="small" fullWidth id="address" label="Address" variant="outlined" 
                                                        value={this.state.address} 
                                                        onChange={(event) => this.setState({address: event.target.value})}
                                                    />
                                                </Grid> : null}
                                                <Grid item>
                                                    <CssTextField size="small" id="amount" label="Price" variant="outlined" 
                                                        value={this.state.amount} 
                                                        onChange={(event) => this.setState({amount: event.target.value})}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">Ξ</InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <BWButton variant="outlined" onClick={() => this.props.listToken(this.state.amount, this.state.address)}>
                                                        List for sale
                                                    </BWButton>
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.props.tokenData.bids.hasBid
                                            ? <div style={{margin: "20px 0px"}}>
                                                <BWButton variant="outlined" onClick={() => this.props.acceptBidForToken()}>Accept offer</BWButton>
                                            </div>
                                            : null
                                        }
                                    </div>  
                                    : <BWButton variant="outlined" onClick={() => this.props.setApprovalForAll()}>Approve collection for trading to sell or accept offers.</BWButton>
                                }
                            </div>
                            : <div>

                                {this.props.tokenData.onSale.isForSale
                                    ? <div style={{marginBottom: 20}}>
                                        <BWButton disabled={this.props.tokenData.onSale.onlySellTo !== '0x0000000000000000000000000000000000000000' && this.props.tokenData.onSale.onlySellTo !== this.props.account} variant="outlined" onClick={() => this.props.buyToken(this.props.tokenData.onSale.minValue)}>Buy</BWButton>
                                    </div>
                                    : null
                                }
                                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        <CssTextField size="small" id="amount" label="Amount" variant="outlined" 
                                            value={this.state.amount} 
                                            onChange={(event) => this.setState({amount: event.target.value})}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Ξ</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BWButton variant="outlined" onClick={() => this.props.bidOnToken(this.state.amount)}>Make an offer</BWButton>
                                    </Grid>
                                </Grid>

                                {(this.props.tokenData.bids.hasBid && this.props.tokenData.bids.bidder === this.props.account)
                                    ? <div style={{margin: "20px 0px"}}>
                                        <BWButton variant="outlined" onClick={() => this.props.withdrawBidForToken()}>Withdraw offer</BWButton>
                                    </div>
                                    : null
                                }
                            </div>
                        }
                    </div>
                : <p>Please connect to interact.</p>
                }
            </div>
            : <CircularProgress style={{'color': '#fafafa'}}/>
        )
    }
}

export default MarketInterface;
