import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Indaco extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Indaco
                            </p>
                            <a href="https://mint.nftfactoryparis.com/indigo/mint/q3tnGCJS" target="_blank" rel="noopener noreferrer">
                                Available at NFT Factory
                            </a>
                            <br/><br/>
                            <img
                                src='/images/indaco_sm.png'
                                alt='indaco_sm' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p>
                                Are you and I so different?<br/>
                                Do you need to know my name<br/>
                                to feel what I feel?<br/>
                                Can you try<br/>
                                just try<br/>
                                to stretch your arms<br/>
                                your thoughts<br/>
                                around me<br/>
                                as I stretch myself<br/>
                                around you<br/>
                                the unknown?
                            </p>
                            <br/><br/>
                            <p>
                                Indaco is an artwork created for <a href='https://mint.nftfactoryparis.com/indigo' target='_blank' rel="noopener noreferrer">the NFT factory Indigo (the seventh color) drop and IRL show</a>.
                                The theme was proposed to the artists as follows: <i>An artistic reflection on the capacity for human society to apprehend natural diversity and oddities, through the prism of the indigo controversy.</i>
                            </p>
                            <p>
                                With Indaco I wanted to reflect on the human need to classify everything around us. So I let my mind wander:<br/><br/>
                                <i>People are afraid of the unknown.<br/>
                                We have an intrinsic need of naming and defining all things to fit them in boxes in our heads.<br/>
                                When they don't fit into our usual criterias, we struggle to understand and accept them: if it cannot be defined, it's scary and confusing.<br/>
                                But is this something that we should strive to change? The world grows more complex every passing day, is the need to define everything necessary to include the diverse?<br/>
                                Or can we accept people the way they are, even if there is no clear definition for their situation?<br/>
                                Each of us needs to actively recognize our instinctive tendencies, and the way society shaped our thinking process, in order to fight it and shape a more inclusive environment for everyone.</i>
                            </p>
                            <p>
                                With these thoughts I started exploring visuals and words, until the right idea came to me, in the form of an asemic piece accompanied by a poem.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}