import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Raw extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="The self"
                                    link="https://objkt.com/asset/hicetnunc/406480"
                                    imgLink='/images/raw/raw1.png'
                                />
                                <CollectionPiece 
                                    title="The stream" 
                                    link="https://objkt.com/asset/hicetnunc/422915"
                                    imgLink='/images/raw/raw3.png'
                                />
                                <CollectionPiece 
                                    title="[-]verse" 
                                    link="https://objkt.com/asset/hicetnunc/485952"
                                    imgLink='/images/raw/raw5.png'
                                />
                                <CollectionPiece 
                                    title="The odd" 
                                    link="https://objkt.com/asset/hicetnunc/513807"
                                    imgLink='/images/raw/raw7.png'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    EncapsuledRaw
                                </p>
                                <a href="https://objkt.com/profile/tz1bd9TdZGm3JxpGfmrTm9NrQt47UK1zi5a6/created" target="_blank" rel="noopener noreferrer">
                                    Available on objkt
                                </a>
                                <p>
                                    Poetry (almost) unedited.
                                </p>
                                <p>
                                    Encapsuled raw is a collection of poems that couldn't wait to get out. They had an inner raw energy that asked to get released. They are minimally edited and digitally hadnwritten.
                                </p>
                                <p>
                                    This collection has been closed with eight pieces in total.
                                </p>
                                <CollectionPiece 
                                    title="The atom"
                                    link="https://objkt.com/asset/hicetnunc/409841"
                                    imgLink='/images/raw/raw2.png'
                                />
                                <CollectionPiece 
                                    title="The flame"
                                    link="https://objkt.com/asset/hicetnunc/442365"
                                    imgLink='/images/raw/raw4.png'
                                />
                                <CollectionPiece 
                                    title="Chains"
                                    link="https://objkt.com/asset/hicetnunc/505865"
                                    imgLink='/images/raw/raw6.png'
                                />
                                <CollectionPiece 
                                    title="Weird Sunday" 
                                    link="https://objkt.com/asset/hicetnunc/521372"
                                    imgLink='/images/raw/raw8.png'
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                EncapsuledRaw
                            </p>
                            <a href="https://objkt.com/profile/tz1bd9TdZGm3JxpGfmrTm9NrQt47UK1zi5a6/created" target="_blank" rel="noopener noreferrer">
                                Available on objkt
                            </a>
                            <p>
                                Poetry (almost) unedited.
                            </p>
                            <p>
                                Encapsuled raw is a collection of poems that couldn't wait to get out. They had an inner raw energy that asked to get released. They are minimally edited and digitally hadnwritten.
                            </p>
                            <p>
                                This collection has been closed with eight pieces in total.
                            </p>
                            <CollectionPiece 
                                title="The self"
                                link="https://objkt.com/asset/hicetnunc/406480"
                                imgLink='/images/raw/raw1.png'
                            />
                            <CollectionPiece 
                                title="The atom"
                                link="https://objkt.com/asset/hicetnunc/409841"
                                imgLink='/images/raw/raw2.png'
                            />
                            <CollectionPiece 
                                title="The flame"
                                link="https://objkt.com/asset/hicetnunc/442365"
                                imgLink='/images/raw/raw3.png'
                            />
                            <CollectionPiece 
                                title="The stream" 
                                link="https://objkt.com/asset/hicetnunc/422915"
                                imgLink='/images/raw/raw4.png'
                            />
                            <CollectionPiece 
                                title="[-]verse" 
                                link="https://objkt.com/asset/hicetnunc/485952"
                                imgLink='/images/raw/raw5.png'
                            />
                            <CollectionPiece 
                                title="Chains"
                                link="https://objkt.com/asset/hicetnunc/505865"
                                imgLink='/images/raw/raw6.png'
                            />
                            <CollectionPiece 
                                title="The odd" 
                                link="https://objkt.com/asset/hicetnunc/513807"
                                imgLink='/images/raw/raw7.png'
                            />
                            <CollectionPiece 
                                title="Weird Sunday" 
                                link="https://objkt.com/asset/hicetnunc/521372"
                                imgLink='/images/raw/raw8.png'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Raw;