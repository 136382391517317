import React from 'react'
import { useParams } from "react-router-dom";

import Web3 from "web3";
import Web3Modal from "web3modal";
import ENS, { getEnsAddress } from '@ensdomains/ensjs'

import CircularProgress from '@mui/material/CircularProgress';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import TextFormat from '@mui/icons-material/TextFormat';

import LeftNav from "../components/LeftNav"
import MarketInterface from "../components/MarketInterface"
import { BWButton, StyledToggleButton } from '../components/CustomFormComponents';

import { ABI_M, ADDRESS_M } from '../marketplaceConfig'
import { ABI_T, ADDRESS_T } from '../tokensConfig'
import MarketSummary from '../components/MarketSummary';

const providerOptions = {};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});

const getENS = async (address, provider) => {
    const ens = new ENS({ provider, ensAddress: getEnsAddress('1') })
    const name = await ens.getName(address)
    return name.name;
}

const getMarketData = async (web3, provider) => {
    try {
        let ensNames = {}
        const tokensContract = new web3.eth.Contract(ABI_T, ADDRESS_T)
        const marketplaceContract = new web3.eth.Contract(ABI_M, ADDRESS_M)
        const totalSupply = await tokensContract.methods.totalSupply().call()
        let data = []
        for (let i=0; i<totalSupply; i++) {
          data[i] = {bids: null, onSale: null}
        }
        await Promise.all(data.map(async (_, index) => {
          let owner = await tokensContract.methods.ownerOf(index).call()
          data[index].index = index
          data[index].owner = owner
          if (!(owner in ensNames)) {
            ensNames[owner] = await getENS(owner, provider)
          }
          data[index].ens = ensNames[owner]
          data[index].name = await tokensContract.methods.getTitle(index).call()
          data[index].bids = await marketplaceContract.methods.tokenBids(index).call()
          data[index].onSale = await marketplaceContract.methods.tokenListings(index).call()
        }));
        return data
      } catch (e) {
        console.log(e)
      }
}

export default function MarketPiece(props) {
    let [tokenDisplayStyle, setTokenDisplayStyle] = React.useState('text')
    let [contract, setContract] = React.useState(null)
    let [contractCorpus, setContractCorpus] = React.useState(null)
    let [ensName, setEnsName] = React.useState(null)
    let [isApprovedForAll, setIsApprovedForAll] = React.useState(false);
    let [account, setAccount] = React.useState(null)
    let [tokenData, setTokenData] = React.useState(null);
    let [currentTokenId, setCurrentTokenId] = React.useState(null);
    let [loading, setLoading] = React.useState(false);
    let [marketLoading, setMarketLoading] = React.useState(false);
    let [data, setData] = React.useState(null);
    let { tokenId } = useParams();
    
    React.useEffect(() => {
        async function getToken() {
            if (data && (!tokenData || (currentTokenId !== tokenId))) {
                setLoading(true)
                setTokenData(data[tokenId]);
                setCurrentTokenId(tokenId);
                setLoading(false)
            }
        }
        getToken();
    }, [tokenId, data, tokenData, currentTokenId])

    const connect = async () => {
        const provider = await web3Modal.connect();
        const userWeb3 = new Web3(provider);
        const chainId = await userWeb3.eth.getChainId();
        const accounts = await userWeb3.eth.getAccounts();
        const ensName = await getENS(accounts[0], provider)
        if (chainId !== 1) {
            // 4 rinkeby 1 mainnet
            alert("Please switch network to Ethereum mainnet.")
        } else {
            setLoading(true)
            const contract = new userWeb3.eth.Contract(ABI_M, ADDRESS_M)
            const contractCorpus = new userWeb3.eth.Contract(ABI_T, ADDRESS_T)
            const isApprovedForAll = await contractCorpus.methods.isApprovedForAll(accounts[0], ADDRESS_M).call()
            setData(await getMarketData(userWeb3, provider))
            setContract(contract)
            setContractCorpus(contractCorpus)
            setAccount(accounts[0])
            setEnsName(ensName)
            setIsApprovedForAll(isApprovedForAll)
            setLoading(false)
        }
    }

    const setApprovalForAll = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contractCorpus !== null) {
                const gasAmount = await contractCorpus.methods
                    .setApprovalForAll(ADDRESS_M, true)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contractCorpus.methods
                    .setApprovalForAll(ADDRESS_M, true)
                    .send({from: account, value: 0, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          setIsApprovedForAll(true)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const delistToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasAmount = await contract.methods
                    .tokenNoLongerForSale(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .tokenNoLongerForSale(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.onSale = {0: false}
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }
    
    const listToken = async (amount, address) => {
        const addressSellTo = address === '' ? '0x0000000000000000000000000000000000000000' : address 
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const weiValue = Web3.utils.toWei(amount, 'ether');
                const gasAmount = await contract.methods
                    .listTokenForSaleToAddress(tokenId, weiValue, addressSellTo)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .listTokenForSaleToAddress(tokenId, weiValue, addressSellTo)
                    .send({from: account, value: 0, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.onSale = {
                            isForSale: true, 
                            index: tokenId, 
                            seller: account, 
                            minValue: weiValue, 
                            onlySellTo: addressSellTo
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const bidOnToken = async (amount) => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const weiValue = Web3.utils.toWei(amount, 'ether');
                const gasAmount = await contract.methods
                    .enterBidForToken(tokenId)
                    .estimateGas({from: account, value: weiValue})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: weiValue})
                contract.methods
                    .enterBidForToken(tokenId)
                    .send({from: account, value: weiValue, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: true, 
                            index: tokenId, 
                            bidder: account, 
                            value: weiValue,
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const acceptBidForToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasAmount = await contract.methods
                    .acceptBidForToken(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .acceptBidForToken(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.owner = tData.bids.bidder
                          tData.bids = {
                            hasBid: false, 
                          }
                          tData.onSale = {
                            isForSale: false,
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const withdrawBidForToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasAmount = await contract.methods
                    .withdrawBidForToken(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .withdrawBidForToken(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: false, 
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const buyToken = async (amount) => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasAmount = await contract.methods
                    .buyToken(tokenId)
                    .estimateGas({from: account, value: amount})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: amount})
                contract.methods
                    .buyToken(tokenId)
                    .send({from: account, value: amount, gas: String(gasAmount)})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: false, 
                          }
                          tData.onSale = {
                            isForSale: false,
                          }
                          tData.owner = account
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    return(
        <div className="pageContainer">
            <LeftNav/>
            <div className="mainContent blackBg">
                {window.innerWidth > 1120 ? <>
                    {data === null 
                    ? loading 
                        ? <CircularProgress style={{'color': '#fafafa', margin: 50}}/> 
                        : <div style={{padding: 20}}>
                        {contract === null 
                                    ? <div style={{marginBottom: 30}}>
                                        <BWButton variant="outlined" onClick={() => connect()}>Connect</BWButton>
                                        <p>-</p>
                                    </div>
                                    : <div style={{marginBottom: 30}}>
                                        <BWButton onClick={() => setContract(null)}>Disconnect</BWButton>
                                        <p>{ensName ? ensName : account?.substring(0, 8) + '...'}</p>
                                    </div>
                                }
                        <p style={{fontSize: "20px", fontWeight: "bold"}}>connect to see encapsuled market</p>
                        
                        </div>
                    : loading ? <CircularProgress style={{'color': '#fafafa', margin: 50}}/> : <div className="coupleCollections">
                        <div className="collectionLeft">
                            <p style={{fontSize: "30px", fontWeight: "bold"}}>
                                {tokenData?.name}
                            </p>
                            {parseInt(tokenId, 10) > 14 && parseInt(tokenId, 10) < 45 
                                ? <><ToggleButtonGroup
                                    value={tokenDisplayStyle}
                                    exclusive
                                    style={{marginBottom: 20}}
                                    onChange={(e, displayStyle) => setTokenDisplayStyle(displayStyle)}
                                >
                                    <StyledToggleButton value="text" aria-label="list">
                                        <TextFormat/>
                                    </StyledToggleButton>
                                    <StyledToggleButton value="video" aria-label="module">
                                        <KeyboardVoiceIcon/>
                                    </StyledToggleButton>
                                </ToggleButtonGroup>
                                {tokenDisplayStyle === 'text' 
                                    ? <img
                                        src={"/images/" + tokenId + '.png'}
                                        alt={tokenData?.name} className="pieceImg"
                                    /> 
                                    : <video className="pieceImg" controls>
                                        <source src={"/videos/" + tokenId + '.mp4'} type="video/mp4"/>
                                    </video>
                                }
                                </>
                                : <img
                                    src={"/images/" + tokenId + '.png'}
                                    alt={tokenData?.name} className="pieceImg"
                                />
                            }
                        </div>
                        <div className="collectionRight">
                            <div style={{textAlign: 'right'}}>
                                {contract === null 
                                    ? <div style={{marginBottom: 30}}>
                                        <BWButton variant="outlined" onClick={() => connect()}>Connect</BWButton>
                                        <p>-</p>
                                    </div>
                                    : <div style={{marginBottom: 30}}>
                                        <BWButton onClick={() => setContract(null)}>Disconnect</BWButton>
                                        <p>{ensName ? ensName : account?.substring(0, 8) + '...'}</p>
                                    </div>
                                }
                            </div>
                            {<MarketInterface 
                                tokenData={data[tokenId]}
                                contract={contract}
                                account={account}
                                isApprovedForAll={isApprovedForAll}
                                delistToken={delistToken}
                                listToken={listToken}
                                bidOnToken={bidOnToken}
                                acceptBidForToken={acceptBidForToken}
                                buyToken={buyToken}
                                setApprovalForAll={setApprovalForAll}
                                withdrawBidForToken={withdrawBidForToken}
                                loading={marketLoading}
                            />}
                        </div>
                    </div>}
                    {data && <MarketSummary data={data}/>}
                    </>
                :
                    <div className="collectionLeft">
                        <div style={{textAlign: 'right'}}>
                            {contract === null 
                                ? <div style={{marginBottom: 30}}>
                                    <BWButton variant="outlined" onClick={() => connect()}>Connect</BWButton>
                                    <p>-</p>
                                </div>
                                : <div style={{marginBottom: 30}}>
                                    <BWButton onClick={() => setContract(null)}>Disconnect</BWButton>
                                    <p>{ensName ? ensName : account?.substring(0, 8) + '...'}</p>
                                </div>
                            }
                        </div>
                        <p style={{fontSize: "30px", fontWeight: "bold"}}>
                                {tokenData?.name}
                        </p>
                        {parseInt(tokenId, 10) > 14 && parseInt(tokenId, 10) < 45 
                            ? <><ToggleButtonGroup
                                value={tokenDisplayStyle}
                                exclusive
                                style={{marginBottom: 20}}
                                onChange={(e, displayStyle) => setTokenDisplayStyle(displayStyle)}
                            >
                                <StyledToggleButton value="text" aria-label="list">
                                    <TextFormat/>
                                </StyledToggleButton>
                                <StyledToggleButton value="video" aria-label="module">
                                    <KeyboardVoiceIcon/>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                            {tokenDisplayStyle === 'text' 
                                ? <img
                                    src={"/images/" + tokenId + '.png'}
                                    alt={tokenData?.name} className="pieceImg"
                                /> 
                                : <video className="pieceImg" controls>
                                    <source src={"/videos/" + tokenId + '.mp4'} type="video/mp4"/>
                                </video>
                            }
                            </>
                            : <img
                                src={"/images/" + tokenId + '.png'}
                                alt={tokenData?.name} className="pieceImg"
                            />
                        }
                        <MarketInterface 
                            tokenData={props.data[tokenId]}
                            contract={contract}
                            account={account}
                            isApprovedForAll={isApprovedForAll}
                            delistToken={delistToken}
                            listToken={listToken}
                            bidOnToken={bidOnToken}
                            acceptBidForToken={acceptBidForToken}
                            buyToken={buyToken}
                            setApprovalForAll={setApprovalForAll}
                            withdrawBidForToken={withdrawBidForToken}
                            loading={marketLoading}
                        />
                        <MarketSummary data={props.data}/>
                    </div>
                }
            </div>
        </div>
    )
    
}