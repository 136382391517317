import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';

export default class TwoElementsRow extends Component {
    render() {
        return(
            <Grid item style={{width: "100%"}} padding={0}>
                <div style={{display: "flex", width: "100%"}} className="wrapOnMobile">
                    <div style={{float: "left"}} className="insideTextBottom">
                        {this.props.isLeftLink 
                            ? this.props.leftLink.startsWith("/") || this.props.leftLink.startsWith("#") 
                                ? <Link to={this.props.leftLink} reloadDocument>
                                    <span>{this.props.leftText}</span>
                                </Link>
                                : <a href={this.props.leftLink} target="_blank" rel="noopener noreferrer">
                                    <span>{this.props.leftText}</span>
                                </a>
                            : <span>{this.props.leftText}</span>
                        }
                    </div>
                    {this.props.hideHr 
                        ? <div style={{width: "100%"}}></div> 
                        : <hr style={{width: "100%", color: "#fafafa", border: "none", borderBottom: "1px dotted", margin: "1px"}}/>}
                    <div style={{float: "right"}} className="insideTextBottom">
                        {this.props.isRightLink 
                            ? this.props.rightLink.startsWith("/") 
                                ? <Link to={this.props.rightLink}>
                                    <span>{this.props.rightText}</span>
                                </Link>
                                : <a href={this.props.rightLink} target="_blank" rel="noopener noreferrer">
                                    <span>{this.props.rightText}</span>
                                </a>
                            : <span>{this.props.rightText}</span>
                        }
                    </div>
                </div>
            </Grid>
        )
    }
}
