import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class E28 extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title=""
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/16"
                                    imgLink='/images/e28/16.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/0"
                                    imgLink='/images/e28/0.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/5"
                                    imgLink='/images/e28/5.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/10"
                                    imgLink='/images/e28/10.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/12"
                                    imgLink='/images/e28/12.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/1"
                                    imgLink='/images/e28/1.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/3"
                                    imgLink='/images/e28/3.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/13"
                                    imgLink='/images/e28/13.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/2"
                                    imgLink='/images/e28/2.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/7"
                                    imgLink='/images/e28/7.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/11"
                                    imgLink='/images/e28/11.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/4"
                                    imgLink='/images/e28/4.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/8"
                                    imgLink='/images/e28/8.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/14"
                                    imgLink='/images/e28/14.png'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Encapsuled28
                                </p>
                                <a href="https://opensea.io/collection/encapsuled28" target="_blank" rel="noopener noreferrer">
                                    Available on OpenSea
                                </a>
                                <p>
                                    Encapsuled28 is a collection of poems crafted with extreme care and precision. 
                                    Their words and flow are studied to synergize and compliment each other. For best experience, read them out loud.
                                </p>
                                <p>
                                    All of these poems are stored on-chain with a custom ERC-721 contract. As long as ethereum lives, these poems will be there. You'll always be able to call the getPoem function to retrieve the text.
                                </p>
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/23"
                                    imgLink='/images/e28/23.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/6"
                                    imgLink='/images/e28/6.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/9"
                                    imgLink='/images/e28/9.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/15"
                                    imgLink='/images/e28/15.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/21"
                                    imgLink='/images/e28/21.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/20"
                                    imgLink='/images/e28/20.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/18"
                                    imgLink='/images/e28/18.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/19"
                                    imgLink='/images/e28/19.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/17"
                                    imgLink='/images/e28/17.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/22"
                                    imgLink='/images/e28/22.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/24"
                                    imgLink='/images/e28/24.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/25"
                                    imgLink='/images/e28/25.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/27"
                                    imgLink='/images/e28/27.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/26"
                                    imgLink='/images/e28/26.png'
                                />
                            </div>
                        </div>
                        :
                            <div className="collectionLeft">
                                <p className="collectionTitle">
                                    Encapsuled28
                                </p>
                                <a href="https://opensea.io/collection/encapsuled28" target="_blank" rel="noopener noreferrer">
                                    Available on OpenSea
                                </a>
                                <p>
                                    Encapsuled28 is a collection of poems crafted with extreme care and precision. 
                                    Their words and flow are studied to synergize and compliment each other. For best experience, read them out loud.
                                </p>
                                <p>
                                    All of these poems are stored on-chain with a custom ERC-721 contract. As long as ethereum lives, these poems will be there. You'll always be able to call the getPoem function to retrieve the text.
                                </p>
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/23"
                                    imgLink='/images/e28/23.png'
                                />
                                <CollectionPiece 
                                    title=""
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/16"
                                    imgLink='/images/e28/16.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/0"
                                    imgLink='/images/e28/0.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/5"
                                    imgLink='/images/e28/5.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/10"
                                    imgLink='/images/e28/10.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/12"
                                    imgLink='/images/e28/12.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/1"
                                    imgLink='/images/e28/1.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/3"
                                    imgLink='/images/e28/3.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/13"
                                    imgLink='/images/e28/13.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/2"
                                    imgLink='/images/e28/2.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/7"
                                    imgLink='/images/e28/7.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/11"
                                    imgLink='/images/e28/11.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/4"
                                    imgLink='/images/e28/4.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/8"
                                    imgLink='/images/e28/8.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/14"
                                    imgLink='/images/e28/14.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/6"
                                    imgLink='/images/e28/6.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/9"
                                    imgLink='/images/e28/9.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/15"
                                    imgLink='/images/e28/15.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/21"
                                    imgLink='/images/e28/21.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/20"
                                    imgLink='/images/e28/20.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/18"
                                    imgLink='/images/e28/18.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/19"
                                    imgLink='/images/e28/19.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/17"
                                    imgLink='/images/e28/17.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/22"
                                    imgLink='/images/e28/22.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/24"
                                    imgLink='/images/e28/24.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/25"
                                    imgLink='/images/e28/25.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/27"
                                    imgLink='/images/e28/27.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://opensea.io/assets/0xb3a3ab875601c3e9772bb2cb1e6d870d355f010a/26"
                                    imgLink='/images/e28/26.png'
                                />
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default E28;