import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchSingleStrokePoems';

export default class SomeFormOfPoetry extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Some form of poetry
                            </p>
                            <p>
                                Presented within <a href="http://www.bauprogetto.net/" target="_blank" rel="noopener noreferrer">BAU 20</a>, 
                                along with a short essay in its catalogue on the theme "what is poetry?".<br/>
                                The series is composed of 120 unique iterations curated from an algorithm written in javascript 
                                that outputs SVG files, plotted on A4 canvas textured paper with technical pens, numbered, and signed.<br/>
                            </p>
                            <p>
                                The algorithm is based on my continuous explorations of unreadable language.
                                Its outputs can take one of eight structures, and are determined by a number of 
                                different parameters that visually shape the pieces.
                            </p>
                            <p>
                                Below, with every page refresh, a new variation is generated.
                            </p>
                            <br/>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}