import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Loss extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Loss
                            </p>
                            <br/><br/>
                            <p>
                                The original objective of language is to coordinate action with the least possible ambiguity. In words with multiple meanings such as "loss", this objective is put at risk. This series is meant to visually explore these variations, and pose the question of whether a non-readable language could be more effective in communicating than our natural language by making a direct connection with the subconscious instead of using rationality to interpret a known set of symbols.<br/>
                            </p>
                            <br/><br/>
                            <p className='aBitBiggerText'>
                                1: destruction, ruin
                            </p>
                            <img alt='' src='/images/loss/1.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                2a1: the act or fact of being unable to keep or maintain something or someone
                            </p>
                            <img alt='' src='/images/loss/2.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                2a2: the partial or complete deterioration or absence of a physical capability or function
                            </p>
                            <img alt='' src='/images/loss/3.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                2b: the harm or privation resulting from losing or being separated from someone or something
                            </p>
                            <img alt='' src='/images/loss/4.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                2c: an instance of losing someone or something
                            </p>
                            <img alt='' src='/images/loss/5.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                3: a person or thing or an amount that is lost
                            </p>
                            <img alt='' src='/images/loss/6.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                4: failure to gain, win, obtain, or utilize
                            </p>
                            <img alt='' src='/images/loss/7.png' className="pieceImgWide" />
                            <p className='aBitBiggerText'>
                                5: decrease in amount, magnitude, value, or degree
                            </p>
                            <img alt='' src='/images/loss/8.png' className="pieceImgWide" />
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
        )
    }
}