
export default function sketch(p) {

  class R {
    constructor(seed) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(seed.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(seed.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }
  }
  
  function pointInCircle(x, y, centerX, centerY, r) {
    return p.abs((x-centerX)*(x-centerX)) + p.abs((y-centerY)*(y-centerY)) <= r*r/4
  }
  
  function gsft(_) {
    console.log("timeSeed " + window.timeSeed + window.tokenHash)
    return window.timeSeed + window.tokenHash
  }
  
  let palettes = [
    {h: 194, s: 51},
    {h: 4, s: 100},
    {h: 316, s: 85},
    {h: 235, s: 91},
    {h: 169, s: 89},
    {h: 113, s: 95},
    {h: 40, s: 100},
    {h: 20, s: 100},
    {h: 75, s: 100},
    {h: 266, s: 70},
  ]
  
  let r
  let rt = new R(window.tokenHash)
  
  let lineThreshold = rt.rC([0.8, 0.9])
  let colorThreshold = rt.rC([0.7, 0.8, 0.9, 0.95, 0.99])
  let spaceThreshold = rt.rC([0.7, 0.8, 0.9])
  let angleBoundary = 1
  
  let margin = rt.rC([0.05, 0.1, 0.2])
  let stepSize = rt.rC([0.01, 0.02])
  let lineHeight = stepSize*2.5
  
  let bigCharThreshold = rt.rC([0.9, 0.95, 0.99])
  let extraLinesAmount = rt.rC([0, 1, 2, 5, 10])
  let singleOriginLines = rt.rB(0, 1) > 0.5
  let dir = rt.rC(['h', 'v'])
  let focusFill = rt.rB(0, 1) > 0.5
  let mainPointDistance = rt.rC([0.02, 0.05, 0.1])
  let invertedColors = rt.rB(0, 1) > 0.5
  let br = rt.rI(40, 100)
  let chaotic = rt.rB(0, 1) > 0.5
  let mainPointsMappedSize = rt.rC([0.02, 0.05, 0.1])
  let mainPointsFill = rt.rB(0, 1) > 0.5
  let mainPointsColor = rt.rB(0, 1) > 0.5
  let useMainPointsMapped = rt.rB(0, 1) > 0.5
  let mainPointsMappedAlpha = rt.rC([0.2, 0.3, 0.5])
  let mainPointsWidth = rt.rC([0.01, 0.015, 0.02, 0.03])
  let curvedVertex = rt.rB(0, 1) > 0.5
  let palette = rt.rC(palettes)
  
  let rectStuff
  let mainPoints
  let finalPoints
  let extraLines
  let w
  let h 

  p.setup = () => {
    if (p.windowHeight <= p.windowWidth/p.sqrt(2)) {
      w=p.windowHeight*p.sqrt(2)*0.75
      h=p.windowHeight*0.75
    } else {
      w=p.windowWidth*0.75
      h=p.windowWidth/p.sqrt(2)*0.75
    }
    p.colorMode(p.HSB)
    p.createCanvas(w, h)
    p.pixelDensity(5)
  }
  
  p.draw = () => {
    writeChronicle()
    p.noLoop()
  }
  
  function writeChronicle() {
    rt = new R(window.tokenHash)
    
    lineThreshold = rt.rC([0.8, 0.9])
    colorThreshold = rt.rC([0.7, 0.8, 0.9, 0.95, 0.99])
    spaceThreshold = rt.rC([0.7, 0.8, 0.9])
    
    margin = rt.rC([0.05, 0.1, 0.2])
    stepSize = rt.rC([0.01, 0.02])
    lineHeight = stepSize*2.5
    
    bigCharThreshold = rt.rC([0.9, 0.95, 0.99])
    extraLinesAmount = rt.rC([0, 1, 2, 5, 10])
    singleOriginLines = rt.rB(0, 1) > 0.5
    dir = rt.rC(['h', 'v'])
    focusFill = rt.rB(0, 1) > 0.5
    mainPointDistance = rt.rC([0.02, 0.05, 0.1])
    invertedColors = rt.rB(0, 1) > 0.5
    br = rt.rI(40, 100)
    chaotic = rt.rB(0, 1) > 0.5
    mainPointsMappedSize = rt.rC([0.02, 0.05, 0.1])
    mainPointsFill = rt.rB(0, 1) > 0.5
    mainPointsColor = rt.rB(0, 1) > 0.5
    useMainPointsMapped = rt.rB(0, 1) > 0.5
    mainPointsMappedAlpha = rt.rC([0.2, 0.3, 0.5])
    mainPointsWidth = rt.rC([0.01, 0.015, 0.02, 0.03])
    curvedVertex = rt.rB(0, 1) > 0.5
    palette = rt.rC(palettes)
    let d = new Date()
    r = new R(gsft(d))
    rectStuff = getRectStuff(r)
    mainPoints = getMainPoints(r)
    finalPoints = getPointsToDraw(r)
    extraLines = getExtraLines(r)
    p.background(invertedColors ? "#fafafa" : "#0f0f0f")
    p.noStroke()
    p.noFill()
  
    // rect
    let c
    if (focusFill) {
      c = p.color(palette.h, palette.s, invertedColors ? 90 : 10)
      p.fill(c)
    } else {
      c = p.color(0, 0, invertedColors ? 10 : 90)
      p.stroke(c)
      p.strokeWeight(0.002*h)
    }
    p.rect(rectStuff.startRectX*w, rectStuff.startRectY*h, rectStuff.rectW*w, rectStuff.rectH*h)
    
    // mainpoints
    if (mainPointsColor) {
      c = p.color(palette.h, palette.s, br)
    } else {
      c = p.color(0, 0, invertedColors ? 10 : 90)
    }
    if (useMainPointsMapped) {
      c.setAlpha(mainPointsMappedAlpha)
    }
    if (mainPointsFill && !useMainPointsMapped) {
      p.fill(c)
      p.noStroke()
    } else {
      p.noFill()
      p.stroke(c)
      p.strokeWeight(0.002*h)
    }
    for (const pp of mainPoints) {
      p.circle(pp.x*w, pp.y*h, pp.w*h)
    }
    
    // character points
    p.noFill()
    
    for (let j=0; j<finalPoints.length; j++) {
      p.stroke(finalPoints[j].ps[0].cl)
      p.strokeWeight(finalPoints[j].ps[0].w*w)
      p.beginShape()
      for (let i=0; i<finalPoints[j].ps.length; i++) {
        if (curvedVertex) {
          p.curveVertex(
            finalPoints[j].ps[i].x*w, finalPoints[j].ps[i].y*h,
          )
        } else {
          p.vertex(
            finalPoints[j].ps[i].x*w, finalPoints[j].ps[i].y*h,
          )
        }
      }
      p.endShape()
      // lines between following characters
      if (finalPoints[j].lineProb > lineThreshold && j<finalPoints.length-1) {
        p.stroke(finalPoints[j].ps[finalPoints[j].ps.length-1].cl)
        p.strokeWeight(finalPoints[j].ps[finalPoints[j].ps.length-1].w*w)
        
        p.line(
          finalPoints[j].ps[finalPoints[j].ps.length-1].x*w, finalPoints[j].ps[finalPoints[j].ps.length-1].y*h,
          finalPoints[j+1].ps[0].x*w, finalPoints[j+1].ps[0].y*h
        )
      }
    }
    // random lines between characters
    for (const l of extraLines) {
      p.stroke(l.cl)
      p.strokeWeight(l.sw*w)
      p.line(l.x1*w, l.y1*h, l.x2*w, l.y2*h)
    }
  }
  
  function getExtraLines(r) {
    let lines = []
    let p1
    if (singleOriginLines) {
      p1 = r.rC(finalPoints)
    }
    for (let i=0; i<extraLinesAmount; i++) {
      if (!singleOriginLines) {  
        p1 = r.rC(finalPoints)
      }
      let p2 = r.rC(finalPoints)
      lines.push({
        x1: p1.ps[p1.ps.length-1].x,
        y1: p1.ps[p1.ps.length-1].y,
        x2: p2.ps[0].x,
        y2: p2.ps[0].y,
        cl: p1.ps[0].cl,
        sw: p1.ps[0].w,
      })
    }
    return lines
  }
  
  function getMainPoints(r) {
    let mainPoints = []
    let mainPointsMapped = []
    let px = r.rB(0.4, 0.6)
    let py = r.rB(0.4, 0.6)
    let pa = r.rB(0, p.TWO_PI)
    let na = r.rB(-1, 1)
    let minX = 1
    let maxX = 0
    let minY = 1
    let maxY = 0
    for (let i=0; i<1000; i++) {
      mainPoints.push({
        x: px,
        y: py,
        w: mainPointsWidth,
      })
      if (i%100 === 0) {
        na = r.rB(-1, 1)
      }
      pa += na
      px += p.cos(pa)*0.004/p.sqrt(2)
      py += p.sin(pa)*0.004
      if (px<margin || px>1-margin || py<margin || py>1-margin) {
        break
      }
      if (px < minX) {
        minX = px
      }
      if (px > maxX) {
        maxX = px
      }
      if (py < minY) {
        minY = py
      }
      if (py > maxY) {
        maxY = py
      }
    }
    if (useMainPointsMapped) {
      for (const pp of mainPoints) {
        mainPointsMapped.push({
          x: p.map(pp.x, minX, maxX, margin, 1-margin),
          y: p.map(pp.y, minY, maxY, margin, 1-margin),
          w: mainPointsMappedSize
        })
      }
      return mainPointsMapped
    } else {
      return mainPoints
    }
  }
    
  
  function getRectStuff(r) {
    let p1x = r.rB(0.9*margin, 1-1.1*margin)
    let p1y = r.rB(0.9*margin, 1-1.1*margin)
    let p2x = r.rB(0.9*margin, 1-1.1*margin)
    let p2y = r.rB(0.9*margin, 1-1.1*margin)
    return {
      startRectX: Math.min(p1x, p2x),
      startRectY: Math.min(p1y, p2y),
      rectW: Math.abs(p2x - p1x),
      rectH: Math.abs(p2y - p1y),
    }
  }
  
  function getPointsToDraw(r) {
    let initialPoints = []
    if (dir === 'h') {
      for (let j=margin; j<=1-margin+0.001; j+=lineHeight) {
        for (let i=margin; i<=1-margin+0.001; i+=stepSize) {
          getPoint(i, j, angleBoundary, initialPoints, r)
        }
      }
    } else {
      for (let i=margin; i<=1-margin+0.001; i+=stepSize) {
        for (let j=margin; j<=1-margin+0.001; j+=lineHeight) {
          getPoint(i, j, angleBoundary, initialPoints, r)
        }
      }
    }
    let points = []
    let cl
    let na
    for (const pp of initialPoints) {
      if (r.rB(0, 1) > colorThreshold) {
        cl = p.color(palette.h, palette.s, pp.br)
      } else {
        cl = p.color(0, 0, invertedColors ? 10 : 90)
      }
      let charPoints = []
      let x = pp.x
      let y = pp.y
      let a = pp.a
      let ab = pp.ab
      for (let i=0; i<pp.s; i++) {
        if (i%pp.c === 0) {
          na = r.rB(-ab, ab)
        }
        a += na
        if (r.rB(0, 1) > 0.5) {
          charPoints.push({
            x: x,
            y: y,
            w: p.w,
            cl: cl
          })
        }
        x += p.cos(a)*pp.step*pp.w/p.sqrt(2)
        y += p.sin(a)*pp.step*pp.w
        if (x > pp.x+0.02 || x < pp.x-0.02 || y > pp.y+0.03 || y < pp.y-0.03) {
          break
        }
      }
      points.push({ps: charPoints, lineProb: r.rB(0, 1)})
    }
    return points
  }
  
  
  function getPoint(i, j, angleBoundary, initialPoints, r) {
    if (r.rB(0, 1) > spaceThreshold) {
      return
    }
   let found = false
    for (const pp of mainPoints) {
      if (pointInCircle(pp.x, pp.y, i, j, mainPointDistance)) {
        found = true
        break
      }
    }
    if (found) {
      return
    }
    if (chaotic) {
      i += r.rB(-stepSize/4, stepSize/4)
      j += r.rB(-lineHeight/4, lineHeight/4)
    }
    let neww
    if (r.rB(0, 1) < bigCharThreshold) {
      neww = 0.001
    } else {
      neww = 0.003
    }
    initialPoints.push({
      x: i,
      y: j,
      a: r.rB(0, p.TWO_PI),
      ab: angleBoundary,
      w: neww,
      s: 300,
      c: 50,
      title:false,
      br: br,
      step: 0.25
    })
  }
  
  p.mouseClicked = () => {
    writeChronicle()
  }
  
  p.keyReleased = () => {
    writeChronicle()
  }
}

