import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class OnWriting extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="" 
                                    link="https://www.fxhash.xyz/gentk/883330"
                                    imgLink='/images/engoesback/5.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://www.fxhash.xyz/gentk/891167"
                                    imgLink='/images/engoesback/9.png'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    engoesback
                                </p>
                                <p>
                                    In May 2022 I went back to Italy, my birth country, for little over a month.<br/>
                                    During this time, I wrote a poem a day, and posted them on Twitter with the hashtag <a href="https://twitter.com/hashtag/engoesback?src=hashtag_click&f=live" target="_blank" rel="noopener noreferrer">#engoesback</a>.<br/>
                                    The poems are in mixed language English-Italian.<br/><br/>
                                    I edited the poems, removed some lines, changed others, and put the remaining lines in a p5js script.<br/>
                                    The script chooses a number between 1 and 8 randomly, and then picks from the existing lines to form a poem with that length.<br/>
                                    Not all iterations will make sense at a glance, but they are all an exploration of my mind and can trigger new thoughts.
                                </p>
                                <p>
                                    Browse the minted iterations on <a href="https://www.fxhash.xyz/generative/14813" target="_blank" rel="noopener noreferrer">fxhash</a>.
                                </p>
                                <CollectionPiece 
                                    title="" 
                                    link="https://www.fxhash.xyz/gentk/887508"
                                    imgLink='/images/engoesback/8.png'
                                />
                                <CollectionPiece 
                                    title="" 
                                    link="https://www.fxhash.xyz/gentk/896342"
                                    imgLink='/images/engoesback/13.png'
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                engoesback
                            </p>
                            <p>
                                In May 2022 I went back to Italy, my birth country, for little over a month.<br/>
                                During this time, I wrote a poem a day, and posted them on Twitter with the hashtag #engoesback.<br/>
                                The poems are in mixed language English-Italian.<br/><br/>
                                I edited the poems, removed some lines, changed others, and put the remaining lines in a p5js script.<br/>
                                The script chooses a number between 1 and 8 randomly, and then picks from the existing lines to form a poem with that length.<br/>
                                Not all iterations will make sense at a glance, but they are all an exploration of my mind and can trigger new thoughts.
                            </p>
                            <p>
                                Browse the minted iterations on <a href="https://www.fxhash.xyz/generative/14813" target="_blank" rel="noopener noreferrer">fxhash</a>.
                            </p>
                            <CollectionPiece 
                                title="" 
                                link="https://www.fxhash.xyz/gentk/883330"
                                imgLink='/images/engoesback/5.png'
                            />
                            <CollectionPiece 
                                title="" 
                                link="https://www.fxhash.xyz/gentk/887508"
                                imgLink='/images/engoesback/8.png'
                            />
                            <CollectionPiece 
                                title="" 
                                link="https://www.fxhash.xyz/gentk/891167"
                                imgLink='/images/engoesback/9.png'
                            />
                            <CollectionPiece 
                                title="" 
                                link="https://www.fxhash.xyz/gentk/896342"
                                imgLink='/images/engoesback/13.png'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OnWriting;