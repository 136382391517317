
export default function sketch(p) {

  function makeid(length) {
    var result           = '';
    var characters       = 'abcdefghi0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  let tokenData = {hash: makeid(64), tokenID: "0"}
  //tokenData.hash = "b5di0ca453109e5fc94ig459a9b8e79ihc91d4ac807189bf5d8i03eeee76fhbg"
  console.log(tokenData.hash)
  class R {
    constructor(seed) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(seed.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(seed.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }
  }
  
  const rng = new R(tokenData.hash)
  let initialPoints = []
  let ps = []
  
  let angleBoundary = rng.rC([0.1, 0.2, 0.5, 1, 2, 3])
  let charDist = rng.rC([0.01, 0.02, 0.04])
  let maxX = charDist/2
  let strokeW = 0.01
  let inverted = true
  let cont = rng.rC([50, 100])
  let angCont = rng.rC([20, 50, 100, 200])
  let structures = [
    'wall',  // no spaces, no line breaks
    'Shakespearen sonnet', // three quatrains and an ending couplet
    'Petrarchan sonnet', // one octave and one sestet
    'free',
    'diminishing', // every line shorter than previous
    'ballad', // rhymed quatrains
    'limerick', // five line poems of a single stanza
    'mantra', // every line the same
  ]
  let structure = rng.rC(structures)
  let lineHeight = ['wall', 'free', 'crescendo', 'diminishing', 'mantra'].includes(structure) 
    ? rng.rC([0.01, 0.02, 0.04, 0.05])
    : rng.rC([0.04, 0.05])
  let maxY = lineHeight/2
  let margin = rng.rC([0.1, 0.2, 0.3])
  let lineMaxLength = 1-margin
  let repeatNum = rng.rC([3, 6, 8, 10, 20, 30, 40, 50])
  let w, h
  
  p.setup = () => {
    if (p.windowWidth <= p.windowHeight/p.sqrt(2)) {
      w=p.windowWidth
      h=p.windowWidth*p.sqrt(2)
    } else {
      w=p.windowHeight/p.sqrt(2)
      h=p.windowHeight
    }
    p.createCanvas(w, h)
    p.noStroke()
    p.pixelDensity(5)
    getInitialPoints()
    for (const pp of initialPoints) {
      let tempPs = []
      let a = rng.rB(0, p.TWO_PI)
      let aInc = rng.rB(-angleBoundary, angleBoundary)
      let x = pp.x
      let y = pp.y
      
      for (let i = 0; i<200; i++) {
        if (i%angCont === 0) {
          a = rng.rB(0, p.TWO_PI)
        }
        if (i%cont === 0) {
          aInc = rng.rB(-angleBoundary, angleBoundary)
        }
        a += aInc
        x += p.cos(a)*(0.001)
        y += p.sin(a)*(0.001)/p.sqrt(2)
        tempPs.push({
          x: x,
          y: y,
          c: inverted ? "#0f0f0f" : "#fafafa"
        })
        if (Math.abs(y-pp.y) > maxY || Math.abs(x-pp.x) > maxX) {
          break
        }
      }
      ps = [...ps, ...tempPs]
    }
  }
  
  p.draw = () => {
    p.background(inverted ? "#fafafa" : "#0f0f0f")
    p.stroke(inverted ? "#0f0f0f" : "#fafafa")
    p.strokeWeight(strokeW*w/8)
    for (let i=0; i<ps.length-1; i++) {
      p.line((ps[i].x)*w, (ps[i].y)*h, (ps[i+1].x)*w, (ps[i+1].y)*h)
    }
    if (structure === 'mantra') {
      let count = 0
      for (let j=lineHeight; j<=repeatNum*lineHeight && j<.55; j+=lineHeight) {
        for (let i=0; i<ps.length-1; i++) {
          p.line((ps[i].x)*w, (ps[i].y + j)*h, (ps[i+1].x)*w, (ps[i+1].y + j)*h)
        }
        p.line((ps[ps.length-1].x)*w, (ps[ps.length-1].y + lineHeight*count)*h, (ps[0].x)*w, (ps[0].y + j)*h)
        count++
      }
      count = 1
      for (let j=-lineHeight; j>=-repeatNum*lineHeight && j>-.55; j-=lineHeight) {
        for (let i=0; i<ps.length-1; i++) {
          p.line((ps[i].x)*w, (ps[i].y + j)*h, (ps[i+1].x)*w, (ps[i+1].y + j)*h)
        }
        p.line((ps[ps.length-1].x)*w, (ps[ps.length-1].y - lineHeight*count)*h, (ps[0].x)*w, (ps[0].y + j+lineHeight)*h)
        count++
      }
    }
    p.noLoop()
  }
  
  
  function getInitialPoints() {
    let finalHeight = 0
    let finalWidth = 0
    let tempPoints = []
    if (structure === 'wall') {
      for (let i=0; i<=1-2*margin; i+=lineHeight) {
        for (let j=margin; j<=lineMaxLength; j+=charDist) {
          tempPoints.push({
            x: j,
            y: i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
        if (i > finalHeight) {
          finalHeight = i
        }
      }
    } else if (structure === 'Shakespearen sonnet') {
      for (let i=0; i<17; i++) {
        if (i === 4 || i === 9 || i === 14) {
          continue
        }
        if (lineHeight*i > finalHeight) {
          finalHeight = lineHeight*i
        }
        for (let j=margin; j<=lineMaxLength; j+=charDist) {
          tempPoints.push({
            x: j,
            y: lineHeight*i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
      }
    } else if (structure === 'Petrarchan sonnet') {
      for (let i=0; i<15; i++) {
        if (i === 8) {
          continue
        }
        if (lineHeight*i > finalHeight) {
          finalHeight = lineHeight*i
        }
        for (let j=margin; j<=lineMaxLength; j+=charDist) {
          tempPoints.push({
            x: j,
            y: lineHeight*i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
      }
    } else if (structure === 'free') {
      for (let i=0; i<=1-2*margin; i+=lineHeight) {
        if (rng.rB(0, 1) > 0.8) {
          continue
        }
        if (i > finalHeight) {
          finalHeight = i
        }
        for (let j=margin; j<=1-margin; j+=charDist) {
          if (rng.rB(0, 1) > 0.7) {
            continue
          }
          tempPoints.push({
            x: j,
            y: i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
          if (rng.rB(0, 1) > 0.8) {
            break
          }
        }
      }
    } else if (structure === 'crescendo') {
      let endLine = margin + charDist + 0.001
      for (let i=0; i<=1-2*margin && (endLine < 1-margin+0.01); i+=lineHeight) {
        for (let j=margin; j<=Math.min(endLine, 1-margin); j+=charDist) {
          tempPoints.push({
            x: j,
            y: i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
        if (i > finalHeight) {
          finalHeight = i
        }
        endLine += charDist
      }
    } else if (structure === 'diminishing') {
      let endLine = 1-margin
      for (let i=0; i<=1-2*margin && (endLine > margin+0.01); i+=lineHeight) {
        for (let j=margin; j<=endLine; j+=charDist) {
          tempPoints.push({
            x: j,
            y: i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
        if (i > finalHeight) {
          finalHeight = i
        }
        endLine -= charDist
      }
    } else if (structure === 'haiku') {
      for (let j=0; j<10; j++) {
        tempPoints.push({
          x: margin + charDist*j,
          y: 0,
        })
      }
      for (let j=0; j<14; j++) {
        tempPoints.push({
          x: margin + charDist*j,
          y: lineHeight,
        })
      }
      for (let j=0; j<10; j++) {
        tempPoints.push({
          x: margin + charDist*j,
          y: 2*lineHeight,
        })
      }
      finalHeight = 2*lineHeight
      finalWidth = margin + 13*charDist
    } else if (structure === 'ballad') {
      let balladLength = rng.rI(2, 4)
      for (let i=0; i<balladLength*5; i++) {
        if (i === 4 || i === 9 || i === 14 || i === 19) {
          continue
        }
        if (lineHeight*i > finalHeight) {
          finalHeight = lineHeight*i
        }
        for (let j=margin; j<=lineMaxLength; j+=charDist) {
          tempPoints.push({
            x: j,
            y: lineHeight*i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
      }
    } else if (structure === 'limerick') {
      for (let i=0; i<=0.41; i+=0.1) {
        for (let j=margin; j<=lineMaxLength; j+=charDist) {
          tempPoints.push({
            x: j,
            y: i,
          })
          if (j > finalWidth) {
            finalWidth = j
          }
        }
      }
      finalHeight = 0.4
    } else if (structure === 'mantra') {
      for (let j=margin; j<=lineMaxLength; j+=charDist) {
        tempPoints.push({
          x: j,
          y: 0.1,
        })
        if (j > finalWidth) {
          finalWidth = j
        }
      }
      finalHeight = 0.1
    }
    for (const pp of tempPoints) {
      initialPoints.push({
        x: p.map(pp.x, margin, finalWidth, (1-finalWidth)/2, 1-(1-finalWidth)/2),
        y: structure === 'mantra' ? 0.5 : p.map(pp.y, 0, finalHeight, (1-finalHeight)/2, 1-(1-finalHeight)/2)
      })
    }
  }

}

