import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Corpus extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="The actor"
                                    link="/corpus/15"
                                    imgLink='/images/15.png'
                                />
                                <CollectionPiece 
                                    title="The bard"
                                    link="/corpus/17"
                                    imgLink='/images/17.png'
                                />
                                <CollectionPiece 
                                    title="The bricklayer"
                                    link="/corpus/19"
                                    imgLink='/images/19.png'
                                />
                                <CollectionPiece 
                                    title="The cartographer"
                                    link="/corpus/21"
                                    imgLink='/images/21.png'
                                />
                                <CollectionPiece 
                                    title="The drifter"
                                    link="/corpus/23"
                                    imgLink='/images/23.png'
                                />
                                <CollectionPiece 
                                    title="The engineer"
                                    link="/corpus/25"
                                    imgLink='/images/25.png'
                                />
                                <CollectionPiece 
                                    title="The hunter"
                                    link="/corpus/27"
                                    imgLink='/images/27.png'
                                />
                                <CollectionPiece 
                                    title="The journalist"
                                    link="/corpus/29"
                                    imgLink='/images/29.png'
                                />
                                <CollectionPiece 
                                    title="The librarian"
                                    link="/corpus/31"
                                    imgLink='/images/31.png'
                                />
                                <CollectionPiece 
                                    title="The painter"
                                    link="/corpus/33"
                                    imgLink='/images/33.png'
                                />
                                <CollectionPiece 
                                    title="The photographer"
                                    link="/corpus/35"
                                    imgLink='/images/35.png'
                                />
                                <CollectionPiece 
                                    title="The rewilder"
                                    link="/corpus/37"
                                    imgLink='/images/37.png'
                                />
                                <CollectionPiece 
                                    title="The salesman"
                                    link="/corpus/39"
                                    imgLink='/images/39.png'
                                />
                                <CollectionPiece 
                                    title="The student"
                                    link="/corpus/41"
                                    imgLink='/images/41.png'
                                />
                                <CollectionPiece 
                                    title="The translator"
                                    link="/corpus/43"
                                    imgLink='/images/43.png'
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    People - on EncapsuledCorpus
                                </p>
                                <p>
                                    Corpus <i>noun</i><br/>
                                    : all the writings or works of a particular kind or on a particular subject<br/>
                                    especially : the complete works of an author
                                </p>
                                <p>
                                    Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                    What does it mean?
                                    The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                    You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                                </p>
                                <p>
                                    People is a collection that was written trying to capture the essence of different figures in eight lines.<br/>
                                    The media of the token can be changed: the owner of each token can reach out to me and choose between the static version or the animated and spoken one.<br/>
                                    Only one version of each poem can exist in every moment.
                                </p>
                                <CollectionPiece 
                                    title="The architect"
                                    link="/corpus/16"
                                    imgLink='/images/16.png'
                                />
                                <CollectionPiece 
                                    title="The bartender"
                                    link="/corpus/18"
                                    imgLink='/images/18.png'
                                />
                                <CollectionPiece 
                                    title="The captain"
                                    link="/corpus/20"
                                    imgLink='/images/20.png'
                                />
                                <CollectionPiece 
                                    title="The dancer"
                                    link="/corpus/22"
                                    imgLink='/images/22.png'
                                />
                                <CollectionPiece 
                                    title="The editor"
                                    link="/corpus/24"
                                    imgLink='/images/24.png'
                                />
                                <CollectionPiece 
                                    title="The fisherman"
                                    link="/corpus/26"
                                    imgLink='/images/26.png'
                                />
                                <CollectionPiece 
                                    title="The inventor"
                                    link="/corpus/28"
                                    imgLink='/images/28.png'
                                />
                                <CollectionPiece 
                                    title="The lamplighter"
                                    link="/corpus/30"
                                    imgLink='/images/30.png'
                                />
                                <CollectionPiece 
                                    title="The lumberjack"
                                    link="/corpus/32"
                                    imgLink='/images/32.png'
                                />
                                <CollectionPiece 
                                    title="The philosopher"
                                    link="/corpus/34"
                                    imgLink='/images/34.png'
                                />
                                <CollectionPiece 
                                    title="The poet"
                                    link="/corpus/36"
                                    imgLink='/images/36.png'
                                />
                                <CollectionPiece 
                                    title="The runner"
                                    link="/corpus/38"
                                    imgLink='/images/38.png'
                                />
                                <CollectionPiece 
                                    title="The singer"
                                    link="/corpus/40"
                                    imgLink='/images/40.png'
                                />
                                <CollectionPiece 
                                    title="The teacher"
                                    link="/corpus/42"
                                    imgLink='/images/42.png'
                                />
                                <CollectionPiece 
                                    title="The writer"
                                    link="/corpus/44"
                                    imgLink='/images/44.png'
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                People - on EncapsuledCorpus
                            </p>
                            <p>
                                Corpus <i>noun</i><br/>
                                : all the writings or works of a particular kind or on a particular subject<br/>
                                especially : the complete works of an author
                            </p>
                            <p>
                                Corpus is my custom writer's smart contract. Crafted specifically to mint my upcoming works on-chain.

                                What does it mean?
                                The texts stored on this contract can be retrieved directly from it, without the need of any other service. The poems rely solely on the Ethereum blockchain to exist.
                                You can go to the contract page on Etherscan and input the tokenID you want to read in the variable 15. texts.
                            </p>
                            <p>
                                People is a collection that was written trying to capture the essence of different figures in eight lines.<br/>
                                The media of the token can be changed: the owner of each token can reach out to me and choose between the static version or the animated and spoken one.<br/>
                                Only one version of each poem can exist in every moment.
                            </p>
                            <CollectionPiece 
                                title="The actor"
                                link="/corpus/15"
                                imgLink='/images/15.png'
                            />
                            <CollectionPiece 
                                title="The architect"
                                link="/corpus/16"
                                imgLink='/images/16.png'
                            />
                            <CollectionPiece 
                                title="The bard"
                                link="/corpus/17"
                                imgLink='/images/17.png'
                            />
                            <CollectionPiece 
                                title="The bartender"
                                link="/corpus/18"
                                imgLink='/images/18.png'
                            />
                            <CollectionPiece 
                                title="The bricklayer"
                                link="/corpus/19"
                                imgLink='/images/19.png'
                            />
                            <CollectionPiece 
                                title="The captain"
                                link="/corpus/20"
                                imgLink='/images/20.png'
                            />
                            <CollectionPiece 
                                title="The cartographer"
                                link="/corpus/21"
                                imgLink='/images/21.png'
                            />
                            <CollectionPiece 
                                title="The dancer"
                                link="/corpus/22"
                                imgLink='/images/22.png'
                            />
                            <CollectionPiece 
                                title="The drifter"
                                link="/corpus/23"
                                imgLink='/images/23.png'
                            />
                            <CollectionPiece 
                                title="The editor"
                                link="/corpus/24"
                                imgLink='/images/24.png'
                            />
                            <CollectionPiece 
                                title="The engineer"
                                link="/corpus/25"
                                imgLink='/images/25.png'
                            />
                            <CollectionPiece 
                                title="The fisherman"
                                link="/corpus/26"
                                imgLink='/images/26.png'
                            />
                            <CollectionPiece 
                                title="The hunter"
                                link="/corpus/27"
                                imgLink='/images/27.png'
                            />
                            <CollectionPiece 
                                title="The inventor"
                                link="/corpus/28"
                                imgLink='/images/28.png'
                            />
                            <CollectionPiece 
                                title="The journalist"
                                link="/corpus/29"
                                imgLink='/images/29.png'
                            />
                            <CollectionPiece 
                                title="The lamplighter"
                                link="/corpus/30"
                                imgLink='/images/30.png'
                            />
                            <CollectionPiece 
                                title="The librarian"
                                link="/corpus/31"
                                imgLink='/images/31.png'
                            />
                            <CollectionPiece 
                                title="The lumberjack"
                                link="/corpus/32"
                                imgLink='/images/32.png'
                            />
                            <CollectionPiece 
                                title="The painter"
                                link="/corpus/33"
                                imgLink='/images/33.png'
                            />
                            <CollectionPiece 
                                title="The philosopher"
                                link="/corpus/34"
                                imgLink='/images/34.png'
                            />
                            <CollectionPiece 
                                title="The photographer"
                                link="/corpus/35"
                                imgLink='/images/35.png'
                            />
                            <CollectionPiece 
                                title="The poet"
                                link="/corpus/36"
                                imgLink='/images/36.png'
                            />
                            <CollectionPiece 
                                title="The rewilder"
                                link="/corpus/37"
                                imgLink='/images/37.png'
                            />
                            <CollectionPiece 
                                title="The runner"
                                link="/corpus/38"
                                imgLink='/images/38.png'
                            />
                            <CollectionPiece 
                                title="The salesman"
                                link="/corpus/39"
                                imgLink='/images/39.png'
                            />
                            <CollectionPiece 
                                title="The singer"
                                link="/corpus/40"
                                imgLink='/images/40.png'
                            />
                            <CollectionPiece 
                                title="The student"
                                link="/corpus/41"
                                imgLink='/images/41.png'
                            />
                            <CollectionPiece 
                                title="The teacher"
                                link="/corpus/42"
                                imgLink='/images/42.png'
                            />
                            <CollectionPiece 
                                title="The translator"
                                link="/corpus/43"
                                imgLink='/images/43.png'
                            />
                            <CollectionPiece 
                                title="The writer"
                                link="/corpus/44"
                                imgLink='/images/44.png'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Corpus;