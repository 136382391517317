import React, { Component } from 'react'
import { Link } from "react-router-dom";

class CollectionPiece extends Component {
    render() {
        return(
            <div>
                <p className="collectionPieceTitle">
                    {this.props.title}
                </p>
                {this.props.link.startsWith("/") 
                    ? <Link to={this.props.link}>
                        <img
                            src={this.props.imgLink}
                            alt={this.props.title} className="pieceImg"
                        />
                    </Link>
                    : <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                        <img
                            src={this.props.imgLink}
                            alt={this.props.title} className="pieceImg"
                        />
                    </a>
                }
            </div>
        )
    }
}

export default CollectionPiece;
