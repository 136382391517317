import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import Grid from '@mui/material/Grid';
import sketch from '../components/SketchChronicles';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { CssTextField, BWButton } from '../components/CustomFormComponents';

export default class Chronicles extends Component {
    state = {
        hash: this.generateRandomHash(),
        year: "2023",
        month: "01",
        day: "01",
        hours: "00",
        minutes: "00",
        seconds: "00",
    }

    generateRandomHash() {
        let result           = '';
        let characters       = 'abcdefghi0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < 64; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    buttonClickRandomHash() {
        this.setState({
            hash: this.generateRandomHash(),
        })
        console.log(window.tokenHash)
    }

    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Chronicles
                            </p>
                            <a href="https://gallery.verticalcrypto.art/token/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/11" target="_blank" rel="noopener noreferrer">
                                Available on VerticalCryptoArt
                            </a>
                            <br/><br/>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <script>{window.tokenHash=this.state.hash}</script>
                                <script>{window.timeSeed=this.state.year + this.state.month + this.state.day + this.state.hours + this.state.minutes + this.state.seconds}</script>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <div style={{padding: "40px 0 20px 0"}}>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="left" style={{marginBottom: 20}}>  
                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center"> 
                                        <Grid item xs={9}>
                                            <CssTextField size="small" fullWidth id="tokenHash" label="Hash" variant="outlined" 
                                                value={this.state.hash} 
                                                onChange={(event) => this.setState({hash: event.target.value})}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <BWButton variant="outlined" onClick={() => this.buttonClickRandomHash()}>
                                                Generate random hash
                                            </BWButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="year" label="Year" variant="outlined" 
                                                    value={this.state.year} 
                                                    onChange={(event) => this.setState({year: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="month" label="Month" variant="outlined" 
                                                    value={this.state.month} 
                                                    onChange={(event) => this.setState({month: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="day" label="Day" variant="outlined" 
                                                    value={this.state.day} 
                                                    onChange={(event) => this.setState({day: event.target.value})}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="hours" label="Hours" variant="outlined" 
                                                    value={this.state.hours} 
                                                    onChange={(event) => this.setState({hours: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="minutes" label="Minutes" variant="outlined" 
                                                    value={this.state.minutes} 
                                                    onChange={(event) => this.setState({minutes: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CssTextField size="small" fullWidth id="seconds" label="Seconds" variant="outlined" 
                                                    value={this.state.seconds} 
                                                    onChange={(event) => this.setState({seconds: event.target.value})}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <p>
                                A collection of perspectives - many voices, one hi<i>story</i><br/><br/>
                                Every piece of chronicles is a different voice, a different point of view, 
                                narrating the same history as we are living it.<br/><br/>
                                Chronicles are ephemeral, but consistent.<br/>
                                They will always maintain their main properties, but every time you look at them, 
                                they will tell you about a different time.<br/><br/>
                                Each individual chronicle is a collection composed of infinite variations itself.<br/>
                                So each of them is not only a piece, but all the pieces that are written in that 
                                chronicle every second. That's about 31,536,000 different outputs each year.
                            </p>
                            <br/>
                            <p>
                                <b>How does it work</b><br/><br/>
                                Chronicles uses two different RNGs. One of them is derived uniquely 
                                from the hash of the piece, and determines the main aesthetics such as accent color, 
                                density of characters, margins, type of core etc.<br/>
                                The second is derived by a combination of current point in time and hash. 
                                This is so that every piece is different from each other when looked at in the 
                                same moment, and also continues to vary over time. <br/>
                                So if you look at a chronicle now, and then after a few seconds refresh the page 
                                (or click on it), you will see the chronicle changed to reflect the newer point in time, 
                                but maintaining the aesthetical style.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}