
export default function sketch(p) {

  function makeid(length) {
    var result           = '';
    var characters       = 'abcdefghi0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  let tokenData = {hash: makeid(64),tokenID: "0"}
  console.log(tokenData.hash)
  //tokenData.hash = "df1ii89gc4fc56iehfhae72c85i2gecfbie2i5a24gfg602398cie8bdd08ai667"
  class R {
    constructor(hash) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(hash.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(hash.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    random_dec() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    randomBetween(a, b) {
      return a + (b - a) * this.random_dec();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    randomInt(a, b) {
      return Math.floor(this.randomBetween(a, b + 1));
    }
    // random boolean with p as percent likelihood of true
    random_bool(p) {
      return this.random_dec() < p;
    }
    // random value in an array of items
    randomChoice(list) {
      return list[this.randomInt(0, list.length - 1)];
    }
  }

  function getPos(x1, x2, perc) {
    if (perc>1) {
      return x2
    }
    return x1*(1-perc) + x2*perc
  }

  function gsft(d) {
    let secs = '0' + d.getUTCSeconds()
    return '' + d.getUTCFullYear() + d.getUTCMonth() + d.getUTCDate() + d.getUTCHours() + d.getUTCMinutes() + secs.slice(-2) + tokenData.hash
  }

  function gnsft(d) {
    let pd = new Date(d.getTime() + changePeriod*1000)
    let secs = '0' + pd.getUTCSeconds()
    return '' + pd.getUTCFullYear() + pd.getUTCMonth() + pd.getUTCDate() + pd.getUTCHours() + pd.getUTCMinutes() + secs.slice(-2) + tokenData.hash
  }

  function iar(v, inc, min, max) {
    v += inc
    if (v<min) {
      v = min
    }
    if (v>max) {
      v = max
    }
    return v
  }

  let cont = 100
  let boundaries = 0.05
  let angleBoundaries = [1, 0.5, 0.3, 0.1, 0.05, 0.02, 0.01, 0]
  let angleConts = [5, 10, 20, 50, 200]
  let done = false
  let bgLines = []

  const rng = new R(tokenData.hash);
  let changePeriod = rng.randomChoice([5, 10, 20, 30, 60])
  let angleBoundary = rng.randomChoice(angleBoundaries)
  let angleCont = rng.randomChoice(angleConts)
  let numPoints = rng.randomInt(2, 5)
  let steps = rng.randomInt(700, 1000)
  let reversedColors = rng.randomBetween(0, 1) > 0.5
  let margin = rng.randomChoice([0.2, 0.3, 0.4])
  let rectSpread = rng.randomChoice([0.01, 0.02, 0.03, 0.05, 0.1])
  let bgLinesDist = rng.randomChoice([0.005, 0.01, 0.02])
  let stepAcc = rng.randomChoice([1, 1.5, 2, 3])
  let limitLines = rng.randomBetween(0, 1) > 0.5
  let cornerLines = rng.randomBetween(0, 1) > 0.5
  let firstLastLines = rng.randomBetween(0, 1) > 0.5
  let widthLines = rng.randomBetween(0, 1) > 0.5
  let bezier = rng.randomBetween(0, 1) > 0.5
  let bezierBorder = rng.randomBetween(0, 1) > 0.2
  let intCircle = rng.randomBetween(0, 1) > 0.5
  let intCircleSize = rng.randomChoice([0.05, 0.1, 0.2, 0.3])
  let intCirclePos = rng.randomChoice(['tl', 'tr', 'br', 'bl'])
  let tone = rng.randomInt(0, 360)
  let coreFilled = rng.randomBetween(0, 1) > 0.5
  let aesthetics = rng.randomChoice(['empty', 'lines', 'squares', 'hor', 'ver'])
  let bgSquaresSize = aesthetics === 'squares' ? rng.randomChoice([0.03, 0.05, 0.1, 0.2]) : rng.randomChoice([0.02, 0.03, 0.05, 0.1, 0.2, 0.5, 1])
  let fullSat = rng.randomBetween(0, 1) > 0.5
  let linesPerc = rng.randomChoice([0.7, 0.8, 0.9, 0.99])
  let connected = rng.randomBetween(0, 1) > 0.5
  let coherent = rng.randomBetween(0, 1) > 0.5
  let monoc = rng.randomBetween(0, 1) > 0.95
  let coreExtraWidth = coreFilled ? 1 : rng.randomChoice([5, 10, 20])

  let satIncPerc = rng.randomBetween(0, 1)
  let brIncPerc = rng.randomBetween(0, 1)
  let satInc
  let brInc
  if (satIncPerc < 0.8) {
    satInc = 1
  } else if (satIncPerc < 0.9) {
    satInc = 2
  } else if (satIncPerc < 0.99) {
    satInc = 5
  } else {
    satInc = 10
  }

  if (brIncPerc < 0.8) {
    brInc = 1
  } else if (brIncPerc < 0.9) {
    brInc = 2
  } else if (brIncPerc < 0.99) {
    brInc = 5
  } else {
    brInc = 10
  }

  let pointSparsityPerc = rng.randomBetween(0, 1)
  let pointSparsity = 20
  if (coreExtraWidth === 1 && pointSparsityPerc < 0.9) {
    pointSparsity = 1
  } else if (coreExtraWidth === 1 && pointSparsityPerc < 0.95) {
    pointSparsity = 5
  } else if (coreExtraWidth === 1 && pointSparsityPerc < 0.99) {
    pointSparsity = 10
  } else if (coreExtraWidth === 1 && pointSparsityPerc < 1) {
    pointSparsity = 20
  } else if (coreExtraWidth === 5 && pointSparsityPerc < 0.7) {
    pointSparsity = 5
  } else if (coreExtraWidth === 5 && pointSparsityPerc < 0.9) {
    pointSparsity = 10
  } else if (coreExtraWidth === 5 && pointSparsityPerc < 1) {
    pointSparsity = 20
  } else if (coreExtraWidth === 10 && pointSparsityPerc < 0.8) {
    pointSparsity = 10
  } else if (coreExtraWidth === 10 && pointSparsityPerc < 1) {
    pointSparsity = 20
  }

  let minBr = reversedColors ? 30 : 0
  let minSat = monoc ? 0 : reversedColors ? 0 : 30
  let maxBr = reversedColors ? 100 : 70
  let maxSat = monoc ? 0 : reversedColors ? 70 : 100 

  let w, h, g, gbg, currRng, nextRng, currStuff, nextStuff, currPoints, currMinX, currMinY, currMaxX, currMaxY, currBr, currSat, nextPoints, nextMinX, nextMinY, nextMaxX, nextMaxY, nextBr, nextSat

  function getAllPoints(r) {
    let pointWeight = 0.005
    let minX = 1
    let minY = 1
    let maxX = 0
    let maxY = 0
    let sat = r.randomBetween(minSat, maxSat)
    let br = r.randomBetween(minBr, maxBr)
    let points = []
    let circles = []
    for (let i=0; i<numPoints; i++) {
      points.push({
        x: r.randomBetween(margin, 1-margin),
        y: r.randomBetween(margin, 1-margin),
        a: r.randomBetween(0, p.TWO_PI),
      })
    }

    let x = points[0].x
    let y = points[0].y
    let a = points[0].a

    for (const pp of points) {
      a = pp.a
      let angleContCounter = 0
      let maxThick = false
      let na
      for (let i=0; i<steps; i++) {
        // change length before changing boundary
        if (i%angleContCounter === 0) {
          angleCont = r.randomChoice(angleConts)
          angleContCounter = 0
        }
        // change boundary (line more or less curvy)
        if (i%angleCont === 0) {
          angleBoundary = r.randomChoice(angleBoundaries)
        }
        // angle increment
        if (i%cont === 0) {
          na = r.randomBetween(-angleBoundary, angleBoundary)
        }
        // change thickness
        if (maxThick) {
          pointWeight += r.randomBetween(-0.0002, 0)
        } else {
          pointWeight += r.randomBetween(-0.0002, 0.0002)
        }
        // ensure thickness is not too little or too much
        if (pointWeight < 0.002) {
          pointWeight = 0.002
        }
        if (pointWeight >= 0.01) {
          pointWeight = 0.01
          maxThick = true
        }
        if (pointWeight < 0.01) {
          maxThick = false
        }
        // allow changing angles for sharp turns
        if (r.randomBetween(0, 1) > 0.5 && i%cont === 0) {
          //a = rng.randomBetween(0, TWO_PI)
        } else {
          a += na
        }
        circles.push({
          x: x,
          y: y,
          w: pointWeight,
          p: r.randomBetween(0, 1),
          sat: sat,
          br: br,
        })
        if (x < minX) {
          minX = x
        }
        if (y < minY) {
          minY = y
        }
        if (x > maxX) {
          maxX = x
        }
        if (y > maxY) {
          maxY = y
        }
        x += p.cos(a)*0.001
        y += p.sin(a)*0.001
        if (y<boundaries || x<boundaries) {
          a+=p.PI
        } else if (y>1-boundaries|| x>1-boundaries) {
          a-=p.PI
        }
        angleContCounter++
        sat = iar(sat, r.randomBetween(-satInc, satInc), minSat, maxSat)
        br = iar(br, r.randomBetween(-brInc, brInc), minBr, maxBr)
      }
    }
    return [circles, minX, maxX, minY, maxY]
  }

  p.setup = () => {
    if (p.windowHeight <= p.windowWidth) {
      h = p.windowHeight
      w = p.windowHeight
    } else {
      w = p.windowWidth;
      h = p.windowWidth
    }
    p.frameRate(30)
    p.colorMode(p.HSB)
    p.createCanvas(w, h)
    let d = new Date()
    d.setUTCSeconds(d.getUTCSeconds() - d.getUTCSeconds() % changePeriod)
    currRng = new R(gsft(d))
    nextRng = new R(gnsft(d))
    g = p.createGraphics(w, h)
    gbg = p.createGraphics(w, h)

    if (aesthetics !== 'empty') {
      for (let i=-0.1; i<=1.1; i+=bgLinesDist) {
        for (let j=-0.1; j<=1.1; j+=bgLinesDist) {
          bgLines.push({
            x: i+rng.randomBetween(-rectSpread, rectSpread),
            y: j+rng.randomBetween(-rectSpread, rectSpread), 
            w: rng.randomBetween(-bgSquaresSize, bgSquaresSize), 
            p: rng.randomBetween(0, 1),
            c: monoc ? rng.randomChoice([
              [tone, 0, 100], [tone, 0, 30], [tone, 0, 70]
            ]) : rng.randomChoice([
              [tone, fullSat ? 100 : 50, 50], [tone, 70, 30], [tone, 30, 70]
            ])
          })
        }
      }
      drawBgLines(gbg, 0.05)
    }

    currStuff = getAllPoints(currRng)
    currPoints = currStuff[0]
    currMinX = currStuff[1]
    currMaxX = currStuff[2]
    currMinY = currStuff[3]
    currMaxY = currStuff[4]

    nextStuff = getAllPoints(nextRng)
    nextPoints = nextStuff[0]
    nextMinX = nextStuff[1]
    nextMaxX = nextStuff[2]
    nextMinY = nextStuff[3]
    nextMaxY = nextStuff[4]

    currBr = currRng.randomInt(minBr, maxBr)
    nextBr = nextRng.randomInt(minBr, maxBr)

    currSat = currRng.randomInt(minSat, maxSat)
    nextSat = nextRng.randomInt(minSat, maxSat)
  }

  p.draw = () => {
    let d = new Date()
    let s = d.getUTCSeconds()
    let ms = d.getUTCMilliseconds()
    let currStep = (s % changePeriod + ms/1000) / changePeriod * stepAcc
    if (s % changePeriod === 0 && !done) {
      currRng = new R(gsft(d))
      nextRng = new R(gnsft(d))
      currStuff = getAllPoints(currRng)
      currPoints = currStuff[0]
      currMinX = currStuff[1]
      currMaxX = currStuff[2]
      currMinY = currStuff[3]
      currMaxY = currStuff[4]
    
      nextStuff = getAllPoints(nextRng)
      nextPoints = nextStuff[0]
      nextMinX = nextStuff[1]
      nextMaxX = nextStuff[2]
      nextMinY = nextStuff[3]
      nextMaxY = nextStuff[4]

      currBr = currRng.randomInt(minBr, maxBr)
      nextBr = nextRng.randomInt(minBr, maxBr)
    
      currSat = currRng.randomInt(minSat, maxSat)
      nextSat = nextRng.randomInt(minSat, maxSat)

      done = true
    }
    if (s % changePeriod > 0) {
      done = false
    }
    p.background(reversedColors ? "#0f0f0f" : "#fafafa")
    p.image(gbg, 0, 0)
    let minX = getPos(currMinX, nextMinX, currStep)
    let maxX = getPos(currMaxX, nextMaxX, currStep)
    let minY = getPos(currMinY, nextMinY, currStep)
    let maxY = getPos(currMaxY, nextMaxY, currStep)
    write(g, h, currPoints, nextPoints, currStep, minX, maxX, minY, maxY, currSat, currBr, nextSat, nextBr)
    p.image(g, 0, 0)
  }


  function drawBgLines(g, al) {
    g.noFill()
    g.strokeWeight(h*0.001)
    for (let i=0; i<bgLines.length; i++) {
      let cl = p.color(bgLines[i].c)
      cl.setAlpha(al)
      g.stroke(cl)
      if (bgLines[i].p > 0.5 && ['lines', 'squares'].includes(aesthetics)) {
        g.rect(
          bgLines[i].x*h,
          bgLines[i].y*h,
          bgLines[i].w*h
        )
      }
      if (bgLines[i].p > linesPerc && ['hor'].includes(aesthetics)) {
        g.line(
          0,
          bgLines[i].y*h+bgLines[i].w*h,
          h,
          bgLines[i].y*h+bgLines[i].w*h,
        )
      } 
      if (bgLines[i].p > linesPerc && ['ver'].includes(aesthetics)) {
        g.line(
          bgLines[i].x*h+bgLines[i].w*h,
          0,
          bgLines[i].x*h+bgLines[i].w*h,
          h,
        )
      } 
      if (bgLines[i].p < 0.01 && ['squares'].includes(aesthetics)) {
        g.fill(cl)
        g.noStroke()
        g.rect(
          bgLines[i].x*h,
          bgLines[i].y*h,
          bgLines[i].w*2*h
        )
      } 
    }
  }

  function write(g, h, currPoints, nextPoints, currStep, minX, maxX, minY, maxY, currSat, currBr, nextSat, nextBr) {
    g.clear()

    let ccc = p.color(reversedColors ? "#cfcfcf" : "#1a1a1a")
    ccc.setAlpha(0.6)
    g.stroke(ccc)
    g.strokeWeight(h*0.002)
    if (firstLastLines) {
      // horizontal crossing first and last points
      g.line(
        0, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h, 
        w, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h
      )
      g.line(
        0, getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h, 
        w, getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h
        )

      // vertical crossing first and last points
      g.line(
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, 0, 
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, h
      )
      g.line(
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, 0, 
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, h
      )
    }
    if (cornerLines) {
      // diagonal from first and last points to middle bottom
      g.line(
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h, 
        h, h
      )
      g.line(
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, 
        getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h, 
        h, h
      )
      // diagonal from first and last points to middle top
      g.line(
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h, 
        h, 0
      )
      g.line(
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, 
        getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h, 
        h, 0
      )
      // diagonal from first and last points to left bottom
      g.line(
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h, 
        0, h
      )
      g.line(
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, 
        getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h, 
        0, h
      )
      // diagonal from first and last points to left top
      g.line(
        getPos(currPoints[0].x, nextPoints[0].x, currStep)*h, getPos(currPoints[0].y, nextPoints[0].y, currStep)*h, 
        0, 0
      )
      g.line(
        getPos(currPoints[currPoints.length-1].x, nextPoints[nextPoints.length-1].x, currStep)*h, 
        getPos(currPoints[currPoints.length-1].y, nextPoints[nextPoints.length-1].y, currStep)*h, 
        0, 0
      )
    }
    if (limitLines) {
      // left most and right most
      g.line(minX*h, 0, minX*h, h)
      g.line(maxX*h, 0, maxX*h, h)

      // top most bottom most
      g.line(0, minY*h, w, minY*h)
      g.line(0, maxY*h, w, maxY*h)
    }
    if (!bezierBorder && aesthetics !== 'empty') {
      g.noStroke()
    }
    let clcl = p.color(reversedColors ? "#0f0f0f" : "#fafafa")
    clcl.setAlpha(0.4)
    g.fill(clcl)
    if (intCircle) {
      let inter
      if (intCirclePos === 'tl') {
        inter = getIntersection(
          {x:getPos(currPoints[0].x, nextPoints[0].x, currStep), y:getPos(currPoints[0].y, nextPoints[0].y, currStep)},
          {x:0, y:0},
          {x:minX, y:0},
          {x:minX, y:1}
        )
      } else if (intCirclePos === 'tr') {
        inter = getIntersection(
          {x:getPos(currPoints[0].x, nextPoints[0].x, currStep), y:getPos(currPoints[0].y, nextPoints[0].y, currStep)},
          {x:1, y:0},
          {x:maxX, y:0},
          {x:maxX, y:1}
        )
      } else if (intCirclePos === 'br') {
        inter = getIntersection(
          {x:getPos(currPoints[0].x, nextPoints[0].x, currStep), y:getPos(currPoints[0].y, nextPoints[0].y, currStep)},
          {x:1, y:1},
          {x:maxX, y:0},
          {x:maxX, y:1}
        )
      } else if (intCirclePos === 'bl') {
        inter = getIntersection(
          {x:getPos(currPoints[0].x, nextPoints[0].x, currStep), y:getPos(currPoints[0].y, nextPoints[0].y, currStep)},
          {x:0, y:1},
          {x:minX, y:0},
          {x:minX, y:1}
        )
      }
      g.circle(inter.x*h, inter.y*h, intCircleSize*h)
    }
    if (bezier) {
      //left
      g.bezier(
        minX/2*h, minY/2*h,
        h, 0,
        h, h,
        minX/2*h, (maxY+(1-maxY)/2)*h
      )
      //right
      g.bezier(
        (maxX+(1-maxX)/2)*h, minY/2*h,
        0, 0,
        0, h,
        (maxX+(1-maxX)/2)*h, (maxY+(1-maxY)/2)*h
      )
      //top
      g.bezier(
        minX/2*h, minY/2*h,
        0, h,
        h, h,
        (maxX+(1-maxX)/2)*h, minY/2*h
      )
      //bottom
      g.bezier(
        minX/2*h, (maxY+(1-maxY)/2)*h,
        0, 0,
        h, 0,
        (maxX+(1-maxX)/2)*h, (maxY+(1-maxY)/2)*h
      )
    }

    for (let i=0; i<currPoints.length; i++) { 
      g.stroke(reversedColors ? "#fafafa" : "#0f0f0f")
      g.strokeWeight(getPos(currPoints[i].w, nextPoints[i].w, currStep)*0.5*h)
      if (widthLines) {
        g.line(
          (1-0.02)*h, getPos(currPoints[i].y, nextPoints[i].y, currStep)*h,
          (1-0.01)*h, getPos(currPoints[i].y, nextPoints[i].y, currStep)*h
        )
        g.line(
          getPos(currPoints[i].x, nextPoints[i].x, currStep)*h, 0.01*h,
          getPos(currPoints[i].x, nextPoints[i].x, currStep)*h, 0.02*h
        )
      }
      let cl
      if (coherent) {
        cl = [tone, getPos(currSat, nextSat, currStep), getPos(currBr, nextBr, currStep)]
      } else {
        cl = [tone, getPos(currPoints[i].sat, nextPoints[i].sat, currStep), getPos(currPoints[i].br, nextPoints[i].br, currStep)]
      }
      if (coreFilled) {
        g.noStroke()
        g.fill(p.color(cl))
      } else {
        g.noFill()
        g.stroke(p.color(cl))
      }
      if (connected && i <currPoints.length-1) {
        g.line(
          getPos(currPoints[i].x, nextPoints[i].x, currStep)*h,
          getPos(currPoints[i].y, nextPoints[i].y, currStep)*h,
          getPos(currPoints[i+1].x, nextPoints[i+1].x, currStep)*h,
          getPos(currPoints[i+1].y, nextPoints[i+1].y, currStep)*h,
        )
      }
      if (i % pointSparsity === 0) {
        g.circle(
          getPos(currPoints[i].x, nextPoints[i].x, currStep)*h,
          getPos(currPoints[i].y, nextPoints[i].y, currStep)*h,
          getPos(currPoints[i].w, nextPoints[i].w, currStep)*h*coreExtraWidth,
        )
      }
    }
  }

  function getIntersection(p1, p2, p3, p4) {
    const ua = ((p4.x - p3.x) * (p1.y - p3.y) - 
              (p4.y - p3.y) * (p1.x - p3.x)) /
              ((p4.y - p3.y) * (p2.x - p1.x) - 
              (p4.x - p3.x) * (p2.y - p1.y));

    const x = p1.x + ua * (p2.x - p1.x);
    const y = p1.y + ua * (p2.y - p1.y);

    return {x, y}
  }

}
