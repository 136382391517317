
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';


export const BWButton = styled(Button)(({ theme }) => ({
  color: '#fafafa',
  borderColor: '#fafafa',
  textTransform: 'none',
  fontFamily: "Montserrat",
  '&:hover': {
      borderColor: '#edb2e5',
  },
  ":disabled": {
      color: 'grey',
      borderColor: 'grey',
  }
}));

export const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      color: '#fafafa',
    },
    '& label': {
      color: '#fafafa',
    },
    '& label.Mui-focused': {
      color: '#fafafa',
    },
    '& .MuiInputAdornment-root': {
      color: '#fafafa',
    },
    '& .MuiTypography-root': {
      color: '#fafafa',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fafafa',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fafafa',
      },
      '&:hover fieldset': {
        borderColor: '#fafafa',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fafafa',
      },
    },
  },
})(TextField);

export const StyledToggleButton = withStyles({
  root: {
    color: '#fafafa',
    '&$selected': {
      border: '1px solid !important',
      borderLeft: '1px solid',
      borderColor: '#fafafa',
      color: '#fafafa',
    },
    '&:hover': {
      borderColor: '#edb2e5',
      color: '#fafafa',
    },
  },
  selected: {},
})(ToggleButton);