import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

class PointLineCode extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div>
                            <div style={{padding: "50px 50px 0 50px"}}>
                                <p className="collectionTitle">
                                    Point and Line to Code
                                </p>
                                <p>
                                    "Every phenomenon can be experienced in two ways. These two ways are not arbitrary, but are bound up with the phenomenon - developing out of its nature and characteristics: externally - or - inwardly."
                                    - Kandinsky, Point and Line to Plane
                                    <hr/>
                                    My study about color and emotion started a long time ago. As part of this, I wrote a book where one of the characters was on a quest to paint canvases that could evoke emotions into viewers. She wanted to trap her own emotions into them. I pictured these canvases much like Rothko's, heavy on color, uneven, but with a bit more variation. Abstract paintings that changed hues and saturation.
                                </p>
                                <p>
                                    It wasn't until I started studying more Kandinsky, specifically after I read "Point and Line to Plane" that I understood that points, lines, and shapes carry their own feelings and emotions with them. Color alone is not be able to transmit as many emotions as it could combined with form.
                                </p>
                                <p>
                                    And that's how Point and Line to Code was born. Minimalistic compositions that carry their own emotion.
                                </p>
                                <p>
                                    Kandinsky had a condition called synesthesia, that caused his senses of sight and hearing to be interconnected. As he once said, "the sound of colors is so definite that it would be hard to find anyone who would express bright yellow with bass notes or dark lake with treble."
                                    To me, similarly, music can be visualized, and images can be heard. Each of the features of Point and Line to Code is related to music, in a way that instinctively makes sense to me.
                                </p>
                                <p>
                                    I hope you will be able to feel, see, and hear some variation of what I feel, see, and hear.
                                </p>
                            </div>
                            <div className="coupleCollections">
                                <div className="collectionLeft">
                                    <img alt='' src='/images/pointlinecode/0.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/2.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/4.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/6.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/8.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/10.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/12.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/14.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/16.png' className="pieceImgGen" />
                                </div>
                                <div className="collectionRight">
                                    <img alt='' src='/images/pointlinecode/1.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/3.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/5.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/7.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/9.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/11.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/13.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/15.png' className="pieceImgGen" />
                                    <img alt='' src='/images/pointlinecode/17.png' className="pieceImgGen" />
                                </div>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                             <p className="collectionTitle">
                                Point and Line to Code
                            </p>
                            <p>
                                "Every phenomenon can be experienced in two ways. These two ways are not arbitrary, but are bound up with the phenomenon - developing out of its nature and characteristics: externally - or - inwardly."
                                - Kandinsky, Point and Line to Plane
                                <hr/>
                                My study about color and emotion started a long time ago. As part of this, I wrote a book where one of the characters was on a quest to paint canvases that could evoke emotions into viewers. She wanted to trap her own emotions into them. I pictured these canvases much like Rothko's, heavy on color, uneven, but with a bit more variation. Abstract paintings that changed hues and saturation.
                            </p>
                            <p>
                                It wasn't until I started studying more Kandinsky, specifically after I read "Point and Line to Plane" that I understood that points, lines, and shapes carry their own feelings and emotions with them. Color alone is not be able to transmit as many emotions as it could combined with form.
                            </p>
                            <p>
                                And that's how Point and Line to Code was born. Minimalistic compositions that carry their own emotion.
                            </p>
                            <p>
                                Kandinsky had a condition called synesthesia, that caused his senses of sight and hearing to be interconnected. As he once said, "the sound of colors is so definite that it would be hard to find anyone who would express bright yellow with bass notes or dark lake with treble."
                                To me, similarly, music can be visualized, and images can be heard. Each of the features of Point and Line to Code is related to music, in a way that instinctively makes sense to me.
                            </p>
                            <p>
                                I hope you will be able to feel, see, and hear some variation of what I feel, see, and hear.
                            </p>
                            <img alt='' src='/images/pointlinecode/0.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/1.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/2.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/3.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/4.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/5.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/6.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/7.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/8.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/9.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/10.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/11.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/12.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/13.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/14.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/15.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/16.png' className="pieceImgGen" />
                            <img alt='' src='/images/pointlinecode/17.png' className="pieceImgGen" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default PointLineCode;