import React, { Component } from 'react'

import Web3 from "web3";

import { Link } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import { withStyles } from '@material-ui/core/styles';

const ETableCell = withStyles(theme => ({
root: {
    backgroundColor: '#0f0f0f',
    color: '#fafafa',
    fontFamily: 'Montserrat'
}
}))(TableCell);

class MarketSummary extends Component {
    render() {
        return(
            <div style={{margin: "50px"}}>
                <p style={{fontSize: "30px", fontWeight: "bold"}}>
                    Collection market status
                </p>
                <p>Marketplace contract address: <a href="https://etherscan.io/address/0x4947abfc78acbf276d7a653da4955aee8dc6654d#code" target="_blank" rel="noopener noreferrer">encapsuled market</a></p>
                <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <ETableCell>Title</ETableCell>
                            <ETableCell>On Sale</ETableCell>
                            <ETableCell>Highest Offer</ETableCell>
                            <ETableCell>Owner</ETableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.props.data.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <ETableCell component="th" scope="row">
                                <Link to={"/corpus/" + row.index}>{row.name}</Link>
                            </ETableCell>
                            <ETableCell>{row.onSale.isForSale ? 'Ξ ' + Web3.utils.fromWei(row.onSale.minValue, 'ether') : '-'}</ETableCell>
                            <ETableCell>{row.bids.hasBid ? 'Ξ ' + Web3.utils.fromWei(row.bids.value, 'ether') : '-'}</ETableCell>
                            <ETableCell>
                                <a href={"https://etherscan.io/address/" + row.owner} target="_blank" rel="noopener noreferrer">
                                    {row.ens ? row.ens : row.owner.substring(0, 8) + '...'}
                                </a>
                            </ETableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        )
    }
}

export default MarketSummary;
