
export default function sketch(p5) {
  /*

  poetry for the subconscious



  Poetry is a complex system, where each word and its position within the composition influence the whole text, at times in ways that are easily identifiable, others more obscure and that require multiple readings.

  Layout also has an impact on the whole system to various degrees, from the choice of meter, newline, and typography to concrete and visual poetry, where it often becomes more important than the content itself.

  To further complicate the system, if we take into account that the same letters can be written in different ways, as it goes for handwriting, we arrive at a system that is almost impossible to fully analyze. The same text written by different hands and in different layouts will convey different messages.

  concrete and asemic writing both aim to trascend the language as it is, to convey and or communicate concepts either raw or not logically formed. Essentially, to talk not to the analytical mind, but to the instinctive or subconscious one.

  they do not lack meaning, they lack meaning in the formal definition of meaning, but there is no such thing as truly meaningless.
  the focus is different: is to be perceived from a more internal point of view rather than a logical and culturally influenced one. It's trying to communicate to the parts of ourselves that not even we fully understand. 
  By forming unexpected outputs, by putting together pieces that we wouldn't normally do, a barrier is broken, and we enter into a different mind space.
  Within this mind space, lies imagination. 

  The aim of this series is exactly this. Characters formed by an algorithm are written in a visual representation derived from the algorithm of the characters themselves, and analyzed in a system of relevance, accents, and connections.

  The key to understand each piece within the series is unique to all of us, and also to the specific moment we are living. Imagination within every individual evolves as they accumulate more perceptions, more experiences, fueling new ways of putting all these parts together. 

  Often when reading a book again, we focus on different parts that we possibly didn't even notice in the previous readings. Visual art is no different.
  This is an invitation to stop and wonder, to reflect on where you are right now, and re-explore it at later points or your life.
  I would also recommend writing down the thoughts each time, along with the date. The track of memories, experiences, imagination, and where your mind was in each moment will help you remember your path and know yourself better.


  the layout needs to be evident in all outputs, otherwise it's just a mess


  */

  //tokenData.hash = "0g57ab30e5gh5326h8bg58idbhi6g1a4i151c4dbbe8249dih256bcabe1agc43i"
  class Random {
    constructor(hash) {
      this.useA = false;
      let sfc32 = function (uint128Hex) {
        let a = parseInt(uint128Hex.substr(0, 8), 16);
        let b = parseInt(uint128Hex.substr(8, 8), 16);
        let c = parseInt(uint128Hex.substr(16, 8), 16);
        let d = parseInt(uint128Hex.substr(24, 8), 16);
        return function () {
          a |= 0; b |= 0; c |= 0; d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };
      // seed prngA with first half of tokenData.hash
      this.prngA = new sfc32(hash.substr(2, 32));
      // seed prngB with second half of tokenData.hash
      this.prngB = new sfc32(hash.substr(34, 32));
      for (let i = 0; i < 1e6; i += 2) {
        this.prngA();
        this.prngB();
      }
    }
    // random number between 0 (inclusive) and 1 (exclusive)
    rD() {
      this.useA = !this.useA;
      return this.useA ? this.prngA() : this.prngB();
    }
    // random number between a (inclusive) and b (exclusive)
    rB(a, b) {
      return a + (b - a) * this.rD();
    }
    // random integer between a (inclusive) and b (inclusive)
    // requires a < b for proper probability distribution
    rI(a, b) {
      return Math.floor(this.rB(a, b + 1));
    }
    // random value in an array of items
    rC(list) {
      return list[this.rI(0, list.length - 1)];
    }

    shuffleDet(array) {
      let m = array.length, t, i;
      while (m) {
        i = Math.floor(this.rD() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
    }
  }

  function gsft(_) {
    console.log("timeSeed " + window.timeSeed + window.tokenHash)
    return window.timeSeed + window.tokenHash
  }
  

  function pointInCircle(x, y, centerX, centerY, r) {
    return p5.abs((x-centerX)*(x-centerX)) + p5.abs((y-centerY)*(y-centerY)) <= r*r/4
  }

  function distance(x1, y1, x2, y2) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx*dx + dy*dy);
  }

  let r = new Random(window.tokenHash);
  let rt
  let angleBoundaries = [1, 0.5, 0.3, 0.1, 0.05, 0.02, 0.01, 0]
  let angleConts = [5, 10, 20, 50, 200]
  let angleBoundary = r.rC(angleBoundaries)
  let angleCont = r.rC(angleConts)
  let numPoints = 4

  let steps = r.rI(700, 1000)

  let reversedColors = r.rB(0, 1) > 0.5
  let boundaries = r.rC([-0.05, 0, 0.05, 0.1, 0.2, 0.3])
  let pointDistanceCheck = r.rC([0.04])
  let travelDistance = r.rC([0.001, 0.002, 0.004, 0.01])
  let hue = r.rB(0, 255)
  let initialPointsDistance = r.rC([0.005, 0.01, 0.02])
  let distanceAsemicChar = r.rC([0.01, 0.02, 0.03, 0.05])
  while (distanceAsemicChar < initialPointsDistance) {
    distanceAsemicChar = r.rC([0.01, 0.02, 0.03, 0.05])
  }
  let fillShapes = r.rB(0, 1) > 0.2
  let shufflePoints = r.rB(0, 1) > 0.8
  let linesRemovalThreshold = r.rC([0.8, 0.9, 0.95, 0.99])
  let charWidth = r.rC([0.002, 0.004, 0.008, 0.01])
  let square = r.rB(0, 1) > 0.5
  let constantWidth = r.rB(0, 1) > 0.8

  let cont = 100
  let pointWeight = 0.005

  let angles = [0.7, 1, 2]
  let points = []
  let circles = []
  let minX = 1
  let minY = 1
  let maxX = 0
  let maxY = 0
  let remapped = []
  let allAsemicPs = []
  let w, h, drawingHeight, drawingWidth

  console.log("numPoints " + numPoints)
  console.log("steps " + steps)
  console.log("pointDistanceCheck " + pointDistanceCheck)
  console.log("boundaries " + boundaries)
  console.log("travelDistance " + travelDistance)
  console.log("hue " + hue)
  console.log("initialPointsDistance " + initialPointsDistance)
  console.log("distanceAsemicChar " + distanceAsemicChar)
  console.log("charWidth " + charWidth)

  function writePoem() {

    r = new Random(window.tokenHash);
    angleBoundary = r.rC(angleBoundaries)
    angleCont = r.rC(angleConts)
    numPoints = 4
  
    steps = r.rI(700, 1000)
  
    reversedColors = r.rB(0, 1) > 0.5
    boundaries = r.rC([-0.05, 0, 0.05, 0.1, 0.2, 0.3])
    pointDistanceCheck = r.rC([0.04])
    travelDistance = r.rC([0.001, 0.002, 0.004, 0.01])
    hue = r.rB(0, 255)
    initialPointsDistance = r.rC([0.005, 0.01, 0.02])
    distanceAsemicChar = r.rC([0.01, 0.02, 0.03, 0.05])
    while (distanceAsemicChar < initialPointsDistance) {
      distanceAsemicChar = r.rC([0.01, 0.02, 0.03, 0.05])
    }
    fillShapes = r.rB(0, 1) > 0.2
    shufflePoints = r.rB(0, 1) > 0.8
    linesRemovalThreshold = r.rC([0.8, 0.9, 0.95, 0.99])
    charWidth = r.rC([0.002, 0.004, 0.008, 0.01])
    square = r.rB(0, 1) > 0.5
    constantWidth = r.rB(0, 1) > 0.8
    points = []
    circles = []
    minX = 1
    minY = 1
    maxX = 0
    maxY = 0
    remapped = []
    allAsemicPs = []
    
    p5.createCanvas(w, h)
    p5.background(reversedColors ? "#0f0f0f" : "#fafafa")

    for (let i=0; i<numPoints; i++) {
      points.push({
        x: r.rB(boundaries, 1-boundaries),
        y: r.rB(boundaries, 1-boundaries),
        a: r.rB(0, p5.TWO_PI)
      })
    }

    let x = points[0].x
    let y = points[0].y

    for (const p of points) {
      getPoints(p, circles, steps, x, y)
    }
    // remapping all
    drawingWidth = maxX - minX
    drawingHeight = maxY - minY
    for (const c of circles) {
      remapped.push({
        x: p5.map(c.x, minX, maxX, 0.5 - drawingWidth/2, 0.5 + drawingWidth/2),
        y: p5.map(c.y, minY, maxY, 0.5 - drawingHeight/2, 0.5 + drawingHeight/2),
        c: c.c,
        a: c.a,
        ab: c.ab,
        w: c.w,
        sw: c.sw,
      })
    }
    circles = remapped
    for (const c of circles) {
      addPointsAsemicChar(allAsemicPs, c)
    }

    if (shufflePoints) {
      r.shuffleDet(circles)
    }
    write()
  }

  p5.setup = () => {
    rt = new Random(gsft(new Date()))
    if (p5.windowHeight <= p5.windowWidth) {
      h = p5.windowHeight
      w = p5.windowHeight
    } else {
      w = p5.windowWidth;
      h = p5.windowWidth
    }
    p5.colorMode(p5.HSB)

    writePoem()
  }

  function write() {
    let col
    for (let i=0; i<circles.length; i++) {
      col = rt.rB(0, 1) > 0.95 ? [hue, 100, 100] : reversedColors ? "#fafafa" : "#0f0f0f"
      circles[i].c = col
      let ewp = rt.rB(0, 1)
      let extraWidth = ewp < 0.5 ? 2 : ewp < 0.8 ? 5 : ewp < 0.95 ? 10 : ewp < 0.99 ? 20 : 50
      circles[i].sw = Math.min(circles[i].w, 0.004) * extraWidth
      drawSystemPoint(i)
      for (const p of allAsemicPs[i]) {
        p5.noStroke()
        p5.fill(col)
        p5.circle(p.x*w, p.y*h, p.w*w)
      }
    }
  }


  function getPoints(p, circles, steps, x, y) {
    let a = p.a
    let angleContCounter = 0
    let maxThick = false
    let na, found, point
    for (let i=0; i<steps; i++) {
      // change thickness
      if (constantWidth) {
        pointWeight = charWidth
      } else {
        if (maxThick) {
          pointWeight += r.rB(-0.0002, 0)
        } else {
          pointWeight += r.rB(-0.0002, 0.0002)
        }
        // ensure thickness is not too little or too much
        if (pointWeight < charWidth/2) {
          pointWeight = charWidth/2
        }
        if (pointWeight >= charWidth) {
          pointWeight = charWidth
          maxThick = true
        }
        if (pointWeight < 0.01) {
          maxThick = false
        }
      }
      // change length before changing boundary
      if (i%angleContCounter === 0) {
        angleCont = r.rC(angleConts)
        angleContCounter = 0
      }
      // change boundary (line more or less curvy)
      if (i%angleCont === 0) {
        angleBoundary = r.rC(angleBoundaries)
      }
      // angle increment
      if (i%cont === 0) {
        na = r.rB(-angleBoundary, angleBoundary)
      }
      // allow changing angles for sharp turns
      if (!(r.rB(0, 1) > 0.5 && i%cont === 0)) {
        a += na
      }
      found = false
      for (let i=circles.length-5; i>=0 && !found; i--) {
        if (pointInCircle(x, y, circles[i].x, circles[i].y, pointDistanceCheck)) {
          found = true
        }
      }
      for (let i=circles.length-1; i>=0 && !found; i--) {
        if (distance(x, y, circles[i].x, circles[i].y) < initialPointsDistance) {
          found = true
        }
      }
      if (!found) {
        let ewp = rt.rB(0, 1)
        let extraWidth = ewp < 0.5 ? 2 : ewp < 0.8 ? 5 : ewp < 0.95 ? 10 : ewp < 0.99 ? 20 : 50
        point = {
          x: x,
          y: y,
          c: rt.rB(0, 1) > 0.95 ? [hue, 100, 100] : reversedColors ? "#fafafa" : "#0f0f0f",
          a: r.rB(0, p5.TWO_PI),
          ab: r.rC(angles),
          w: pointWeight,
          sw: Math.min(pointWeight, 0.004) * extraWidth, // avoid giant squares and circles in system
        }
        circles.push(point)
      }
      if (x < minX) {
        minX = x
      }
      if (y < minY) {
        minY = y
      }
      if (x > maxX) {
        maxX = x
      }
      if (y > maxY) {
        maxY = y
      }
      x += p5.cos(a)*travelDistance
      y += p5.sin(a)*travelDistance
      if (y<boundaries || x<boundaries) {
        a+=p5.PI
      } else if (y>1-boundaries|| x>1-boundaries) {
        a-=p5.PI
      }
      angleContCounter++
    }
  }

  function addPointsAsemicChar(allPoints, p) {
    let na, found
    let cp = []
    let x = p.x
    let y = p.y
    let a = p.a
    let ab = p.ab
    for (let i=0; i<300; i++) {
      if (i%50 === 0) {
        na = r.rB(-ab, ab)
      }
      a += na
      found = false
      for (let k=allPoints.length-1; k>=0; k--) {
        if (allPoints[k].length > 0) {
          if (distance(x, y, allPoints[k][0].x, allPoints[k][0].y) < 0.05) {  
            for (let j=0; j < allPoints[k].length && !found; j++) {
              if (distance(x, y, allPoints[k][j].x, allPoints[k][j].y) < charWidth) {
                found = true
              }
            }
          }
        }
      }
      if (!found) {
        cp.push({
          x: x,
          y: y,
          w: p.w,
          c: p.c
        })
      }
      x += p5.cos(a)*0.25*charWidth
      y += p5.sin(a)*0.25*charWidth
      if (x > p.x+distanceAsemicChar || x < p.x-distanceAsemicChar || y > p.y+distanceAsemicChar || y < p.y-distanceAsemicChar) {
        break
      }
    }
    allPoints.push(cp)
  }

  function drawSystemPoint(index) {
    let bcol
    p5.noFill()
    p5.noStroke()
    let p = circles[index]
    let x = p.x
    let y = p.y
    bcol = p5.color(p.c)
    bcol.setAlpha(0.3)
    if (rt.rB(0, 1) > 0.8 && fillShapes) {
      p5.fill(bcol)
    } else {
      p5.stroke(bcol)
    }
    if (square) {
      p5.rect((x-p.sw)*w, (y-p.sw)*h, p.sw*2*w)
    } else {
      p5.circle((x)*w, (y)*h, p.sw*w)
    }
    if ((!shufflePoints || rt.rB(0, 1) > linesRemovalThreshold) && index < circles.length-1) {
      p5.stroke(bcol)
      p5.line((p.x)*w, (p.y)*h, (circles[index+1].x)*w, (circles[index+1].y)*h)
    }
  }

  p5.mouseClicked = () => {
    rt = new Random(gsft(new Date()))
    writePoem()
  }

  p5.keyReleased = () => {
    rt = new Random(gsft(new Date()))
    writePoem()
  }
}

