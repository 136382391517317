import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Journey extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="Chapter one" 
                                    link="https://knownorigin.io/gallery/2873000-journey-chapter-one"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/2873000/asset.png"
                                />
                                <CollectionPiece 
                                    title="Chapter three" 
                                    link="https://knownorigin.io/gallery/3180000-journey-chapter-three"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/3180000/asset.png"
                                />
                                <CollectionPiece 
                                    title="Chapter five" 
                                    link="https://knownorigin.io/gallery/5300000-journey-chapter-five"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/5300000/asset.png"
                                />
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Journey
                                </p>
                                <a href="https://knownorigin.io/collections/encapsuled/journey" target="_blank" rel="noopener noreferrer">
                                    Available on KnownOrigin
                                </a>
                                <p>
                                    "Let's continue this journey together."
                                </p>
                                <p>
                                    Journey is an interactive story, written with its collectors.<br/>
                                    The first collector of every chapter gets to choose the direction of the next one, by picking one of the options that I will provide after the sale.<br/>
                                    All content is digitally hand drawn.
                                </p>
                                <CollectionPiece 
                                    title="Chapter two" 
                                    link="https://knownorigin.io/gallery/3107000-journey-chapter-two"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/3107000/asset.png"
                                />
                                <CollectionPiece 
                                    title="Chapter four" 
                                    link="https://knownorigin.io/gallery/3314000-journey-chapter-four"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/3314000/asset.png"
                                />
                                <CollectionPiece 
                                    title="Chapter six" 
                                    link="https://knownorigin.io/gallery/11419000-journey-chapter-six"
                                    imgLink="https://content.knownorigin.io/network/mainnet/edition/11419000/asset.gif"
                                />
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Journey
                            </p>
                            <a href="https://knownorigin.io/collections/encapsuled/journey" target="_blank" rel="noopener noreferrer">
                                Available on KnownOrigin
                            </a>
                            <p>
                                "Let's continue this journey together."
                            </p>
                            <p>
                                Journey is an interactive story, written with its collectors.<br/>
                                The first collector of every chapter gets to choose the direction of the next one, by picking one of the options that I will provide after the sale.<br/>
                                All content is digitally hand drawn.
                            </p>
                            <CollectionPiece 
                                title="Chapter one" 
                                link="https://knownorigin.io/gallery/2873000-journey-chapter-one"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/2873000/asset.png"
                            />
                            <CollectionPiece 
                                title="Chapter two" 
                                link="https://knownorigin.io/gallery/3107000-journey-chapter-two"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/3107000/asset.png"
                            />
                            <CollectionPiece 
                                title="Chapter three" 
                                link="https://knownorigin.io/gallery/3180000-journey-chapter-three"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/3180000/asset.png"
                            />
                            <CollectionPiece 
                                title="Chapter four" 
                                link="https://knownorigin.io/gallery/3314000-journey-chapter-four"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/3314000/asset.png"
                            />
                            <CollectionPiece 
                                title="Chapter five" 
                                link="https://knownorigin.io/gallery/5300000-journey-chapter-five"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/5300000/asset.png"
                            />
                            <CollectionPiece 
                                title="Chapter six" 
                                link="https://knownorigin.io/gallery/11419000-journey-chapter-six"
                                imgLink="https://content.knownorigin.io/network/mainnet/edition/11419000/asset.gif"
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Journey;