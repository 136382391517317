import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Divergence extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece 
                                    title="Birth"
                                    link="https://foundation.app/@teto/div/1"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/1/QmcPq45UBwdXDf7V7qFqqxhMjHNaLUYWKj3pGKxpSCiFPN/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    born perfection<br/>
                                    unaware of the others<br/>
                                    tossed into a world<br/>
                                    that no one understands<br/>
                                    about to become<br/>
                                    divergent<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Unexpected"
                                    link="https://foundation.app/@teto/div/3"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/3/QmQ35HrCDx4Scqs64jYTSo9bGiy2nBDSba6Tuddh5Dt8EF/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    adapted to a place<br/>
                                    you never belonged to<br/>
                                    sharp and precise<br/>
                                    when you only wanted<br/>
                                    to be like the others<br/>
                                    an unexpected turn<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Unicity"
                                    link="https://foundation.app/@teto/div/5"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/5/QmYgmy5LS92qTcKEHPttta8QtsV84udoBxvAdeSAgLVkPx/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    transformed<br/>
                                    created anew<br/>
                                    impossible to recognize<br/>
                                    an unpredictable unicity<br/>
                                    time took you apart<br/>
                                    to rebuild you<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Overgrown"
                                    link="https://foundation.app/@teto/div/7"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/7/QmS96hhhw5kGsvKK5tJV8D8CY7ysGrg7ud4R5Tvfy5XH93/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    on the edge of the overgrown<br/>
                                    jumping between lines<br/>
                                    sliding in dark places<br/>
                                    only to climb back<br/>
                                    following the white path<br/>
                                    on the edge of the overgrown<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Void"
                                    link="https://foundation.app/@teto/div/9"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/9/QmXRrmd7xMqRA1KtYmXw5UCxMZSBJKDShtaN24BYW2uiVE/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    at times with holes<br/>
                                    curving to fill<br/>
                                    the void<br/>
                                    that got in<br/>
                                    through the cracks<br/>
                                    of the moments we lost<br/><br/>
                                    we are you
                                </p>
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Divergence
                                </p>
                                <p>
                                    Divergence is a collection I created together with <a href="https://twitter.com/tetonotsorry" target="_blank" rel="noopener noreferrer">Teto</a> that made us connect on our passion for minimalistic design.<br/>
                                    It started with a few words:
                                </p>
                                <p>
                                    You are us<br/>
                                    We are you.
                                </p>
                                <p>
                                    Every piece of this collection explores a path, a way of being, a divergence.<br/>
                                    There are ten in total, and each piece is accompanied by a poem that synergizes with the visual work.<br/>
                                    One piece, "Birth", is the start of all others. Something happened in their life, and they changed.
                                </p>
                                <p>
                                    You are us.<br/>
                                    We all start the same.<br/>
                                    And then life happens.<br/>
                                    Some will absorb it<br/>
                                    others will fight it<br/>
                                    a few will own it.<br/>
                                    From birth, endless possibilities:<br/>
                                    some realized,<br/>
                                    others will never see the light.<br/>
                                    The only difference is our paths.<br/>
                                    We are you.
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Origin"
                                    link="https://foundation.app/@teto/div/2"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/2/QmWs27VVMz2FvaCQ88TYHGLuw9nKo9ktuAyba3kxjMi5wx/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    trying to find<br/>
                                    the origin<br/>
                                    the point where everything<br/>
                                    split<br/>
                                    reconstituting yourself<br/>
                                    with the memories you have<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Twisted"
                                    link="https://foundation.app/@teto/div/4"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/4/QmcLjW9bKNYYr4781ZdrSgjQKetNgpaB13tZH5Ma6VPzua/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    twisted existence<br/>
                                    a glitch took you away<br/>
                                    everyone thought<br/>
                                    you'd be the one<br/>
                                    life decided otherwise<br/>
                                    and you turned it around<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Will"
                                    link="https://foundation.app/@teto/div/6"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/6/QmawpRMQXH7iKwq9TwyXYvypgfQaK1DGcgk1vbqkcJd8sW/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    drawing your future<br/>
                                    shaped in your own will<br/>
                                    unaware<br/>
                                    that the future you're drawing<br/>
                                    was the one<br/>
                                    you were destined to<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Rupture"
                                    link="https://foundation.app/@teto/div/8"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/8/QmbqHwjmY7GAWC33C3119sED4yHraaDFbSYe1qGmCbtmxz/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    stretched in every direction<br/>
                                    our bodies now<br/>
                                    deformed<br/>
                                    about to rupture in pieces<br/>
                                    keeping it together<br/>
                                    in the hope of returning<br/><br/>
                                    we are you
                                </p>
                                <hr/>
                                <CollectionPiece 
                                    title="Lost"
                                    link="https://foundation.app/@teto/div/10"
                                    imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/10/QmSoFagMd28x91EX7YPWfQbJciepKkTMVBDwFQeurZLKrR/nft.png"
                                />
                                <p className="poemInCollection">
                                    you are us<br/><br/>
                                    a lost direction<br/>
                                    a quest to search<br/>
                                    meaning<br/>
                                    in a meaningless world<br/>
                                    you explored every possible path<br/>
                                    only to realize<br/><br/>
                                    we are you
                                </p>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                        <p className="collectionTitle">
                            Divergence
                        </p>
                        <p>
                            Divergence is a collection I created together with <a href="https://twitter.com/tetonotsorry" target="_blank" rel="noopener noreferrer">Teto</a> that made us connect on our passion for minimalistic design.<br/>
                            It started with a few words:
                        </p>
                        <p>
                            You are us<br/>
                            We are you.
                        </p>
                        <p>
                            Every piece of this collection explores a path, a way of being, a divergence.<br/>
                            There are ten in total, and each piece is accompanied by a poem that synergizes with the visual work.<br/>
                            One piece, "Birth", is the start of all others. Something happened in their life, and they changed.
                        </p>
                        <p>
                            You are us.<br/>
                            We all start the same.<br/>
                            And then life happens.<br/>
                            Some will absorb it<br/>
                            others will fight it<br/>
                            a few will own it.<br/>
                            From birth, endless possibilities:<br/>
                            some realized,<br/>
                            others will never see the light.<br/>
                            The only difference is our paths.<br/>
                            We are you.
                        </p>
                            <CollectionPiece 
                                title="Birth"
                                link="https://foundation.app/@teto/div/1"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/1/QmcPq45UBwdXDf7V7qFqqxhMjHNaLUYWKj3pGKxpSCiFPN/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                born perfection<br/>
                                unaware of the others<br/>
                                tossed into a world<br/>
                                that no one understands<br/>
                                about to become<br/>
                                divergent<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Origin"
                                link="https://foundation.app/@teto/div/2"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/2/QmWs27VVMz2FvaCQ88TYHGLuw9nKo9ktuAyba3kxjMi5wx/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                trying to find<br/>
                                the origin<br/>
                                the point where everything<br/>
                                split<br/>
                                reconstituting yourself<br/>
                                with the memories you have<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Unexpected"
                                link="https://foundation.app/@teto/div/3"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/3/QmQ35HrCDx4Scqs64jYTSo9bGiy2nBDSba6Tuddh5Dt8EF/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                adapted to a place<br/>
                                you never belonged to<br/>
                                sharp and precise<br/>
                                when you only wanted<br/>
                                to be like the others<br/>
                                an unexpected turn<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Twisted"
                                link="https://foundation.app/@teto/div/4"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/4/QmcLjW9bKNYYr4781ZdrSgjQKetNgpaB13tZH5Ma6VPzua/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                twisted existence<br/>
                                a glitch took you away<br/>
                                everyone thought<br/>
                                you'd be the one<br/>
                                life decided otherwise<br/>
                                and you turned it around<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Unicity"
                                link="https://foundation.app/@teto/div/5"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/5/QmYgmy5LS92qTcKEHPttta8QtsV84udoBxvAdeSAgLVkPx/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                transformed<br/>
                                created anew<br/>
                                impossible to recognize<br/>
                                an unpredictable unicity<br/>
                                time took you apart<br/>
                                to rebuild you<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Will"
                                link="https://foundation.app/@teto/div/6"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/6/QmawpRMQXH7iKwq9TwyXYvypgfQaK1DGcgk1vbqkcJd8sW/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                drawing your future<br/>
                                shaped in your own will<br/>
                                unaware<br/>
                                that the future you're drawing<br/>
                                was the one<br/>
                                you were destined to<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Overgrown"
                                link="https://foundation.app/@teto/div/7"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/7/QmS96hhhw5kGsvKK5tJV8D8CY7ysGrg7ud4R5Tvfy5XH93/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                on the edge of the overgrown<br/>
                                jumping between lines<br/>
                                sliding in dark places<br/>
                                only to climb back<br/>
                                following the white path<br/>
                                on the edge of the overgrown<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Rupture"
                                link="https://foundation.app/@teto/div/8"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/8/QmbqHwjmY7GAWC33C3119sED4yHraaDFbSYe1qGmCbtmxz/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                stretched in every direction<br/>
                                our bodies now<br/>
                                deformed<br/>
                                about to rupture in pieces<br/>
                                keeping it together<br/>
                                in the hope of returning<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Void"
                                link="https://foundation.app/@teto/div/9"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/9/QmXRrmd7xMqRA1KtYmXw5UCxMZSBJKDShtaN24BYW2uiVE/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                at times with holes<br/>
                                curving to fill<br/>
                                the void<br/>
                                that got in<br/>
                                through the cracks<br/>
                                of the moments we lost<br/><br/>
                                we are you
                            </p>
                            <hr/>
                            <CollectionPiece 
                                title="Lost"
                                link="https://foundation.app/@teto/div/10"
                                imgLink="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/10/QmSoFagMd28x91EX7YPWfQbJciepKkTMVBDwFQeurZLKrR/nft.png"
                            />
                            <p className="poemInCollection">
                                you are us<br/><br/>
                                a lost direction<br/>
                                a quest to search<br/>
                                meaning<br/>
                                in a meaningless world<br/>
                                you explored every possible path<br/>
                                only to realize<br/><br/>
                                we are you
                            </p>
                            <hr/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Divergence;