import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import CollectionPiece from "../components/CollectionPiece"

class Capsules extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div className="coupleCollections">
                            <div className="collectionLeft">
                                <CollectionPiece imgLink='/images/dialogues/artifact (1).png' title="1" link="https://objkt.com/asset/hicetnunc/96142"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (3).png' title="3" link="https://objkt.com/asset/hicetnunc/101435"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (5).png' title="5" link="https://objkt.com/asset/hicetnunc/104372"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (7).png' title="7" link="https://objkt.com/asset/hicetnunc/107559"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (9).png' title="9" link="https://objkt.com/asset/hicetnunc/110666"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (11).png' title="11" link="https://objkt.com/asset/hicetnunc/113493"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (13).png' title="13" link="https://objkt.com/asset/hicetnunc/118796"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (15).png' title="15" link="https://objkt.com/asset/hicetnunc/120597"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (17).png' title="17" link="https://objkt.com/asset/hicetnunc/132958"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (19).png' title="19" link="https://objkt.com/asset/hicetnunc/134697"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (21).png' title="21" link="https://objkt.com/asset/hicetnunc/152487"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (23).png' title="23" link="https://objkt.com/asset/hicetnunc/160212"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (25).png' title="25" link="https://objkt.com/asset/hicetnunc/170518"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (27).png' title="27" link="https://objkt.com/asset/hicetnunc/171496"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (29).png' title="29" link="https://objkt.com/asset/hicetnunc/212247"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (31).png' title="31" link="https://objkt.com/asset/hicetnunc/684516"/>
                            </div>
                            <div className="collectionRight">
                                <p className="collectionTitle">
                                    Capsules
                                </p>
                                <a href="https://objkt.com/profile/tz1Tguuqstpgd77A7uvnKEgoMcAfcEzTQaSm/created" target="_blank" rel="noopener noreferrer">
                                    Available on objkt
                                </a>
                                <p>
                                    The capsules project is ongoing on H=N.<br/>
                                    It's a dialogue between two characters, and every participant gets to choose the word that inspires the next piece. The piece is then minted as a 1/1 and sent directly to the participant's wallet.
                                </p>
                                <p>
                                    To participate, <a href="https://objkt.com/asset/hicetnunc/79317" target="_blank" rel="noopener noreferrer">collect a capsule</a> and send me a DM on twitter or discord!
                                </p>
                                <CollectionPiece imgLink='/images/dialogues/artifact (2).png' title="2" link="https://objkt.com/asset/hicetnunc/97835"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (4).png' title="4" link="https://objkt.com/asset/hicetnunc/102462"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (6).png' title="6" link="https://objkt.com/asset/hicetnunc/105317"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (8).png' title="8" link="https://objkt.com/asset/hicetnunc/108902"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (10).png' title="10" link="https://objkt.com/asset/hicetnunc/112503"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (12).png' title="12" link="https://objkt.com/asset/hicetnunc/113854"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (14).png' title="14" link="https://objkt.com/asset/hicetnunc/119123"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (16).png' title="16" link="https://objkt.com/asset/hicetnunc/127932"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (18).png' title="18" link="https://objkt.com/asset/hicetnunc/133988"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (20).png' title="20" link="https://objkt.com/asset/hicetnunc/146166"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (22).png' title="22" link="https://objkt.com/asset/hicetnunc/158511"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (24).png' title="24" link="https://objkt.com/asset/hicetnunc/160214"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (26).png' title="26" link="https://objkt.com/asset/hicetnunc/170608"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (28).png' title="28" link="https://objkt.com/asset/hicetnunc/171499"/>
                                <CollectionPiece imgLink='/images/dialogues/artifact (30).png' title="30" link="https://objkt.com/asset/hicetnunc/547892"/>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Capsules
                            </p>
                            <a href="https://objkt.com/profile/tz1Tguuqstpgd77A7uvnKEgoMcAfcEzTQaSm/created" target="_blank" rel="noopener noreferrer" className="black">
                                Available on objkt
                            </a>
                            <p>
                                The capsules project is ongoing on H=N.<br/>
                                It's a dialogue between two characters, and every participant gets to choose the word that inspires the next piece. The piece is then minted as a 1/1 and sent directly to the participant's wallet.
                            </p>
                            <p>
                                To participate, <a href="https://objkt.com/asset/hicetnunc/79317" target="_blank" rel="noopener noreferrer" className="black">collect a capsule</a> and send me a DM on twitter or discord!
                            </p>
                            <CollectionPiece imgLink='/images/dialogues/artifact (1).png' title="1" link="https://objkt.com/asset/hicetnunc/96142"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (2).png' title="2" link="https://objkt.com/asset/hicetnunc/97835"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (3).png' title="3" link="https://objkt.com/asset/hicetnunc/101435"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (4).png' title="4" link="https://objkt.com/asset/hicetnunc/102462"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (5).png' title="5" link="https://objkt.com/asset/hicetnunc/104372"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (6).png' title="6" link="https://objkt.com/asset/hicetnunc/105317"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (7).png' title="7" link="https://objkt.com/asset/hicetnunc/107559"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (8).png' title="8" link="https://objkt.com/asset/hicetnunc/108902"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (9).png' title="9" link="https://objkt.com/asset/hicetnunc/110666"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (10).png' title="10" link="https://objkt.com/asset/hicetnunc/112503"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (11).png' title="11" link="https://objkt.com/asset/hicetnunc/113493"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (12).png' title="12" link="https://objkt.com/asset/hicetnunc/113854"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (13).png' title="13" link="https://objkt.com/asset/hicetnunc/118796"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (14).png' title="14" link="https://objkt.com/asset/hicetnunc/119123"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (15).png' title="15" link="https://objkt.com/asset/hicetnunc/120597"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (16).png' title="16" link="https://objkt.com/asset/hicetnunc/127932"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (17).png' title="17" link="https://objkt.com/asset/hicetnunc/132958"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (18).png' title="18" link="https://objkt.com/asset/hicetnunc/133988"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (19).png' title="19" link="https://objkt.com/asset/hicetnunc/134697"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (20).png' title="20" link="https://objkt.com/asset/hicetnunc/146166"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (21).png' title="21" link="https://objkt.com/asset/hicetnunc/152487"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (22).png' title="22" link="https://objkt.com/asset/hicetnunc/158511"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (23).png' title="23" link="https://objkt.com/asset/hicetnunc/160212"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (24).png' title="24" link="https://objkt.com/asset/hicetnunc/160214"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (25).png' title="25" link="https://objkt.com/asset/hicetnunc/170518"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (26).png' title="26" link="https://objkt.com/asset/hicetnunc/170608"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (27).png' title="27" link="https://objkt.com/asset/hicetnunc/171496"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (28).png' title="28" link="https://objkt.com/asset/hicetnunc/171499"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (29).png' title="29" link="https://objkt.com/asset/hicetnunc/212247"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (30).png' title="30" link="https://objkt.com/asset/hicetnunc/547892"/>
                            <CollectionPiece imgLink='/images/dialogues/artifact (31).png' title="31" link="https://objkt.com/asset/hicetnunc/684516"/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Capsules;