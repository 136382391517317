import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Manifesto extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Manifesto
                            </p>
                            <a href="https://superrare.com/0x883a2239853151b8a986a88e4f6bb66cbc2d8500/manifesto-%F0%9F%9F%A5-4" target="_blank" rel="noopener noreferrer">
                                Available on SuperRare
                            </a>
                            <p style={{paddingBottom: 50}}>
                                <i>I will not think for myself.</i>
                            </p>
                            <div className="manifestoImgsWrapper">
                                <img
                                    src='/images/manifesto/manifestov1xs.png'
                                    alt='manifestov1xs' className="manifestoImg"
                                />
                                <img
                                    src='/images/manifesto/manifestov3xs.png'
                                    alt='manifestov3xs' className="manifestoImg"
                                />
                                <img
                                    src='/images/manifesto/manifestov2xs.png'
                                    alt='manifestov2xs' className="manifestoImg"
                                />
                            </div>
                            <p className="manifestoP">
                                Manifesto is an artwork created for the initiative CRITICIZE BY CREATING by <a href='https://www.breezyart.io/' target='_blank' rel="noopener noreferrer">BREEZY</a>.
                                This asemic piece is structured as a manifesto with six statements that, at first sight, cannot be understood.
                                By leveraging ARTIVIVE, viewers can scan the piece and will be presented with the "translated" version of the manifesto.
                            </p>
                            <div className="manifestoImgsWrapper">
                                <img
                                    src='/images/manifesto/manifestov1arxs.png'
                                    alt='manifestov1xsar' className="manifestoImg"
                                />
                                <img
                                    src='/images/manifesto/manifestov3arxs.png'
                                    alt='manifestov3xsar' className="manifestoImg"
                                />
                                <img
                                    src='/images/manifesto/manifestov2arxs.png'
                                    alt='manifestov2xsar' className="manifestoImg"
                                />
                            </div>
                            <p className="manifestoP">
                                The six statements might appear to a casual reader as valid points. 
                                Only when thinking about them, it will emerge the subtle dystopic theme of loss of individuality and ability to reason independently.<br/><br/>
                                Often, in modern society, we are used to be spoonfed information, and our ability to think critically has been dimming due to an overexposure of stimuli that our brain cannot process.
                                Every day there are too many things happening, and all of them are important. But our capacity to process information is not limitless, and because of that we've grown accustomed to trusting that the information presented to us is correct.
                                This trap we fall into is not a one step process. We get used to some aspects, and then more, and then more.<br/><br/>
                                Visually, in the asemic part of the manifesto, the style of the character will lose freedom with every statement.
                                From curves to straight lines, imposed by the collective and the dogmas we accepted.<br/>
                                The problem is not evident at first glance, we tend to agree with the first phases of dystopias, and only when it's too late we realize that we cannot go back.
                                Not easily at least. This warning is represented with the progressive increase in saturation and the decrease of brightness towards a dark red tone.<br/><br/>
                                The black on white version was donated to BREEZY's Historical Permanent Collection, and a limited edition print series will be made available of it, the white on black will remain in my possession as the Artist Proof and the colored is&nbsp;
                                <a href="https://superrare.com/0x883a2239853151b8a986a88e4f6bb66cbc2d8500/manifesto-%F0%9F%9F%A5-4" target="_blank" rel="noopener noreferrer">
                                    accepting offers on SuperRare
                                </a>.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}